<template>
    <v-hover v-slot="{ hover }">
        <div class="pa-4 ma-n4" :style="hover? (row ? 'margin-top:-31px !important;': 'margin-left:-31px !important;') : null">
            <v-btn @click.stop="handleClick" small color="transparent" v-if="hover" icon>
                <v-icon v-ripple color="primary">mdi-plus-box</v-icon>
            </v-btn>
            <div v-else :class="['adding-dot', row?'mr-2':'mb-2']" :style="row?'margin-top:-2px':'margin-left:-2px'"></div>
        </div>
    </v-hover>
</template>

<script>
export default {
    name: "AddButton",
    props: {
        row: {
            type: Boolean,
            default: false
        },
        beforeColumnId: {
            type: String,
            default: null
        },
        beforeRowId: {
            type: String,
            default: null
        }
    },
    methods: {
        handleClick(){
            this.$emit('add', this.row, this.row? this.beforeRowId: this.beforeColumnId)
        }
    },
}
</script>

<style lang="scss" scoped>
.adding-dot{
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: var(--v-grey-lighten1);
}
</style>