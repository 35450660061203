<template>
    <v-card class="pa-4">
        <div class="mb-2 subtitle-1">Add Options List</div>
        <v-textarea
            v-model="optionsInput"
            outlined
            :placeholder="`Type each option separated by a new line.\n\nExample:\nOption 1\nOption 2`"
            hide-details="auto"
            rows="6"
            auto-grow
            clearable
        ></v-textarea>
        <div class="d-flex">
            <v-spacer></v-spacer>
            
            <v-btn
                :disabled="!isValid"
                @click="emitOptions"
                color="primary"
                class="mt-4"
                elevation="0"
            >
                Add Options
            </v-btn>
        </div>
    </v-card>
</template>

<script>
export default {
    data() {
        return {
            optionsInput: '',
        }
    },
    computed: {
        isValid() {
            // Input validation: check if optionsInput is not empty and each line contains some text
            return this.optionsInput.split('\n').every(option => option.trim().length > 0);
        },
        optionsArray() {
            // Convert the optionsInput string into an array, removing any leading/trailing whitespace from each option
            return this.optionsInput.split('\n').map(option => option.trim());
        }
    },
    methods: {
        emitOptions() {
            if (this.isValid) {
                this.$emit('addOptions', this.optionsArray);
            }
        }
    }
}
</script>