<template>
    <div class="popup" @click="$emit('hide')">
        <div class="popup__content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "CustomPopup",
};
</script>

<style lang="scss" scoped>
.popup {
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.51);
    top: 0;
    left: 0;

    display: grid;
    place-items: center;

    &__content {
        z-index: 100;
        background: var(--white);
        box-shadow: 0px 2.5rem 5rem rgba(0, 0, 0, 0.1);
        border-radius: 2rem;

        padding: 3rem;
    }
}
</style>
