<template>
    <div class="d-flex flex-grow-1 justify-center justify-sm-start">
        <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            max-width="290"
            ref="dateMenu"
            v-if="isTextInputLook"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    :value="formattedDate"
                    clearable
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="answer=null"
                    outlined
                    hide-details="auto"
                    placeholder="Select date"
                    prepend-inner-icon="mdi-calendar"
                    :dark="styles.dark"
                ></v-text-field>
            </template>
            <v-date-picker
                :header-color="styles.dark?'secondary': styles.button.background"
                :dark="styles.dark"
                v-model="answer"
                @change="handleChange"
                elevation="0"
                :min="minDate"
                :max="maxDate"
                :active-picker.sync="activePicker"
            ></v-date-picker>
        </v-menu>
        <v-date-picker
            v-else
            :header-color="styles.dark?'secondary': styles.button.background"
            :color="styles.contrastBackground"
            :dark="styles.dark"
            v-model="answer"
            @change="$emit('change')"
            elevation="0"
            :min="minDate"
            :max="maxDate"
            :active-picker.sync="activePicker"
        ></v-date-picker>
    </div>
</template>

<script>
export default {
    name: "DatePicker",
    inject: ["question"],
    props: {
        value: {
            required: true,
        },
        styles: {
            required: true,
        },
        survey: {
            required: true,
        },
    },

    data(){
        return {
            dateMenu: false,
            activePicker: this.question?.properties?.starting_active_state || 'DATE',
        }
    },

    mounted() {
        if (this.value) {
            this.picker = new Date(this.value.value)
                .toISOString()
                .substr(0, 10);
        }
        this.activePicker = this.activeStep?.properties?.starting_active_state || 'DATE'

    },
    watch: {
        dateMenu(val) {
            val && setTimeout(() => (this.activePicker = this.question?.properties?.starting_active_state || 'DATE'))
        },
        startingActiveStateProperty(val) {
            val && setTimeout(() => (this.activePicker = val || 'DATE'))
        }
    },

    computed: {
        answer: {
            get: function () {
                return this.value ? this.value.value : "";
            },
            set: function (val) {
                if(val && val !== this.answer){
                    return  this.$emit("input", { type: "date", value: val });
                }
                return this.$emit("input", null);
            },
        },
        formattedDate() {
            if (this.answer) {
                return this.$date(this.answer).format('MMMM D, YYYY');
            }
            return "";
        },
        minDate() {
            if (this.question?.properties?.min_date) {
                return this.question.properties.min_date;
            }
            return null;
        },
        maxDate() {
            if (this.question?.properties?.max_date) {
                return this.question.properties.max_date;
            }
            return null;
        },

        isTextInputLook() {
            return this.question?.properties?.text_input_look || false;
        },

        startingActiveStateProperty(){
            return this.question?.properties?.starting_active_state || 'DATE'
        },

    },
    methods: {
        handleChange(date) {
            this.$refs.dateMenu.save(date)
            this.$emit("change");
        },
    },
};
</script>

