<template>
    <div>
        <question-text
            :question="question"
            :errors="errors"
            @change="updateQuestion"
            @focus="clearTextInput"
            style="width: 100%;"
        ></question-text>

        <div>
            <v-divider class="my-4"></v-divider>
            <div class="subtitle-2">Upload properties</div>
            <v-checkbox
                v-model="localFiles"
                label="Local file upload"
                class="mt-2"
                hide-details="auto"
                @change="updateQuestion"
            >
            </v-checkbox>
            <v-checkbox
                v-model="camera"
                label="Camera"
                class="mt-2"
                hide-details="auto"
                @change="updateQuestion"
            >
            </v-checkbox>
            <v-checkbox
                v-model="screencast"
                label="Screencast (desktop only)"
                class="mt-2"
                hide-details="auto"
                @change="updateQuestion"
            >
            </v-checkbox>
            <v-select
                v-model="fileTypes"
                :items="fileTypesOptions"
                item-text="label"
                item-value="value"
                label="Allowed file types"
                class="mt-4"
                hide-details="auto"
                dense
                outlined
                @change="updateQuestion"
            ></v-select>
            <v-autocomplete
                class="mt-2"
                v-if="fileTypes === 'custom'"
                auto-select-first
                chips
                deletable-chips
                multiple
                small-chips
                v-model="question.properties.file_types_patterns"
                outlined
                dense
                :items="[...items, ...question.properties.file_types_patterns]"
                @update:search-input="captureInputValue"
                ref="autocomplete"
                :search-input="currentAutocompleteInputValue"
                @change="updateQuestion"
                label="File types"
            >
                <template v-slot:no-data>
                    <div class="px-2">
                        <v-btn 
                            style="width: 100%;"
                            small 
                            text 
                            @click="addCustomItem"
                            outlined
                        >
                            Add "{{ currentAutocompleteInputValue }}"
                        </v-btn>
                    </div>
                </template>
                <template v-slot:selection="{ item, index, selected, disabled }">
                    <v-chip
                        :key="index"
                        :input-value="selected"
                        close
                        :disabled="disabled"
                        @click:close="remove(item)"
                        small
                        label
                        class="my-1"  
                    >
                        {{ item }}
                    </v-chip>
                </template>
                <template v-slot:item="data">
                    <v-list-item v-bind="data.attrs" @click="data.on.click">
                        <v-list-item-action>
                            <v-checkbox :input-value="data.attrs.inputValue"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title class="text-body-2">{{data.item}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-autocomplete>
            <div class="mt-3">
                <div class="text-caption mb-1">Files number limits</div>
                <div class="d-flex">
                    <v-text-field min="0" @change="handleMinNumberOfFilesChange" v-model="minNumberOfFiles" hide-details="auto" prefix="Min" outlined dense class="text-caption" type="number" placeholder="number of files" clearable></v-text-field>
                    <div class="mx-1"></div>
                    <v-text-field min="0" @change="handleMaxNumberOfFilesChange" v-model="maxNumberOfFiles" hide-details="auto" prefix="Max" outlined dense class="text-caption" type="number" placeholder="number of files" clearable></v-text-field>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import QuestionText from "../QuestionText.vue"

export default {
    name: "FileUploadType",
    components: { QuestionText },
    props: ["question", "errors"],
    inject: [
        "deleteChoiceFromQuestion",
        "addChoiceToQuestion",
        "updateQuestion",
    ],
    data() {
        return {
            currentAutocompleteInputValue: '',
            fileTypesOptions: [
                {label: 'All files', value: 'all', wildcard: null},
                {label: 'Images', value: 'images', wildcard: ['image/*']},
                {label: 'Videos', value: 'videos', wildcard: ['video/*']},
                {label: 'Documents', value: 'documents', wildcard: ['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx']},
                {label: 'Custom', value: 'custom', wildcard: null}
            ],
            items: [
                // Images
                'image/*',
                '.jpg', 'image/jpeg',
                '.png', 'image/png',
                '.gif', 'image/gif',
                '.bmp', 'image/bmp',
                '.svg', 'image/svg+xml',
                '.webp', 'image/webp',

                // Videos
                'video/*',
                '.mp4', 'video/mp4',
                '.mov', 'video/quicktime',
                '.avi', 'video/x-msvideo',
                '.mkv', 'video/x-matroska',
                '.webm', 'video/webm',

                // Audio
                'audio/*',
                '.mp3', 'audio/mpeg',
                '.wav', 'audio/wav',
                '.ogg', 'audio/ogg',

                // Documents
                '.pdf', 'application/pdf',
                '.doc', 'application/msword',
                '.docx',
                '.ppt', 'application/vnd.ms-powerpoint',
                '.pptx', 
                '.xls', 'application/vnd.ms-excel',
                '.xlsx', 
                '.txt', 'text/plain',
                '.html', 'text/html',
                '.css', 'text/css',
                '.js', 'application/javascript',
                '.json', 'application/json',

                // Archives
                '.zip', 'application/zip',
                '.rar', 'application/vnd.rar',
                '.tar', 'application/x-tar',
                '.gz', 'application/gzip',
            ]

            
        }
    },
    computed: {
        minNumberOfFiles: {
            get() {
                return this.question.properties.min_number_of_files
            },
            set(val) {
                try {
                    const min = val===''?null: Number(val);
                    this.question.properties = {
                        ...this.question.properties,
                        min_number_of_files: min < 1 ? null : min,
                    };
                } catch (error) {
                    this.question.properties = {
                        ...this.question.properties,
                        min_number_of_files: null
                    }
                }
            }
        },
        maxNumberOfFiles: {
            get() {
                return this.question.properties.max_number_of_files
            },
            set(val) {
                try {
                    const max = val===''?null: Number(val);
                    this.question.properties = {
                        ...this.question.properties,
                        max_number_of_files: max < 1 ? null : max,
                    };
                } catch (error) {
                    this.question.properties = {
                        ...this.question.properties,
                        max_number_of_files: null
                    }
                }
            }
        },

        localFiles: {
            get() {
                return this.question.properties.local_files !== false
            },
            set(value) {
                return this.question.properties = {
                    ...this.question.properties,
                    local_files: value,
                };
            }
        },
        camera: {
            get() {
                return this.question.properties.camera!==false
            },
            set(value) {
                return this.question.properties = {
                    ...this.question.properties,
                    camera: value,
                };
            }
        },
        screencast: {
            get() {
                return this.question.properties.screencast !== false;
            },
            set(value) {
                this.question.properties = {
                    ...this.question.properties,
                    screencast: value,
                };
            }
        },
        fileTypes: {
            get() {
                return this.question.properties.file_types || 'all'
            },
            set(value) {
                return this.question.properties = {
                    ...this.question.properties, 
                    file_types: value,
                    file_types_patterns: this.fileTypesOptions.find(option => option.value === value).wildcard
                }
            }
        }
    },
    methods: {
        clearTextInput() {
            if(this.question.text === "Type your question here") {
                this.question.text = "";
            }
        },
        captureInputValue(value) {
            this.currentAutocompleteInputValue = value;
        },
        addCustomItem() {
            // Check if the item already exists in the list
            if (!this.items.includes(this.currentAutocompleteInputValue)) {
                this.items.push(this.currentAutocompleteInputValue);
                this.question.properties.file_types_patterns.push(this.currentAutocompleteInputValue);
            }
            // Clear the current input value
            this.currentAutocompleteInputValue = '';

            this.updateQuestion();
        },
        remove (item) {
            const index = this.question.properties.file_types_patterns.indexOf(item)
            if (index >= 0) this.question.properties.file_types_patterns.splice(index, 1)
            this.updateQuestion()
        },

        handleMinNumberOfFilesChange(){
            if(typeof this.minNumberOfFiles === 'number' && typeof this.maxNumberOfFiles ==='number' && this.minNumberOfFiles > this.maxNumberOfFiles) {
                this.question.properties = {
                    ...this.question.properties,
                    max_number_of_files: this.minNumberOfFiles,
                };
            }
            this.updateQuestion();
        },

        handleMaxNumberOfFilesChange(){
            if(typeof this.minNumberOfFiles === 'number' && typeof this.maxNumberOfFiles ==='number' && this.maxNumberOfFiles < this.minNumberOfFiles) {
                this.question.properties = {
                    ...this.question.properties,
                    min_number_of_files: this.maxNumberOfFiles,
                };
            }
            this.updateQuestion();
        }
    },
};
</script>
