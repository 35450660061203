<template>
    <div v-if="blocked" :class="[customClass, 'wrapper-container']" @click="handleClick" :style="wrapperStyles" v-bind="$attrs"
        v-on="$listeners">
        <v-card class="pa-3" color="deep-purple lighten-5" outlined style="pointer-events: none; position: relative;">
            <v-chip style="position: absolute; top: -8px; left: 12px;" x-small color="deep-purple accent-3" dark>Pro Feature</v-chip>
            <slot></slot>
        </v-card>
    </div>
    <div v-else :class="customClass">
        <slot></slot>
    </div>
</template>

<script>
import {mapMutations} from "vuex";
export default {
    props:{
        blocked: {
            type: Boolean,
            default: false
        },
        customClass: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            wrapperStyles: {
                position: 'relative',
                pointerEvents: 'none'
            },
        };
    },
    methods: {
        ...mapMutations(['closeDialog', 'openDialog']),
        handleClick(){
            this.openDialog()
        }
    },
    mounted() {
        this.$el.style.pointerEvents = 'all';
    },
};
</script>

<style lang="scss" scoped>
.wrapper-container{
    cursor:  pointer ;
    width: 100%;
}
</style>