import { VueNodeViewRenderer, mergeAttributes } from '@tiptap/vue-2'
import Image from '@tiptap/extension-image'

import ImageBlockView from './ImageBlockView.vue'

export const ImageBlock = Image.extend({
  name: 'imageBlock',

  group: 'block',

  defining: true,

  isolating: true,

  addAttributes() {
    return {
      src: {
        default: '',
        parseHTML: element => element.getAttribute('src'),
        renderHTML: attributes => ({
          src: attributes.src,
        }),
      },
      width: {
        default: '100%',
        parseHTML: element => element.style.width,
        renderHTML: attributes => ({
          style: `width: ${attributes.width};`,
        }),
      },
      align: {
        default: 'center',
        parseHTML: element => element.classList.contains('align-center') ? 'center' : 
                              element.classList.contains('align-left') ? 'left' : 
                              element.classList.contains('align-right') ? 'right' : 'center',
        renderHTML: attributes => {
          const alignClass = attributes.align === 'left' ? 'align-left' :
                             attributes.align === 'right' ? 'align-right' :
                             'align-center';
          return {
            class: alignClass,
          }
        },
      },
      alt: {
        default: undefined,
        parseHTML: element => element.getAttribute('alt'),
        renderHTML: attributes => ({
          alt: attributes.alt,
        }),
      },
    }
  },

  renderHTML({ HTMLAttributes }) {
    return ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
  },

  addCommands() {
    return {
      setImage:
        attrs =>
        ({ commands }) => {
          return commands.insertContent({ type: 'imageBlock', attrs: { src: attrs.src } })
        },

      setImageAt:
        attrs =>
        ({ commands }) => {
          return commands.insertContentAt(attrs.pos, { type: 'imageBlock', attrs: { src: attrs.src } })
        },

      setImageAlign:
        align =>
        ({ commands }) => {
          return commands.updateAttributes('imageBlock', { align })
        },

      setImageWidth:
        width =>
        ({ commands }) => {
          return commands.updateAttributes('imageBlock', { width: `${Math.max(0, Math.min(100, width))}%` })
        },
    }
  },

  addNodeView() {
    return VueNodeViewRenderer(ImageBlockView)
  },
})

export default ImageBlock
