<template>
    <v-form v-model="valid" ref="form" class="d-flex mb-2">
        <v-text-field
            v-model="name"
            :rules="nameRules"
            class="text-caption mr-1"
            placeholder="variable_name"
            outlined
            dense
            style="width:60px"
            hide-details="auto"
            validate-on-blur
        ></v-text-field>
        <v-select
            v-model="type"
            :rules="typeRules"
            class="text-caption mr-1"
            :items="variableTypes"
            :menu-props="{bottom: true, offsetY: true}"
            outlined
            dense
            persistent-hint
            placeholder="Type"
            :style="{width: '40px'}"
            hide-details="auto"
            validate-on-blur
        ></v-select>
        <v-text-field
            v-model="value"
            :rules="defaultValueRules"
            class="text-caption mr-1"
            placeholder="Default Value (Optional)"
            outlined
            dense
            hide-details="auto"
            validate-on-blur
        >
        </v-text-field>
        <v-btn :loading="addingLoader" :disabled="addingLoader" height="40" elevation="0" color="primary" @click="handleAddVariable" v-if="createMode">
            <v-icon left>mdi-plus</v-icon>
            Add Variable
        </v-btn>
        <template v-else>
            <v-btn :loading="updateLoader" :disabled="updateLoader" @click="updateVariable" icon>
                <v-icon>mdi-check</v-icon>
            </v-btn>
            <v-btn @click="$emit('switchMode')" icon>
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-form>
</template>

<script>
import { mapMutations } from 'vuex';
import BuilderApi from '@/api/BuilderApi';
export default {
    name: 'DynamicVariableEditState',
    props: {
        createMode: {
            type: Boolean,
            default: false
        },
        variable: {
            type: Object,
            default: null
        }
        
    },
    data(){
        return {
            name: this.variable?.name || '',
            type: this.variable?.type || '',
            value: this.variable?.value || '',
            valid: true,
            variableTypes: [ 'text', 'number', 'boolean','object'],
            nameRules: [
                v => !!v || 'Name is required',
                // prevent reserved names 'utility', 'metadata', 'fields'
                v => !['utility', 'metadata', 'fields', 'loop_item'].includes(v) || `"${v}" is reserved`,
                
                v => /^[a-zA-Z_]\w*$/.test(v) || 'Name must start with a letter and contain only alphanumeric characters and underscores'
            ],
            typeRules: [
                v => !!v || 'Type is required'
            ],
            defaultValueRules: [],
            addingLoader: false,
            updateLoader: false
        }
    },

    inject: ['refreshVariables'],

    watch: {
        type(newType) {
            // Clear default value if type changes
            this.value = '';

            switch(newType) {
                case 'text':
                    this.defaultValueRules = [
                        v => (typeof v === 'string' || v === '') || 'Default value must be a text/string'
                    ];
                    break;
                case 'number':
                    this.defaultValueRules = [
                        v => !isNaN(v) || 'Default value must be a number'
                    ];
                    break;
                case 'boolean':
                    this.defaultValueRules = [
                        v => v === 'true' || v === 'false' || 'Default value must be a boolean (true/false)'
                    ];
                    break;
                case 'object':
                this.defaultValueRules = [
                    v => {
                            try {
                                JSON.parse(v);
                                return true; // means it's a valid JSON string representation of an object
                            } catch (error) {
                                return 'Default value must be a valid JSON string';
                            }
                        }
                    ];

                    break;
                default:
                    this.defaultValueRules = [];
            }
        }
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        async handleAddVariable(){
            try {
                const valid = this.$refs.form.validate();
                if(!valid) {
                    return;
                }
                this.addingLoader = true;
                const res = await BuilderApi.createDynamicVariable(this.$route.params.surveyId, {
                    name: this.name,
                    type: this.type,
                    value: this.value
                });
                if(res.data && res.data.added){
                    await this.refreshVariables();
                    this.name = '';
                    this.type = '';
                    this.value = '';
                    this.showSnackBar({
                        color: 'success',
                        text: 'Variable added successfully'
                    })
                }
            } catch (error) {
                this.showSnackBar({
                    color: 'error',
                    text: 'Error adding variable'
                })
            }finally{
                this.addingLoader = false;
            }
        },
        async updateVariable(){
            try {
                // TODO: check if variable has changed
                const valid = this.$refs.form.validate();
                if(!valid) {
                    return;
                }
                this.updateLoader = true;
                const res = await BuilderApi.updateDynamicVariable(this.$route.params.surveyId, this.variable.id, {
                    name: this.name,
                    type: this.type,
                    value: this.value
                });
    
                if(res.data && res.data.updated){
                    await this.refreshVariables();
                    this.$emit('switchMode')
                    this.showSnackBar({
                        color: 'success',
                        text: 'Variable updated'
                    })
                }
            } catch (error) {
                this.showSnackBar({
                    color: 'error',
                    text: 'Error updating variable'
                })
            }finally{
                this.updateLoader = false;
            }
        }
    }
}
</script>