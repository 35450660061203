import { uploadMediaFile} from '@/api/survey'

function executeCommand(editor, commandFunction) {
  const { $from } = editor.state.selection;
  const isCurrentLineEmpty = $from.parent.textContent.trim().length === 0;

  if (!isCurrentLineEmpty) {
    editor.chain().focus().setTextSelection({ from: $from.end(), to: $from.end() }).run();
    // Insert a new paragraph
    editor.chain().focus().insertContent('<p></p>').run();
    // Move the cursor to the new paragraph
    editor.chain().focus().setTextSelection({ from: $from.end() + 2, to: $from.end() + 2 }).run();
  }

  // Apply the command
  commandFunction(editor);
}

export const GROUPS = [
    {
      name: 'headings',
      title: 'Headings',
      commands: [
        {
          name: 'heading1',
          label: 'Heading 1',
          iconName: 'mdi-format-header-1',
          description: 'High priority section title',
          aliases: ['h1'],
          action: editor => {
            executeCommand(editor, editor => {
              editor.chain().focus().setHeading({ level: 1 }).run();
            });
          },
        },
        {
          name: 'heading2',
          label: 'Heading 2',
          iconName: 'mdi-format-header-2',
          description: 'Medium priority section title',
          aliases: ['h2'],
          action: editor => {
            executeCommand(editor, editor => {
              editor.chain().focus().setHeading({ level: 2 }).run();
            });
          },
        },
        {
          name: 'heading3',
          label: 'Heading 3',
          iconName: 'mdi-format-header-3',
          description: 'Low priority section title',
          aliases: ['h3'],
          action: editor => {
            executeCommand(editor, editor => {
              editor.chain().focus().setHeading({ level: 3 }).run();
            });
          },
        },
      ],
    },
    {
      name: 'lists',
      title: 'Lists',
      commands: [
        {
          name: 'bulletList',
          label: 'Bullet List',
          iconName: 'mdi-format-list-bulleted',
          description: 'Unordered list of items',
          aliases: ['ul'],
          action: editor => {
            executeCommand(editor, editor => {
              editor.chain().focus().toggleBulletList().run();
            });
          },
        },
        {
          name: 'numberedList',
          label: 'Numbered List',
          iconName: 'mdi-format-list-numbered',
          description: 'Ordered list of items',
          aliases: ['ol'],
          action: editor => {
            executeCommand(editor, editor => {
              editor.chain().focus().toggleOrderedList().run();
            });
          },
        },
      ]
    },
    {
      name: 'other',
      title: 'Other',
      commands: [
        {
          name: 'blockquote',
          label: 'Blockquote',
          iconName: 'mdi-format-quote-close',
          description: 'Element for quoting',
          action: editor => {
            executeCommand(editor, editor => {
              editor.chain().focus().setBlockquote().run()
            });
          },
        },
        // TODO: Implement code block
        // {
        //   name: 'codeBlock',
        //   label: 'Code Block',
        //   iconName: 'mdi-code-tags',
        //   description: 'Code block with syntax highlighting',
        //   shouldBeHidden: editor => editor.isActive('columns'),
        //   action: editor => {
        //     executeCommand(editor, editor => {
        //       editor.chain().focus().setCodeBlock().run()
        //     });
        //   },
        // },
        // TODO: Implement table
        // {
        //   name: 'table',
        //   label: 'Table',
        //   iconName: 'mdi-table',
        //   description: 'Insert a table',
        //   shouldBeHidden: editor => editor.isActive('columns'),
        //   action: editor => {
        //     executeCommand(editor, editor => {
        //       editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: false }).run()
        //     });
        //   },
        // },
        {
          name: 'image',
          label: 'Image',
          iconName: 'mdi-image-outline',
          description: 'Insert an image',
          aliases: ['img'],
          action: editor => {
            executeCommand(editor, editor => {
              // Create an input element for file upload
              const input = document.createElement('input');
              input.type = 'file';
              input.accept = 'image/png, image/jpeg, image/gif, image/bmp, image/webp';
              input.style.display = 'none';

              // Append the input to the body
              document.body.appendChild(input);
              
              // Add event listener for when a file is selected
              input.addEventListener('change', async (event) => {
                const file = event.target.files[0];
                if (file) {
                  // Show a spinner overlay on the temporary image
                  const spinnerOverlay = document.createElement('div');
                  spinnerOverlay.className = 'tiptap-image-upload-spinner-overlay';
                  spinnerOverlay.innerHTML = '<div class="tiptap-image-upload-spinner"></div>';
                  document.body.appendChild(spinnerOverlay);

                  try {
                    // Upload the image file
                    const formData = new FormData();
                    formData.append('image', file);

                    const surveyHash = editor?.options?.surveyHash;

                    const res = await uploadMediaFile(file, surveyHash);

                    if(res && res.data && res.data.url){
                      editor.chain().focus().setImage({ src:res.data.url }).run();
                    }else{
                      throw new Error('Image upload failed');
                    }
                  } catch (error) {
                    console.error('Image upload failed:', error);
                  } finally {
                    // Remove the spinner overlay
                    document.body.removeChild(spinnerOverlay);
                  }
                }

                // Remove the input element from the body
                document.body.removeChild(input);
              });

              // Trigger the file upload dialog
              input.click();

            });
          },
        },
        // TODO: Implement columns
        // {
        //   name: 'columns',
        //   label: 'Columns',
        //   iconName: 'mdi-format-columns',
        //   description: 'Add two column content',
        //   aliases: ['cols'],
        //   shouldBeHidden: editor => editor.isActive('columns'),
        //   action: editor => {
        //     executeCommand(editor, editor => {
        //       editor
        //       .chain()
        //       .focus()
        //       .setColumns()
        //       .focus(editor.state.selection.head - 1)
        //       .run()
        //     });
        //   },
        // },
        {
          name: 'horizontalRule',
          label: 'Divider',
          iconName: 'mdi-minus',
          description: 'Insert a horizontal divider',
          aliases: ['hr'],
          action: editor => {
            executeCommand(editor, editor => {

              // Insert the horizontal rule
              editor.chain().focus().setHorizontalRule().run();
            });
          },
        }
      ],
    },
  ]
  
  export default GROUPS