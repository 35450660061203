<template>
    <div>
        <question-text
            :question="question"
            :errors="errors"
            @change="updateQuestion"
            @focus="clearTextInput"
            style="width: 100%;"
        ></question-text>
        <v-divider class="my-4"></v-divider>
        <div class="mb-6"></div>
        <!-- Max audio response length customizations -->
        <recording-limit :question="question"></recording-limit>
        <div class="mb-6"></div>
        <text-input-option :question="question"></text-input-option>
        <v-checkbox small hide-details="auto" label="Prevent recording deletion" v-model="preventRecordingDeletion"></v-checkbox>
        <v-checkbox small hide-details="auto" label="Start recording on page load" v-model="startRecordingOnPageLoad"></v-checkbox>
        <div class="d-flex">
            <v-dialog
                v-model="customizeLabelsDialog"
                width="500"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn 
                        elevation="0"
                        class="mt-4 mr-2 text-body-2"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon  left>mdi-text-box-edit-outline</v-icon>
                        Customize labels
                    </v-btn>
                </template>
                <v-card class="pa-4">
                    <customize-labels
                        :question="question"
                        :defaultLabels="defaultLabels"
                    ></customize-labels>
                </v-card>
            </v-dialog>
            <vocabulary-settings></vocabulary-settings>
        </div>
        <v-card v-if="showExpiredProbingTrial" class="pa-2 mt-4" elevation="0" color="deepOrange lighten-5">
            <div class="text-caption">
                <v-icon color="deepOrange accent-1" class="mr-2">mdi-robot-off</v-icon>
                Looks like you’ve used all of your AI Probing free credits. Please contact the Voiceform team to add more.
            </div>
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn @click="handleContactUs" dark class="mt-2" small elevation="0" color="deepOrange accent-1">
                    <v-icon  small left>mdi-chat</v-icon>
                    Contact Us
                </v-btn>
            </div>
        </v-card>
        <template v-if="hasProbingFeature">
            <v-divider class="my-6"></v-divider>
            <question-probing :question="question"></question-probing>
        </template>
    </div>
</template>

<script>
import QuestionProbing from '../QuestionProbing.vue';
import QuestionText from "../QuestionText.vue";
import RecordingLimit from "../RecordingLimit.vue";
import TextInputOption from '../TextInputOption.vue';
import { mapGetters } from 'vuex';
import { Crisp } from 'crisp-sdk-web';
import CustomizeLabels from '../CustomizeLabels.vue';
import VocabularySettings from './VocabularySettings.vue';

export default {
    name: "VoiceResponseType",
    props: {
        question: Object,
        errors: Object
    },
    components: { QuestionText, RecordingLimit, TextInputOption, QuestionProbing, CustomizeLabels, VocabularySettings},
    inject: ["updateQuestion"],
    data() {
        return {
            customizeLabelsDialog: false,
            defaultLabels: {
                "hit_record": "Hit Record",
                "record_more": "Record More",
                "text_input_label": "Type answer",
                "text_input_placeholder": "Type your answer",
                "hint_text": "Select how you want to answer...",
                "or_divider": "or",
            }
        }
    },
    computed: {
        ...mapGetters({ 
            features: 'auth/features',
        }),
        hasProbingFeature(){
            return !!((this.features && this.features.probing) || (this.features.probing_trial_ended_at === null && this.features.probing_trial===true))
        },
        showExpiredProbingTrial(){
            return this.features && this.features.probing_trial_ended_at && !this.hasProbingFeature
        },
        preventRecordingDeletion: {
            get() {
                return this.question.properties.prevent_recording_deletion || false;
            },
            set(value) {
                this.question.properties.prevent_recording_deletion = !!value;
                this.updateQuestion();
            }
        },
        startRecordingOnPageLoad: {
            get() {
                return this.question.properties.start_recording_on_page_load || false;
            },
            set(value) {
                this.question.properties.start_recording_on_page_load = !!value;
                this.updateQuestion();
            }
        },
    },
    methods: {
        clearTextInput() {
            if(this.question.text === "Type your question here") {
                this.question.text = "";
            }
        },
        handleContactUs(){
            Crisp.chat.open();
            Crisp.message.send("text", "Hi, I've used all trial AI probes. I would like to know more about Voiceform AI Probing.")
        }
    }
};
</script>
