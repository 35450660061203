<template>
    <v-text-field
        v-model="answer"
        :dark="styles.dark"
        :color="styles.color"
        :rules="[rules.required, rules.isNumber, rules.isLowerThenMin, rules.isGreaterThenMax, rules.isInteger]"
        validate-on-blur
        type="number"
        clearable
        outlined
        :placeholder="fieldPlaceholder"
        :prefix="prefixProperty"
        :suffix="suffixProperty"
        ref="numericInputField"
    ></v-text-field>
</template>

<script>
export default {
    name: "NumericInput",
    props: {
        value: {
            required: true,
        },
        styles: {
            required: true,
        },
    },
    inject: ['survey', 'question'],
    components: {},
    data() {
        return {
            rules: {
                required: (value) =>{
                    if(!this.question.is_optional && !value && value !== 0){
                        return "Required."
                    }
                    return true
                },
                isNumber: (value) => {
                    if (value && isNaN(value)) {
                        return "Must be a number.";
                    }
                    return true;
                },
                isLowerThenMin: (value) => {
                    if (typeof value === 'number' && typeof this.minProperty === 'number' && value < this.minProperty) {
                        return `Must be greater than ${this.minProperty}`;
                    }
                    return true;
                },
                isGreaterThenMax: (value) => {
                    if (typeof value === 'number' && typeof this.maxProperty === 'number' && value > this.maxProperty) {
                        return `Must be less than ${this.maxProperty}`;
                    }
                    return true;
                },
                isInteger: (value) => {
                    if (typeof value === 'number' && !this.allowDecimalNumbersProperty && !Number.isInteger(value)) {
                        return `Must be an integer`;
                    }
                    return true;
                },

            },
        };
    },
    computed: {
        answer: {
            get: function () {
                return this.value ? this.value.value : "";
            },
            set: function (val) {
                try {
                    let numericValue = val ? Number(val) : null;
                    if (numericValue!==null && !this.allowDecimalNumbersProperty) {
                        numericValue = Number(numericValue.toFixed(0));
                    }
                    const isValid = this.validateValue(numericValue);

                    this.$emit("input", val ? { type: "number", value: numericValue, valid: isValid || 'Invalid Number.' } : null);
                } catch (error) {
                    console.error(error);
                }
            },
        },
        minProperty(){
            if(typeof this.question.properties?.min_number === 'number'){
                return this.question.properties.min_number
            }
            return null
        },
        maxProperty(){
            if(typeof this.question.properties?.max_number === 'number'){
                return this.question.properties.max_number
            }
            return null
        },
        allowDecimalNumbersProperty(){
            return this.question.properties?.allow_decimal_numbers || false
        },
        fieldPlaceholder(){
            return this.question.properties?.field_placeholder || "Type number"
        },
        prefixProperty(){
            return this.question.properties?.prefix || null
        },
        suffixProperty(){
            return this.question.properties?.suffix || null
        }
    },
    methods: {
        validateValue(value) {
            const validations = [
                this.rules.required,
                this.rules.isNumber,
                this.rules.isLowerThenMin,
                this.rules.isGreaterThenMax,
                this.rules.isInteger,
            ];
            for (const validate of validations) {
                const result = validate(value);
                if (result !== true) {
                    return result; // This will be the error message string
                }
            }
            return true; // All validations passed
        },
    }
};
</script>
