<template>
    <v-card min-height="40" v-if="!edit" elevation="0" color="grey lighten-5" class="d-flex align-center mb-2">
        <div style="width: 235px" class="placeholder px-3 text-caption mr-1">
            <span>{{variable.name}}</span>
        </div>
        <div style="width: 215px" class="placeholder px-3 text-caption mr-1">
            <span>{{variable.type}}</span>
        </div>
        <div style="flex: 1;" class="placeholder px-3 text-caption mr-1">
            <span :class="{'grey--text': !variable.value}">{{variable.value || 'Default value'}} </span>
        </div>
        <v-btn icon @click="handleEdit">
            <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn @click="handleDelete" :loading="deleting" :disabled="deleting" icon>
            <v-icon>mdi-delete</v-icon>
        </v-btn>
    </v-card>
    <dynamic-variable-edit-state :variable="variable" @switchMode="edit=false" v-else></dynamic-variable-edit-state>
</template>

<script>
import { mapMutations } from 'vuex'
import BuilderApi from '../../../../api/BuilderApi'
import DynamicVariableEditState from './DynamicVariableEditState.vue'
export default {
    props: {
        variable: {
            type: Object,
            required: true,
            deleting: false
        }
    },
    components: {
        DynamicVariableEditState
    },
    inject: ['refreshVariables'],
    data(){
        return{
            edit: false,
            variableTypes: [
                'text',
                'number',
                'boolean',
                'object', // can be key value pair or array
            ]
        }
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        handleEdit(){
            this.edit = true
        },
        async handleDelete(){
            try {
                this.deleting = true
                const res = await BuilderApi.deleteDynamicVariable(this.$route.params.surveyId, this.variable.id)
                if(res && res.data && res.data.deleted){
                    await this.refreshVariables()
                    this.showSnackBar({text: 'Variable deleted successfully', color: 'success'})
                }
            } catch (error) {
                this.showSnackBar({text: error?.message || "Can't delete the variable. Please try again later.", color: 'error'})
            }finally{
                this.deleting = false
            }
        }
    }

}
</script>