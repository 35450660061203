var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticStyle:{"cursor":"not-allowed !important"}},'div',attrs,false),on),[_c('Dashboard',{key:((_vm.question.id) + "-" + (_vm.plugins.length && _vm.plugins.join('-'))),attrs:{"uppy":_vm.uppy,"props":{
                proudlyDisplayPoweredByUppy: false,
                width: '100%',
                doneButtonHandler: null,
                singleFileFullScreen: false,
                showRemoveButtonAfterComplete: true,
                disabled: true,
                theme: _vm.styles.dark? 'dark' : 'light',
                locale: _vm.dashboardLocale,
                disableLocalFiles: _vm.disableLocalFiles
            },"plugins":_vm.plugins}})],1)]}}])},[_c('div',{staticClass:"text-caption"},[_vm._v(" Upload is disabled in preview ")])])}
var staticRenderFns = []

export { render, staticRenderFns }