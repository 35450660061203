<template>
    <div :style="`color: ${styles.backgroundTextColor}`" class="root px-4">
        <v-scroll-y-reverse-transition hide-on-leave>
            <div class="content mx-4">
                <div class="text-h4 thank-you-message" v-if="survey.goodbye_message">
                    {{ survey.goodbye_message }}
                </div>
                <div
                    class="finish-subtitle text-center mb-16 pt-5"
                    v-html="survey.goodbye_subtitle"
                >
                </div>
                <v-icon :size="56" :color="styles.backgroundTextColor">
                    mdi-check
                </v-icon>
                <div class="text-sm-body-1 mt-10 mb-2 mx-4 loading-message">{{ instructionsMessage || '' }}</div>
                
            </div>
        </v-scroll-y-reverse-transition>
        <div v-if="!hideBranding" class="powered-by-container">
            <powered-by></powered-by>
        </div>
    </div>
</template>
<script>
import {mapGetters} from 'vuex'
import PoweredBy from '../../views/survey/components/PoweredBy.vue'
export default {
    components: {PoweredBy},
    name: 'SurveyLoadingState',
    data() {
        return {toggle: true}
    },
    inject: ['survey'],
    props: {
        styles: {
            required: true
        }
    },
    computed: {
        ...mapGetters({
            submissionStatus: 'form/submissionStatus'
        }),

        hideBranding(){
            return this.survey && this.survey.settings && this.survey.settings.hide_branding
        },

        instructionsMessage(){
            return this.survey.settings?.goodbye_instructions_message === undefined? 'Your voiceform has been successfully submitted. You can close the window now 👍': this.survey.settings?.goodbye_instructions_message;
        }
    }
}
</script>

<style lang="scss" scoped>
    .root{
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
    }
    .content{
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1
    }
    .loading-message{
        max-width: 400px;
        text-align: center;
    }
    .powered-by-container{
        display: flex;
        flex-grow: 0;
        justify-content: center;
    }
    .thank-you-message{
        max-width: 400px;
        text-align: center;
    }
    .finish-subtitle {
        font-size: 20px;
        max-width: 400px;
        line-height: 31px;
    }
</style>
