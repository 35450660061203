<template>
    <div :class="['title-editing-container']" >
        <div ref="titleText" v-if="!isEditing" @click.stop="enableEditing">
            <span v-if="isRequired" class="error--text">*</span>
            {{ title }}
            <span v-if="!title" class="placeholderTitle">{{placeholder}}</span>
        </div>
        <textarea 
            v-else
            class="title-input"
            ref="titleInput"
            v-model="editableTitle"
            @blur="save"
            @keyup.enter="save"
            @input="adjustTextAreaHeight"
            rows="1"
        >
        </textarea>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            default: "Title"
        },
        isRequired: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isEditing: false,
            editableTitle: this.title,
            hover: false
        };
    },
    methods: {
        enableEditing() {
            const titleWidth = this.$refs.titleText.clientWidth;
            this.isEditing = true;
            this.$nextTick(() => {
                this.$refs.titleInput.style.height = (this.$refs.titleInput.scrollHeight) + "px";
                this.$refs.titleInput.style.width = titleWidth + "px";
                
                this.$refs.titleInput.focus();
                this.$refs.titleInput.select();
            });
        },
        save() {
            const sanitizedTitle = this.editableTitle? this.editableTitle.replace(/\n/g, ''): "";
            if(this.title !== sanitizedTitle){
                this.$emit("update:title", sanitizedTitle);
            }
            this.editableTitle = sanitizedTitle;
            this.isEditing = false;
        },
        adjustTextAreaHeight(event) {
            event.target.style.height = "auto";
            event.target.style.height = (event.target.scrollHeight) + "px";
        }
    }
};
</script>

<style scoped lang="scss" >
.title-editing-container:hover {
    background-color: #00000030; /* Light grey background on hover */
    cursor: pointer;
    border-radius: 4px;
}
.placeholderTitle{
    color: var(--v-grey-base);
}

.title-input {
    border: none;
    border-radius: 4px;
    font-size: inherit; /* Match font size with span */
    font-family: inherit; /* Match font family with span */
    padding: 0;
    margin: 0;
    width: 100%; 
    box-sizing: border-box;
    resize: none; /* Disable manual resizing */
    overflow: hidden; /* Hide scrollbar */
    background-color: #00000030; /* Remove the default white background */
    display: block;
}

.title-input:focus {
    outline: none;
}
</style>
