<template>
  <v-menu
    v-bind="$attrs"
    v-model="isMenuOpen"
    open-on-hover
    nudge-bottom="4"
    offset-y
    :open-delay="openDelay"
    :close-delay="closeDelay"
    :background-opacity="backgroundOpacity"
    :bottom="bottom"
    :max-width="maxWidth"
    transition="slide-y-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs" />
    </template>
    <v-card class="py-1 px-2" :color="color" rounded>
      <slot />
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'HoverMenuTooltip',
  inheritAttrs: false,
  props: {
    openDelay: {
      type: Number,
      default: 300,
    },
    closeDelay: {
      type: Number,
      default: 200,
    },
    backgroundOpacity: {
      type: Number,
      default: 1,
    },
    bottom: {
      type: Boolean,
      default: true,
    },
    maxWidth: {
      type: String,
      default: '300px',
    },
    color: {
      type: String,
      default: 'secondary',
    },
  },
  data() {
    return {
      isMenuOpen: false,
    };
  },
};
</script>
