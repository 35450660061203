<template>
    <div class="mt-4">
        <iframe
            v-if="isIframe"
            ref="videoIframe"
            :height="height"
            v-resize="onResize"
            frameborder="0" 
            :srcdoc="sourceDoc"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen
            style="width: 100%; max-width: 560px;"
        ></iframe>
        <iframe
            v-else
            ref="videoIframe"
            :height="height"
            v-resize="onResize"
            :src="videoSource"
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen
            style="width: 100%; max-width: 560px;"
        >
        </iframe>
    </div>
</template>
<script>
export default {
    name: 'VideoAttachment',
    data() {
        return {
            height: null,
        }
    },
    inject: ['question'],
    computed: {
        videoSource() {
            if(this.question && this.question.video_source) {
                // eslint-disable-next-line
                const regExp = /^https?\:\/\/.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
                const match = this.question.video_source.match(regExp);
                const uniqueId = (match && match[2].length === 11) ? match[2] : false;
                // eslint-disable-next-line
                if (!!uniqueId) {
                    return `https://www.youtube.com/embed/${uniqueId}?enablejsapi=1`;
                }
                return '';
            }
            return '';
        },
        isIframe() {
            if(this.question && this.question.video_source) {
                // eslint-disable-next-line
                const regex = /^<iframe.*>.*<\/iframe>/g;
                const match = this.question.video_source.match(regex);
                return !!match;
            }
            return false;
        },
        iframeSrc() {
            if(this.isIframe){
                // eslint-disable-next-line
                let newVal = this.question.video_source.replace(/width=\"\d*(px|%)?\"/, '');
                // eslint-disable-next-line
                newVal = newVal.replace(/height=\"\d*(px|%)?\"/, '');
                newVal = newVal.replace(/<iframe /, '<iframe width="100%" height="100%" ');
                return newVal;
            }
            return this.question.video_source
        },
        sourceDoc() {
            return `<html style="margin: 0; padding: 0; height: 100%; overflow: hidden;">
                        <body style="margin: 0; padding: 0; height: 100%; overflow: hidden;">
                            ${this.iframeSrc}
                        </body>
                    </html>`;
        },
    },
    mounted() {
        this.onResize();
    },
    methods: {
        onResize() {
            const rectangle = this.$refs.videoIframe.getBoundingClientRect();
            if(rectangle.width) {
                this.height = (315/560)*rectangle.width;
            }
        }
    },
}
</script>

<style scoped>
    .iframe-class {
        width: 100%;
        max-width: 560px;
    }
</style>