<template>
    <v-text-field
        label="Email"
        v-model="answer"
        :dark="styles.dark"
        :color="survey.style.color"
        :rules="[rules.email]"
        validate-on-blur
        type="email"
        autocomplete="email"
        @update:error="handleError"
        clearable
        outlined
    ></v-text-field>
</template>

<script>
const pattern =/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export default {
    name: "Email",
    props: {
        value: {
            required: true,
        },
        survey: {
            required: true,
        },
        styles: {
            required: true,
        },
    },
    components: {},
    data() {
        return {
            email:"",
            rules: {
                email: this.validateEmail,
            },
        };
    },
    methods: {
        handleError(hasError){
            this.$emit("isValid", !hasError)
        },
        validateEmail(value){
            return !value || pattern.test(value) || "Invalid e-mail.";
        }
    },
    computed: {
        answer: {
            get: function () {
                return this.value ? this.value.value : "";
            },
            set: function (value) {
                this.$emit("input", value? { answer: { type: "email", value: value.toLowerCase() }, valid: pattern.test(value) || "Invalid e-mail."}: null);
                this.$emit('change')
            },
        },
    }
};
</script>

<style lang="scss" scoped>
</style>
