<template>
    <svg
        :width="width"
        :height="height"
        viewBox="0 0 19 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.24 10.5978L8.46622 17.3716C6.80919 19.0377 4.5968 18.892 3.16739 17.4535C1.72886 16.0332 1.60139 13.8299 3.25842 12.1638L12.5724 2.8498C13.4919 1.93025 14.9031 1.69354 15.8409 2.62219C16.7787 3.56907 16.5328 4.96207 15.6133 5.88162L6.39948 15.1136C6.08084 15.4323 5.71665 15.3412 5.48903 15.1318C5.28874 14.9224 5.2068 14.5491 5.51635 14.2305L11.935 7.82089C12.3357 7.41118 12.3448 6.81029 11.9624 6.41879C11.5709 6.0455 10.9609 6.0455 10.5603 6.44609L4.11425 12.8921C3.04902 13.9665 3.10365 15.6235 4.05962 16.5795C5.08844 17.6083 6.65441 17.5901 7.72875 16.5157L17.0062 7.2473C18.8727 5.37176 18.8272 2.90443 17.161 1.23831C15.5313 -0.391416 13.0185 -0.482465 11.143 1.38397L1.76527 10.7799C-0.647425 13.2017 -0.510857 16.6978 1.71066 18.9193C3.93216 21.1317 7.4283 21.2774 9.85011 18.8738L16.6786 12.0454C17.0608 11.663 17.0608 10.9529 16.6603 10.5796C16.287 10.179 15.6406 10.2063 15.24 10.5978Z"
            :fill="iconColor"
        />
        <defs>
            <linearGradient
                id="paint0_linear"
                x1="9.20433"
                y1="0"
                x2="9.20433"
                y2="20.6278"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#0355A0" />
                <stop offset="1" stop-color="#01ACD2" />
            </linearGradient>
        </defs>
    </svg>
</template>
<script>
export default {
    name: "PaperClip",
    props: {
        width: {
            type: [Number, String],
            default: 15,
        },
        height: {
            type: [Number, String],
            default: 17,
        },
        iconColor: {
            type: String,
            default: "url(#paint0_linear)",
        },
    },
};
</script>
