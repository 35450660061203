<template>
  <v-card class="items" elevation="1">
    <template v-if="items.length">
      <div v-for="(item, itemIndex) in items" :key="`group-${item.name}`">
        <div class="group-title grey--text">{{ item.title }}</div>
        <v-btn block small class="command-button" 
          v-for="(command, commandIndex) in item.commands" 
          :class="{ 'is-selected': isSelected(itemIndex, commandIndex) }"
          :key="command.name" 
          @click="selectItem(itemIndex, commandIndex)" 
          text
        >
          <v-icon left>{{ command.iconName }}</v-icon>
          {{ command.label }}
        </v-btn>
        <v-divider v-if="itemIndex + 1 !== items.length" 
          style="margin-top: 12px; margin-bottom: 12px;">
        </v-divider>
      </div>
    </template>
    <!-- empty state -->
    <div v-else class="pa-2 text-caption grey--text">No commands...</div>
  </v-card>
</template>

<script>
// vuetify
import VIcon from 'vuetify/lib/components/VIcon/VIcon';
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    command: {
      type: Function,
      required: true,
    },
  },
  components: {
    VIcon,
  },
  data() {
    return {
      selectedGroupIndex: 0,
      selectedCommandIndex: 0,
    }
  },
  watch: {
    items() {
      this.resetSelection();
    },
  },
  methods: {
    resetSelection() {
      this.selectedGroupIndex = 0;
      this.selectedCommandIndex = 0;
    },
    isSelected(groupIndex, commandIndex) {
      return this.selectedGroupIndex === groupIndex && this.selectedCommandIndex === commandIndex;
    },
    onKeyDown({ event }) {
      if (event.key === 'ArrowUp') {
        this.upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        this.downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        this.enterHandler();
        return true;
      }

      return false;
    },
    upHandler() {
      if (this.selectedCommandIndex > 0) {
        this.selectedCommandIndex--;
      } else if (this.selectedGroupIndex > 0) {
        this.selectedGroupIndex--;
        this.selectedCommandIndex = this.items[this.selectedGroupIndex].commands.length - 1;
      }
    },
    downHandler() {
      const commandsInGroup = this.items[this.selectedGroupIndex].commands.length;
      if (this.selectedCommandIndex < commandsInGroup - 1) {
        this.selectedCommandIndex++;
      } else if (this.selectedGroupIndex < this.items.length - 1) {
        this.selectedGroupIndex++;
        this.selectedCommandIndex = 0;
      }
    },
    enterHandler() {
      this.selectItem(this.selectedGroupIndex, this.selectedCommandIndex);
    },
    selectItem(groupIndex, commandIndex) {
      const item = this.items[groupIndex]?.commands[commandIndex];
      if (item) {
        this.command(item);
      }
      this.selectedGroupIndex = groupIndex;
      this.selectedCommandIndex = commandIndex;
    },
  },
}
</script>

<style lang="scss" scoped>
.group-title {
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 4px;
}
.command-button {
  font-size: 0.5rem;
  padding: 8px !important;
  text-align: left;
  justify-content: flex-start;
  opacity: 0.5;
  border: 1px solid transparent;
}
.items {
  padding: 8px;
  font-size: 1.5rem !important;
}
.command-button.is-selected {
  border: 1px solid var(--v-primary-base);
  opacity: 1;
}
</style>
