<template>
    <v-sheet elevation="1" rounded v-if="survey">
        <div class="pa-6">
            <div>
                <div class="text-subtitle-1 mb-3">
                    Form Type
                </div>
                <v-select
                    :items="useTypes"
                    :menu-props="{bottom: true, offsetY: true}"
                    :rules="[rules.formRules]"
                    validate-on-blur
                    outlined
                    v-model="useTypeSelector"
                    item-text="useType"
                    item-value="code"
                    dense
                    @change="handleSelectChange"
                    hint="This will help to analyze your voiceform results better"
                    persistent-hint
                ></v-select>
                <v-text-field
                    v-if="isOther"
                    v-model="survey.use_type"
                    :rules="[rules.formRules]"
                    validate-on-blur
                    label="Describe your use case briefly"
                    class="mt-4"
                    @change="updateSurvey"
                    outlined
                    clearable
                    dense
                    hint="Required"
                    persistent-hint
                ></v-text-field>
                <div v-if="showTranscriptLanguage" class="text-subtitle-1 mb-3">
                    Languages
                </div>
                <v-autocomplete
                    v-if="showTranscriptLanguage"
                    :items="languages"
                    outlined
                    v-model="baseLanguage"
                    item-text="name"
                    item-value="code"
                    dense
                    @change="updateSurvey"
                    label="Original Language"
                    hide-details="auto"
                    class="mb-3 text-body-2"
                ></v-autocomplete>

                <v-autocomplete
                    v-if="surveyTranslations"
                    :items="additonalLanguages"
                    outlined
                    v-model="translationLanguages"
                    item-text="name"
                    item-value="code"
                    dense
                    @change="updateSurvey"
                    label="Additional Languages"
                    hide-details="auto"
                    persistent-hint
                    multiple
                    :class="['text-body-2', {'additional-languages-input-spacing': translationLanguages && translationLanguages.length > 0}]"
                    @update:search-input="captureAdditionalLanguagesInputValue"
                    :search-input="additionalLanguagesSearchInput"
                    hint="Languages your survey will be AI-translated into."
                >
                    <template v-slot:selection="data">
                        <v-chip color="primary lighten-5" v-bind="data.attrs" small label class="mr-1 mt-1 primary--text text--darken-2" close @click="data.select" @click:close="removeAdditionalLanguage(data.item)">
                            {{ data.item.name }}
                        </v-chip>
                    </template>
                </v-autocomplete>
                
                <upgrade-wrapper v-else illustration="https://cdn.voiceform.com/media/translation-illustration.png" include-dialog :blocked="!surveyTranslations" bodyText="Translate your entire survey—regardless of question type—into 50+ languages instantly with AI. Save time and effort compared to manual translations, and make your survey accessible to a global audience in seconds. Perfect for creating a single survey that works worldwide.">
                    <v-card class="pa-2" elevation="0" color="deep-purple lighten-5" rounded>
                        <div class="text-subtitle-2"><v-icon small color="deep-purple accent-3">mdi-diamond</v-icon> Additional Languages</div>
                        <div class="text-body-2">Enable AI-powered translations for your entire survey, allowing it to be available in multiple languages instantly.</div>
                    </v-card>
                </upgrade-wrapper>

                <v-expand-transition>
                    <upgrade-wrapper illustration="https://cdn.voiceform.com/media/translation-illustration.png" include-dialog v-if="baseLanguage !== 'en' || translationLanguages.length" :blocked="!enableTranslation" bodyText="Translations let you translate non-English responses back into English. This ensures data and analysis remain consistent, eliminating the need to hire a translator to interpret your data. Available in 50+ languages.">
                        <v-checkbox
                            v-model="translation"
                            label="Translate responses to English"
                            hide-details
                            class="mt-2"
                        >
                            <template v-slot:label>
                                <span class="mr-2">Translate response to English</span>
                                <v-icon v-if="!enableTranslation" small color="deep-purple accent-3">mdi-diamond</v-icon>
                            </template>
                        </v-checkbox>
                    </upgrade-wrapper>
                </v-expand-transition>

                <div class="text-subtitle-1 mb-2 mt-2">
                    Survey End Date <span class="text-caption grey--text">(your local time)</span>
                </div>
                <v-menu
                    :disabled="!enableEndDate"
                    ref="ends_at_menu"
                    v-model="ends_at_menu"
                    :close-on-content-click="false"
                    :return-value.sync="ends_at"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    dense
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            :disabled="!enableEndDate"
                            class="mt-0 pt-0 text-body-2"
                            :value="ends_at? $date(ends_at).format('MMM D, YYYY, hh:mm A'): null"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            dense
                            clearable 
                            @click:clear="handleEndDateClear"
                            placeholder="Select Date"
                        >
                        </v-text-field>
                    </template>
                    <v-date-picker
                        :disabled="!enableEndDate"
                        v-model="ends_at"
                        no-title
                        scrollable
                        @change="() => {
                                    this.$refs.ends_at_menu.save(
                                        survey.ends_at
                                    );
                                }"
                    >
                        <v-spacer></v-spacer>
                    </v-date-picker>
                </v-menu>
                <!-- Deprecated property-->
                <div v-if="survey.redirect_link" class="d-flex align-center mb-3">
                    <div class="text-subtitle-1">
                        Redirect link
                    </div>
                    <v-tooltip right max-width="250">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                class="ml-2 "
                                v-bind="attrs"
                                v-on="on"
                            >
                            mdi-information-outline
                            </v-icon>
                        </template>
                            <span>Add a URL link here to automatically redirect users at the end of the survey</span>
                    </v-tooltip>
                </div>
                <!-- Deprecated property-->
                <v-text-field
                    v-show="survey.redirect_link"
                    :disabled="!enableRedirect"
                    v-model="survey.redirect_link"
                    @change="handleLinkChange"
                    prepend-icon="mdi-link"
                    outlined
                    dense
                    :rules="[rules.link]"
                    validate-on-blur
                    placeholder="http://"
                    clearable
                    error-messages="This property is deprecated. Please use new 'Logic' page instead."
                ></v-text-field>
            </div>
            <div class="pr-4">
                <div class="text-subtitle-1">
                    Survey Components
                </div>
                <v-checkbox
                    v-model="survey.settings.is_progressbar_hidden"
                    @change="updateSurvey"
                    label="Hide Progress Bar"
                    hide-details
                ></v-checkbox>
                <!-- TODO: hide if feature is not available -->
                <v-checkbox
                    v-if="surveyTranslations"
                    v-model="survey.settings.hide_language_selector"
                    @change="updateSurvey"
                    label="Hide Language Selector"
                    hide-details
                ></v-checkbox>
                <v-checkbox
                    v-model="survey.settings.is_nav_arrows_hidden"
                    @change="updateSurvey"
                    label="Hide Navigation Arrows"
                    hide-details
                ></v-checkbox>
                <v-checkbox v-if="survey.has_logic"
                    label="Show Progress Bar in Percentage"
                    hide-details
                    input-value="1"
                    :disabled="true"
                ></v-checkbox>
                <v-checkbox v-else
                    v-model="survey.settings.percentage_progress"
                    @change="updateSurvey"
                    label="Show Progress Bar in Percentage"
                    hide-details
                    :disabled="survey.settings.is_progressbar_hidden"
                ></v-checkbox>
                <v-checkbox
                    v-model="autoSwitch"
                    @change="updateSurvey"
                    label="Question Auto-switch"
                    hide-details
                ></v-checkbox>
                <upgrade-wrapper :blocked="!enableHideBranding">
                    <v-checkbox
                        :disabled="!enableHideBranding"
                        v-model="survey.settings.hide_branding"
                        @change="updateSurvey"
                        hide-details
                    >
                    <template v-slot:label>
                        <span class="mr-2">Remove Voiceform Branding</span>
                        <v-icon v-if="!enableHideBranding" small color="deep-purple accent-3">mdi-diamond</v-icon>
                    </template>
                </v-checkbox>
                </upgrade-wrapper>
            </div>
            <div class="pr-4 mt-8" cols="12">
                <div class="d-flex align-center mb-3">
                    <v-icon class="mr-2">mdi-bell</v-icon>
                    <div class="text-subtitle-1">
                        Email Notifications
                    </div>
                </div>
                <v-tooltip max-width="250px" bottom left>
                    <template v-slot:activator="{ on, attrs }">
                        <div v-on="on" v-bind="attrs" class="d-flex align-center mb-3">
                            <v-checkbox
                                :disabled="!enableNotifications"
                                v-model="survey.email_notifications"
                                @change="updateSurvey"
                                small
                                class="pa-0 ma-0"
                                hide-details="auto"
                            ></v-checkbox>
                            <div class="text-body-2">Receive Submission Notification</div>
                        </div>
                    </template>
                    <span class="text-caption">Get an email notification when a respondent submits a survey</span>
                </v-tooltip>
                <div v-if="showEmailSubmissionConfirmation">
                    <submission-confirmation-email></submission-confirmation-email>
                </div>
            </div>
        </div>
        <v-divider></v-divider>
        <div style="margin-left: 1px">
            <v-expansion-panels flat>
                <v-expansion-panel >
                    <v-expansion-panel-header>
                        <div class="text-subtitle-1">
                            <v-icon left>mdi-microphone-settings</v-icon>
                            Audio Recording Settings
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-checkbox
                            v-model="textInputOnMicError"
                            @change="updateSurvey"
                            label="Show Text Field On Mic Error"
                            hide-details
                        ></v-checkbox>
                        <v-checkbox
                            v-model="silenceDetector"
                            @change="updateSurvey"
                            label="Silence Detector"
                            hide-details
                        ></v-checkbox>
                        <v-checkbox v-model="noiseSuppression" hide-details label="Noise Suppression"></v-checkbox>
                        <v-checkbox v-model="echoCancellation" hide-details label="Echo Cancellation"></v-checkbox>
                        <v-checkbox v-model="autoGainControl" hide-details label="Automatic Gain Control"></v-checkbox>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
        <v-divider></v-divider>
        <dynamic-variables></dynamic-variables>
        <v-divider></v-divider>
        <div  class="localization mt-8 pa-6">
            <div class="d-flex align-center mb-5">
                <div class="text-subtitle-1">
                    Custom Labels
                </div>
                <v-tooltip right max-width="250">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            class="ml-2 "
                            v-bind="attrs"
                            v-on="on"
                        >
                        mdi-information-outline
                        </v-icon>
                    </template>
                        <span>Edit the text in items below to personalize buttons and messages in your Voiceform.</span>
                </v-tooltip>
            </div>
            <div>
                <div
                    v-for="(text, key) in localization"
                    :key="key"
                >
                    <v-text-field
                        :disabled="!enableCustomLabels"
                        v-model="localization[key]"
                        :value="text"
                        outlined
                        clearable
                        :label="keyToLabel(key)"
                        @change="updateSurvey"
                        dense
                    >
                    </v-text-field>
                </div>
            </div>
        </div>
    </v-sheet>
</template>

<script>
import ContainerCard from "@/components/dashboard/ContainerCard.vue";
import useTypes from "@/configs/useTypes";
import { mapActions, mapGetters } from "vuex";
import BuilderApi from '../../../../api/BuilderApi';
import { pick } from 'lodash';
import UpgradeWrapper from '../../../../components/UpgradeWrapper.vue';
import DynamicVariables from './DynamicVariables.vue';
import SubmissionConfirmationEmail from './SubmissionConfirmationEmail.vue';
import supportedLanguages from '@/configs/languages';

const LinkReg = new RegExp("^(http|https)://", "i");

export default {
    name: "Settings",
    components: { ContainerCard, UpgradeWrapper, DynamicVariables, SubmissionConfirmationEmail },
    data: () => {
        return {
            useTypes: useTypes,
            ends_at_menu: false,
            checkboxes: {
                phone_number: {
                    required: false,
                    optional: false,
                    // Hiding phone input because its transition to question types
                    hidden: true,
                    label: "Phone Number",
                },
                age: {
                    required: false,
                    optional: false,
                    label: "Age",
                },

                gender: {
                    required: false,
                    optional: false,
                    label: "Gender",
                },
                household_income: {
                    required: false,
                    optional: false,
                    label: "Household Income",
                },
                location: {
                    required: false,
                    optional: false,
                    label: "Location",
                },
            },
            rules: {
                link: value => {
                    const pattern = /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/;
                    return !value || pattern.test(value) || 'Invalid link'
                },
                formRules: value => !!value || 'Required'
            },
            valid: true,
            loadingDynamicVariables: false,
            variables: [],
            additionalLanguagesSearchInput: '',
        };
    },
    inject: ['survey', 'refreshSurvey'],
    computed: {
        ...mapGetters({
            user: 'auth/user',
            features: 'auth/features'
        }), 

        baseLanguage: {
            get() {
                if(this.survey.language.includes('-')){
                    return this.survey.language.split('-')[0];
                }

                return this.survey.language;
            },
            set(value) {
                this.survey.language = value;
            }
        },
        translationLanguages: {
            get() {
                return this.survey.settings.translation_languages || [];
            },
            set(value) {
                // make sure that the base language is not included in the translation languages
                if(value.includes(this.baseLanguage)){
                    value = value.filter(lang => lang !== this.baseLanguage)
                }
                this.survey.settings.translation_languages = value;
                this.additionalLanguagesSearchInput = '';
            }
        },
        translation: {
            get() {
                if(this.enableTranslation && this.baseLanguage !== 'en') {
                    return typeof this.survey.settings.translation === 'boolean' ? this.survey.settings.translation : true;
                }
                return false;
            },
            set(value) {
                this.survey.settings.translation = value;
                this.updateSurvey();
            }
        },
        // use 'isOther' to identify if selectedOption is 'other'
        // if true, display 'other' in the useType list while simultaneously 
        // allowing to store the useTypeDescription in 'this.survey.use_type'
        ends_at: {
            get() {
                if(this.survey.ends_at) {
                    return this.$date(this.survey.ends_at).format('YYYY-MM-DD');
                }
                return null;
            },
            set(value) {
                this.survey.ends_at = value?this.$date(value).toISOString():null;
                this.updateSurvey();
            }
        },
        useTypeSelector: {
            get() {
                if(this.isOther) {
                    return 'other';
                }
                return this.survey.use_type;
            },
            set(value) {
                return this.survey.use_type = value === 'other' ? '' : value 
            }
        },
        autoSwitch: {
            get() {
                return typeof this.survey.settings.auto_switch === 'boolean' ? this.survey.settings.auto_switch : true;
            },
            set(value) {
                this.survey.settings.auto_switch = value;
                this.updateSurvey();
            }
        },
        textInputOnMicError: {
            get() {
                return typeof this.survey.settings.text_input_on_mic_error === 'boolean' ? this.survey.settings.text_input_on_mic_error : true;
            },
            set(value) {
                this.survey.settings.text_input_on_mic_error = value;
                this.updateSurvey();
            }
        },
        noiseSuppression: {
            get() {
                return typeof this.survey.settings.noise_suppression === 'boolean' ? this.survey.settings.noise_suppression : true;
            },
            set(value) {
                this.survey.settings.noise_suppression = value;
                this.updateSurvey();
            }
        },
        echoCancellation: {
            get() {
                return typeof this.survey.settings.echo_cancellation === 'boolean' ? this.survey.settings.echo_cancellation : true;
            },
            set(value) {
                this.survey.settings.echo_cancellation = value;
                this.updateSurvey();
            }
        },
        autoGainControl: {
            get() {
                return typeof this.survey.settings.auto_gain_control === 'boolean' ? this.survey.settings.auto_gain_control : true;
            },
            set(value) {
                this.survey.settings.auto_gain_control = value;
                this.updateSurvey();
            }
        },
        silenceDetector: {
            get() {
                return typeof this.survey.settings.silence_detector === 'boolean' ? this.survey.settings.silence_detector : true;
            },
            set(value) {
                this.survey.settings.silence_detector = value;
                this.updateSurvey();
            }
        },
        isOther() {
            if(this.survey.use_type !== null) {
                const slicedoptions = this.useTypes.slice(0, this.useTypes.length-1);
                const item = slicedoptions.find(option=> option.code === this.survey.use_type);
                return !item;
            }
            return false;
        },
        localization(){
            delete this.survey.settings.localization.back; 
            return this.survey.settings.localization;
        },
        enableTranslation(){
            return !!this.features && this.features.translation
        },
        enableRedirect(){
            return !!this.features && this.features.redirect
        },
        enableCustomLabels(){
            return !!this.features && this.features.custom_labels
        },
        enableHideBranding(){
            return !!this.features && this.features.remove_branding
        },
        surveyTranslations(){
            return !!this.features && this.features.survey_translations
        },
        enableNotifications(){
            return !!this.features && this.features.email_notifications
        },
        enableEndDate(){
            return !!this.features && this.features.schedule_voiceform_end_date
        },
        showTranscriptLanguage(){
            return this.features && this.features.transcription
        },
        showEmailSubmissionConfirmation(){
            return this.features && this.features.email_submission_confirmation
        },
        languages(){
            if(!this.features.transcription_languages){
                return [{ name: "English", code: "en" }]
            }

            return supportedLanguages
        },
        additonalLanguages(){
            return this.languages.filter(lang => lang.code !== this.baseLanguage)
        }
    },
    methods: {
        ...mapActions({
            setInformation: "survey/setInformation",
        }),
        async updateSurvey(){
            const updatedData = pick(this.survey, ['settings', 'use_type', 'language', 'ends_at', 'redirect_link', 'email_notifications']);
            await BuilderApi.patchForm(this.survey.id,updatedData)
            await this.refreshSurvey()
        },
        async handleSelectChange() {
            if(!this.isOther) {
                this.updateSurvey();
            }
        },
        checkboxInit() {
            for (const [key, value] of Object.entries(this.checkboxes)) {
                let option = this.survey.information_to_collect[key];
                if (option) {
                    value[option] = true;

                    if (option == "optional") {
                        value.required = true;
                    }
                    value.hidden = false
                }
            }
        },
        informationHandler(target , name, value) {
            let { required, optional } = value;

            if (target.id == name && !required) {
                value.optional = false;
            } else if (target.id != name && optional) {
                value.required = true;
            }

            this.setInformation({ name, value });
        },
        keyToLabel([first, ...rest]) {
            return first.toUpperCase() + rest.join("").replaceAll("_", " ");
        },
        handleLinkChange(value){
            if(value && value.trim() && !LinkReg.test(value)){
                this.survey.redirect_link = `http://${value}`
            }
            this.updateSurvey()
        },
        handleEndDateClear(){
            this.survey.ends_at=null;
            this.updateSurvey()
        },
        removeAdditionalLanguage(item) {
            const index = this.translationLanguages.findIndex(code => code === item.code);
            if (index >= 0) this.translationLanguages.splice(index, 1)
            this.updateSurvey()
        },
        captureAdditionalLanguagesInputValue(value) {
            this.additionalLanguagesSearchInput = value;
        }
    },
    updated() {
        this.checkboxInit();
    },
    destroyed() {
        this.updateSurvey();
    },
};
</script>


<style lang="scss" scoped>
.additional-languages-input-spacing::v-deep .v-select__selections {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}
</style>