<template>
  <div class="description-section ma-4">
    <div class="text-subtitle-1 mb-3">
        Description
    </div>
    <div v-if="editToggle">
      <editor :toolbar="true" v-model="description"></editor>
      <div class="d-flex">
        <v-btn @click="handleEditSave" small elevation="0" color="primary" class="mt-2">
          Save
        </v-btn>
        <v-btn text @click="handleCancel" small elevation="0" color="primary" class="mt-2 mr-2">
          Cancel
        </v-btn>
      </div>
    </div>
    <v-hover v-else v-slot="{ hover }">
      <v-card outlined :color="hover? 'grey lighten-5': null" elevation="0" class="pa-4" @click="handleOpenEdit">
        <div v-if="question.description" v-html="descriptionText"></div>
        <div v-else>
          <i class="grey--text">Click to add description</i>
        </div>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
  import Editor from '../../../../../../components/Editor.vue';

  export default {
    components: { Editor },
    name: "DescriptionSection",
    data() {
      return {
        description: "",
        editToggle: false,
        fileUploadMap: {},
        draggingOver: false
      }
    },
    watch: {
      '$route.params.stepType': function () {
        this.editToggle = false
      }
    },
    computed: {
      descriptionText(){
        return this.question.description || "<i>Click to add description</i>"
      }
    },
    inject: [
        "updateQuestion",
        "question"
    ],
    methods: {
      handleEditSave(){
        this.question.description = this.description
        this.updateQuestion()
        this.editToggle = false
      },
      handleCancel(){
        this.description = this.question.description
        this.editToggle = false
      },
      handleOpenEdit(){
        this.description = this.question.description
        this.editToggle = true
      }
    },
    
  }
</script>

<style scoped>
.description-section {
  word-break: break-word;
}
</style>