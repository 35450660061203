<template>
    <div class="pa-6">
        <v-text-field hide-details outlined placeholder="Title" v-model="survey.title" @change="patchForm({title: survey.title})"></v-text-field>
        <div class="mt-8" />
        <div class="text-subtitle-2 mb-3">
            Subtitle
        </div>
        <editor :limit="270" v-model="survey.subtitle" @change="patchForm({subtitle: survey.subtitle})"></editor>
        <div class="mt-2" />
        <span class="text-subtitle-2">Additional Text</span>
        <div class="mt-3" />
        <editor :limit="150" v-model="survey.privacy_text" @change="patchForm({privacy_text: survey.privacy_text})"/>
        <div class="mt-4" />
        <div class="mt-8">
            <div v-if="survey.welcome_page_file_url">
                <div class="d-flex align-center">
                    <div class="text-subtitle-2 mb-2">Welcome Image</div>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="removeLogo">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </div>
                <v-card class="pa-3 justify" elevation="0" color="grey lighten-4">
                    <v-img max-height="80" :src="survey.welcome_page_file_url" contain></v-img>
                </v-card>
            </div>
            <v-file-input
                v-else
                accept="image/png, image/jpeg, image/bmp, image/gif"
                placeholder="Pick an image"
                prepend-icon="mdi-image-outline"
                label="Intro image"
                :disabled="!showWelcomeUpload"
                outlined
                @change="handleAddWelcomeImage"
                dense
            ></v-file-input>
        </div>
    </div>
</template>

<script>
import ContainerCard from "@/components/dashboard/ContainerCard.vue";
import CustomInput from "@/components/elements/CustomInput.vue";
import {mapGetters } from "vuex";
import Editor from '@/components/Editor.vue'
import BuilderApi from '@/api/BuilderApi';
import { uploadLogo } from '@/api/survey';
export default {
    name: "Welcome",
    components: {
        CustomInput,
        ContainerCard,
        Editor
    },
    inject: ['survey', 'refreshSurvey'],
    computed: {
        ...mapGetters({
            features: 'auth/features'
        }),

        showWelcomeUpload(){
            return !!this.features && this.features.upload_welcome_image
        },
    },
    methods: {
        async removeLogo(){
            try {
                await BuilderApi.patchForm(this.survey.id, {
                    welcome_page_file_path: null,
                    welcome_page_file_url: null
                })
                await this.refreshSurvey()
            } catch (error) {
                console.log('error in removeLogo', error);
            }
        },
        async handleAddWelcomeImage(file){
            try {
                
                if(file){
                    const res = await uploadLogo(file, this.survey.hash)
                    if(res && res.data){
                        await BuilderApi.patchForm(this.survey.id, {
                            welcome_page_file_path: res.data.path,
                            welcome_page_file_url: res.data.url
                        })
                        await this.refreshSurvey()
                    }
                }
            } catch (error) {
                console.log('error in handleAddWelcomeImage', error);
            }
        },
        async patchForm(props){
            await BuilderApi.patchForm(this.survey.id, props)
            await this.refreshSurvey()
            
        }
    }
};
</script>

