<template>
    <div v-if="editor" class="question--description tiptap editor" ref="menuContainerRef">
        <editor-content :editor="editor" />
        <ImageBlockMenu :editor="editor" :appendTo="$refs.menuContainerRef"/>
        <TextMenu :editor="editor"/>
        <LinkMenu :editor="editor" :appendTo="$refs.menuContainerRef"/>
    </div>
</template>

<script>

import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import CharacterCount from '@tiptap/extension-character-count'
import TextAlign from '@tiptap/extension-text-align'
import Highlight from '@tiptap/extension-highlight'
import Underline from '@tiptap/extension-underline'
import Placeholder from '@tiptap/extension-placeholder'
import SlashCommand from '@/components/tiptap/extensions/SlashCommand' 
import DropCursor from '@tiptap/extension-dropcursor'
import Link from '@tiptap/extension-link'

import ImageBlock from '@/components/tiptap/extensions/ImageBlock';
import ImageBlockMenu from '@/components/tiptap/extensions/ImageBlock/ImageBlockMenu';
import LinkMenu from '@/components/tiptap/extensions/LinkMenu';
import TextMenu from '@/components/tiptap/extensions/TextMenu';
import HorizontalRule from '@/components/tiptap/extensions/HorizontalRule';

export default {
    name: "DescriptionEdit",
    inject: ["updateQuestion", 'survey', 'question'],
    data(){
        return {
            editor: null
        }
    },
    props: {
        styles: {
            required: true
        }
    },
    components: {
        EditorContent,
        ImageBlockMenu,
        TextMenu,
        LinkMenu
    },


    mounted(){

        this.editor = new Editor({
            surveyHash: this.survey.hash,
            surveyId: this.survey.id,
            extensions: [
                StarterKit.configure({
                    heading: {
                        levels: [1, 2, 3],
                    },
                    horizontalRule: false,
                    dropcursor: false,
                }),
                Link.configure({
                    openOnClick: false,
                }),
                this.limit && CharacterCount.configure({
                    limit: this.limit,
                }),
                TextAlign.configure({
                    types: ['heading', 'paragraph'],
                    alignments: ['left', 'right', 'center', 'justify'],
                }),
                Highlight,
                Underline,
                Placeholder.configure({
                    includeChildren: true,
                    showOnlyCurrent: true,
                    emptyEditorClass: 'is-editor-empty',
                    emptyNodeClass: 'is-empty',
                    placeholder: ()=>{
                        // check if depth is 1, then return placeholder
                        if (this.editor?.state?.selection?.$from?.depth === 1) {
                            return 'Type / to insert...';
                        }
                        return ''
                    },
                }),
                SlashCommand.configure({
                    vueInstance: this, // Pass the Vue instance
                }),
                ImageBlock,
                HorizontalRule,
                DropCursor.configure({
                    width: 2,
                }),
            ],
            content: this.question.description,
            onBlur: () => {
                const html = this.editor.getHTML()
                if(html !== this.question.description){
                    this.question.description= html;
                    this.updateQuestion();
                }
            },
        })
    },
    beforeDestroy() {
        this.editor.destroy()
    },

}
</script>

<style lang="scss" scoped>
.question--description {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
}
.question--description::v-deep .ProseMirror{
    padding: 0 !important;
    margin: 0 !important;
}

.editor::v-deep{
    word-break: break-word;

    img{
        border-radius: 4px;
        margin-top: 12px;
        margin-bottom: 12px;
        height: auto !important;
    }

    h1, h2, h3{
        margin-top: 12px;
        margin-bottom: 12px;
    }

    h1{
        font-size: 3rem;
        font-weight: 500;
        line-height:unset;
        letter-spacing: normal;
        text-transform: unset;
    }

    h2{
        font-size: 2.25rem;
        font-weight: 500;
        line-height:unset;
        letter-spacing: 0.0125em;
        text-transform: unset;
    }

    h3{
        font-size: 1.5rem;
        font-weight: 500;
        line-height:unset;
        letter-spacing: 0.015em;
        text-transform: unset;
    }

    hr{
        margin-top: 12px;
        margin-bottom: 12px;
        opacity: 0.5;
    }


    // standard bulleted list with bullets
    ul {
        list-style-type: disc;
        padding-left: 20px;
    }

    // standard numbered list with numbers
    ol {
        list-style-type: decimal;
        padding-left: 20px;
    }

    // nice quote look with italics and lightened color
    blockquote {
        font-style: italic;
        color: inherit;
        opacity: 0.7;
        border-left: 4px solid #ccc;
        margin-left: 0;
        padding-left: 10px;
    }


    .align-left {
        display: block;
        margin-left: 0;
        margin-right: auto; /* Ensures it aligns left */
    }

    .align-right {
        display: block;
        margin-left: auto;
        margin-right: 0; /* Ensures it aligns right */
    }

    .align-center {
        display: block;
        margin-left: auto;
        margin-right: auto; /* Ensures it aligns center */
    }
}
</style>

<style lang="scss">
.tiptap p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: inherit;
  font-style: italic;
  pointer-events: none;
  height: 0;
  opacity: 0.4;
}

.tiptap p.is-empty::before {
  content: attr(data-placeholder);
  float: left;
  color: inherit;
  font-style: italic;
  pointer-events: none;
  height: 0;
  opacity: 0.4;
}

.tiptap-image-upload-spinner-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.tiptap-image-upload-spinner{
    width: 50px;
    height: 50px;
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-top: 5px solid #000;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>
