<template>
    <BubbleMenu 
        :editor="editor" 
        pluginKey="linkMenu" 
        :shouldShow="handleShouldShow" 
        :updateDelay="0" 
        :tippyOptions="tippyOptions"
    >

        <v-card class="pa-3" v-if="showEdit">
            <v-text-field :style="{width: '250px'}" width="400px" class="text-caption mr-1" hide-details dense placeholder="Enter URL"
                v-model="url" outlined>
                <template slot="prepend-inner">
                    <v-icon class="mt-1 mr-1" small>mdi-link-variant</v-icon>
                </template>
            </v-text-field>

            <div class="d-flex mt-2">
                <v-btn outlined text @click="handleRemoveLink">
                    <v-icon left>mdi-delete</v-icon>
                    Remove Link
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn elevation="0" @click="handleApplyEdits" color="primary">Apply</v-btn>
            </div>

        </v-card>
        <v-card class="pa-2 d-flex" v-else>
            <a class="text-caption mr-2 mt-1" :href="activeLink" target="_blank">
                {{ activeLink }}
            </a>
            <v-btn class="mr-2" @click="showEdit=true" small icon>
                <v-icon small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn @click="handleRemoveLink" small icon>
                <v-icon small>mdi-delete</v-icon>
            </v-btn>
        </v-card>

    </BubbleMenu>
</template>

<script>
import { BubbleMenu } from '@tiptap/vue-2'
const LinkReg = new RegExp("^(http|https)://", "i");
export default {
    name: 'LinkMenu',
    components: {
        BubbleMenu,
    },

    props: {
        editor: {
            type: Object,
            required: true,
        },
        appendTo: {
            default: () => document.body,
        },
    },

    data() {
        return {
            showEdit: false,
            url: '',
        }
    },

    computed: {
        tippyOptions() {
            return {
                popperOptions: {
                    modifiers: [{ name: 'flip', enabled: false }],
                },
                onHidden: () => {
                    this.showEdit = false
                }
            }

        },
        activeLink() {
            return this.editor?.getAttributes('link')?.href
        }
    },

    methods: {
        handleShouldShow() {
            return this.editor.isActive('link')
        },

        handleRemoveLink() {
            this.editor.chain().focus().unsetLink().run()
        },

        handleApplyEdits() {
            if (this.url && this.url.trim() && !LinkReg.test(this.url)) {
                this.url = `http://${this.url}`
            }
            this.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .setLink({ href: this.url })
                .run()
            this.showEdit = false
        }
    },
    watch: {
        activeLink: {
            handler(newVal) {
                if (newVal) {
                    this.url = newVal
                    this.showEdit = false
                }
            }
        }
    }
}
</script>