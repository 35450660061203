<template>
    <label class="text-input" :class="{ valid: checkValid }">
        {{ label }}
        <div>
            <textarea
                v-if="type == 'textarea'"
                v-model="content"
                ref="input"
                :placeholder="placeholder"
                @input="$emit('input', content)"
                @change="$emit('change')"
                :disabled="disabled"
            ></textarea>
            <input
                v-else
                ref="input"
                :size="size"
                :type="type"
                v-model="content"
                :placeholder="placeholder"
                @input="$emit('input', content)"
                @change="$emit('change')"
                @focus="clearTextInput($event)"
                @keyup.enter="$emit('keyup-enter', $event)"
                :disabled="disabled"
            />

            <span v-if="error">{{
                typeof error == typeof "" ? error : error[0]
            }}</span>
        </div>
    </label>
</template>

<script>
export default {
    name: "CustomInput",
    props: {
        type: {
            type: String,
            default: "text",
        },
        value: {
            required: true,
        },
        label: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        error: {
            required: false,
        },
        isValid: {
            type: Boolean,
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            size: null,
            content: this.value,
        };
    },
    methods: {
        clearTextInput(event) {
            if(event.target.value == "Type your question here") {
                event.target.value = "";
            }
        }
    },
    watch: {
        value() {

            this.content = this.value;

        },
    },
    mounted() {
        this.size = this.$refs.input.placeholder.length;
    },
    computed: {
        checkValid() {
            return this.content && this.content.length > 0;
        },
    },
};
</script>

<style lang="scss" scoped>
.text-input {
    @include text-input;
}
</style>
