<template>
    <div class="pa-6">
        <v-text-field outlined placeholder="Thank you for your time" v-model="survey.goodbye_message" @change="updateSurvey" :maxlength="80" :counter="80"></v-text-field>
        <div class="mt-4" />
        <div class="text-subtitle-2 mb-3">
            Subtitle
        </div>
        <Editor v-model="survey.goodbye_subtitle" @change="updateSurvey" :limit="270"></Editor>
        <div class="mt-2" />
        <v-text-field dense @click:clear="handleInstructionsClear"  class="text-body-2" clearable outlined placeholder="Instructions Message" v-model="instructionsMessage" @change="updateSurvey" :maxlength="80" :counter="80"></v-text-field>
    </div>
</template>

<script>
import ContainerCard from "@/components/dashboard/ContainerCard.vue";
import CustomInput from "@/components/elements/CustomInput.vue";
import Editor from '../../../../../components/Editor.vue';
import {pick} from 'lodash'
import BuilderApi from '../../../../../api/BuilderApi';

export default {
    name: "Finish",
    components: {
        CustomInput,
        ContainerCard,
        Editor
    },
    computed: {
        instructionsMessage: {
            get(){
                if(this.survey.settings?.goodbye_instructions_message === undefined){
                    return 'Your voiceform has been successfully submitted. You can close the window now 👍';
                }
                return this.survey.settings?.goodbye_instructions_message || '';
            },
            set(value){
                this.survey.settings.goodbye_instructions_message = value;
            }
        }
    },
    methods: {
        handleInstructionsClear(){
            this.instructionsMessage = null;
            this.updateSurvey();
        },
        async updateSurvey(){
            try {
                const updatedData = pick(this.survey, ['goodbye_message', 'goodbye_subtitle']);
                if(this.survey.settings?.goodbye_instructions_message !== undefined){
                    updatedData.settings = {
                        ...this.survey.settings,
                        goodbye_instructions_message: this.survey.settings?.goodbye_instructions_message || null
                    }
                }
                await BuilderApi.patchForm(this.survey.id, updatedData)
            } catch (error) {
                console.error('error in finish page update', error);
            }
        }
    },
    inject: ['survey'],
};
</script>

<style lang="scss" scoped>
</style>
