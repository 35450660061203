<template>
    <div>
        <v-text-field @change="updateQuestion" v-model="fieldPlaceholder" hide-details="auto" outlined dense class="mb-2 text-caption" placeholder="Field Placeholder"></v-text-field>
        <div class="d-flex">
            <v-text-field @change="updateQuestion" v-model="prefix" hide-details="auto" outlined dense class="mb-2 text-caption" placeholder="Prefix"></v-text-field>
            <div class="mx-1"></div>
            <v-text-field @change="updateQuestion" v-model="suffix" hide-details="auto" outlined dense class="mb-2 text-caption" placeholder="Suffix"></v-text-field>
        </div>
        <div class="mb-2">
            <div class="text-caption grey--text text--darken-1 mb-2">Allowed range</div>
            <div class="d-flex">
                <v-text-field @change="handleMinChange" v-model="minNumberProperty" hide-details="auto" prefix="Min" outlined dense class="text-caption" type="number" placeholder="number"></v-text-field>
                <div class="mx-1"></div>
                <v-text-field @change="handleMaxChange" v-model="maxNumberProperty" hide-details="auto" prefix="Max" outlined dense class="text-caption" type="number" placeholder="number"></v-text-field>
            </div>
        </div>
        <div class="d-flex align-center">
            <v-checkbox
                v-model="allowDecimalNumbersProperty"
                hide-details
                @change="updateQuestion"
                small
                class="ma-0 pa-0"
            ></v-checkbox>
            <div class="text-caption grey--text text--darken-1">Allow decimal numbers</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NumericInputType",
    props: {
        value: {
            required: true,
        },
    },
    data(){
        return {
            properties : {
                min_number: null,
                max_number: null,
                allow_decimal_numbers: false,
                field_placeholder: "",
                prefix: "",
                suffix: "",
                ...this.value
            }
        }
    },
    methods: {
        updateQuestion() {
            this.$emit('change', this.properties);
        },
        handleMinChange(){
            if(typeof this.minNumberProperty === 'number' && typeof this.maxNumberProperty ==='number' && this.minNumberProperty > this.maxNumberProperty) {
                this.properties = {
                    ...this.properties,
                    max_number: this.minNumberProperty,
                };
            }

            this.updateQuestion();
        },
        handleMaxChange(){
            if(typeof this.minNumberProperty === 'number' && typeof this.maxNumberProperty ==='number' && this.maxNumberProperty < this.minNumberProperty) {
                this.properties = {
                    ...this.properties,
                    min_number: this.maxNumberProperty,
                };
            }
            this.updateQuestion();
        },
        
    },
    computed: {
        minNumberProperty: {
            get: function () {
                return this.properties.min_number;
            },
            set: function (val) {
                try {
                    const min = val===''?null: Number(val);
                    this.properties = {
                        ...this.properties,
                        min_number: min,
                    };
                    
                } catch (error) {
                    this.properties = {
                        ...this.properties,
                        min_number: null,
                    };
                } finally {
                    this.$emit('input', this.properties);
                }
            },
        },
        maxNumberProperty: {
            get: function () {
                return this.properties.max_number;
            },
            set: function (val) {
                try { 
                    const max = val===''?null: Number(val);
                    this.properties = {
                        ...this.properties,
                        max_number: max,
                    };
                } catch (error) {
                    this.properties = {
                        ...this.properties,
                        max_number: null,
                    };
                }finally {
                    this.$emit('input', this.properties);
                }

            },
        },

        allowDecimalNumbersProperty: {
            get: function () {
                return this.properties.allow_decimal_numbers || false;
            },
            set: function (val) {
                this.properties = {
                    ...this.properties,
                    allow_decimal_numbers: val,
                };
                this.$emit('input', this.properties);
            },
        },

        fieldPlaceholder: {
            get: function () {
                return this.properties.field_placeholder || "";
            },
            set: function (val) {
                this.properties = {
                    ...this.properties,
                    field_placeholder: val,
                };
                this.$emit('input', this.properties);
            },
        },
        prefix: {
            get: function () {
                return this.properties.prefix || "";
            },
            set: function (val) {
                this.properties = {
                    ...this.properties,
                    prefix: val,
                };
                this.$emit('input', this.properties);
            },
        },
        suffix: {
            get: function () {
                return this.properties.suffix || "";
            },
            set: function (val) {
                this.properties = {
                    ...this.properties,
                    suffix: val,
                };
                this.$emit('input', this.properties);
            },
        }
    },
}
</script>