<template>
    <div>
        <div class="d-flex flex-column">
            <div class="text-subtitle-1 mb-3">Customize labels</div>
            <v-card elevation="0" color="deepPurple lighten-5" class="text-caption mb-4 pa-2">
                <v-icon small>mdi-information</v-icon>
                Tip: Keep labels at or below the original length to prevent UI disruptions.
            </v-card>
            <v-text-field
                v-for="(label, key) in customLabels"
                :key="key"
                :label="key.replace(/_/g, ' ')"
                :value="customLabels[key] || ''"
                @input="updateCustomLabel(key, $event)"
                @change="updateQuestion"
                outlined
                dense
                hide-details="auto"
                class="text-body-2 mb-3"
                clearable
            ></v-text-field>
        </div>
    </div>
</template>

<script>
export default {
    name: "CustomizeLabels",
    props: {
        question: {
            type: Object,
            required: true
        },
        defaultLabels: {
            type: Object,
            required: true
        }
    },
    inject: ["updateQuestion"],
    computed: {
        customLabels: {
            get() {
                // Merge default labels with stored customizations
                return { ...this.defaultLabels, ...this.question.properties.custom_labels || {} };
            },
            set(value) {
                this.question.properties.custom_labels = value||{};
            }
        }
    },
    methods: {
        updateCustomLabel(key, newValue) {
            // Create a copy of the customLabels to modify
            let updatedLabels = {...this.customLabels};
            
            // Update the specific label
            updatedLabels[key] = newValue;
            
            // Trigger the setter by reassigning customLabels
            this.customLabels = updatedLabels;
        }
    }
}
</script>