<template>
    <v-form ref="form">
        <div class="mt-4" v-if="properties && properties.options.length > 0">
            <div class="text-caption mb-2">Options</div>
            <draggable v-model="options" ghost-class="ghost" @change="updateQuestion" :options="{ handle: '.drag-handle' }">
                <div
                    class="d-flex mb-3"
                    v-for="(option, i) in properties.options"
                    :key="`option-${option.id}`"
                >
                    <v-icon class="grab drag-handle" small>
                        mdi-drag-vertical
                    </v-icon>
                    <v-text-field
                        hide-details="auto"
                        v-model="option.value"
                        placeholder="Option..."
                        outlined
                        dense
                        background-color="white"
                        class="text-body-2"
                        :rules="rules"
                        @change="updateQuestion"
                    ></v-text-field>
                    <v-btn
                        @click.prevent="handleDelete(i)"
                        icon
                    >
                        <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                </div>
            </draggable>
        </div>
        <div class="d-flex mt-3">
            <v-btn color="primary" elevation=0 @click="handleAddClick">
                <v-icon left>mdi-plus-circle</v-icon>
                Add Option
            </v-btn>
            <v-btn @click="bulkAddDialog=true" class="ml-2" color="secondary" outlined>
                <v-icon left>mdi-playlist-plus</v-icon>
                Bulk Add
            </v-btn>
        </div>
        <v-dialog v-model="bulkAddDialog" width="500px">
            <bulk-options-add @addOptions="handleBulkAddOptions"></bulk-options-add>
        </v-dialog>
    </v-form>
</template>


<script>
import draggable from 'vuedraggable'
import {mapMutations } from "vuex";
import { uuid } from 'vue-uuid';
import BulkOptionsAdd from '@/views/dashboard/voiceform/create/steps/question/types/BulkOptionsAdd.vue';
export default {
    name: "DropdownTypeProperties",
    props: {
        value: {
            required: true,
        },
    },
    components: {draggable, BulkOptionsAdd },
    data(){
        return {
            error: "",
            duplicateIndices: [],
            bulkAddDialog: false,

            properties : {options: [
                ...this.value.options || []
            ]},
            rules: [
                v => !!v || 'Option is required',
                v => this.duplicates[v] && this.duplicates[v]<=1 || 'Duplicate options not allowed',
            ]
        }
    },
    computed: {
        duplicates() {
            const values = {}
            this.properties.options.forEach(option => {
                if(values[option.value]) {
                    values[option.value] += 1;
                } else {
                    values[option.value] = 1;
                }
            });
            return values;
        },

        options: {
            get() {
                return this.properties.options;
            },
            set(value) {
                this.properties = {...this.properties, options: value };
                this.$emit('input', this.properties);
            }
        }
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        handleAddClick() {
            if(this.properties.options.some(item => item.value === "" || item.value === null)) {
                this.showSnackBar({
                    text: 'Cannot add an empty field', 
                    color: 'error', 
                    timeout: 2000,
                    
                })
                return;
            }
            const id = uuid.v4().replace(/-/g, "");
            this.$set(this.properties, 'options', [...this.properties.options, {id: `option_${id}` , value: ""}]);
            this.$emit('input', this.properties);
        },
        handleDelete(i) {
            this.duplicateIndices.pop(i);
            this.$set(this.properties, 'options', this.properties.options.filter((item, index) => index !== i));
            this.$emit('input', this.properties);
            this.updateQuestion();
        },
        updateQuestion() {
            const valid = this.$refs.form.validate();
            if(!valid) {
                return;
            }
            this.$emit('change', this.properties);
        },
        handleBulkAddOptions(options){
            this.bulkAddDialog = false;
            options.forEach(option => {
                // check if option does not exist
                if(this.properties.options.some(item => item.value === option)) {
                    return;
                }
                this.$set(this.properties, 'options', [...this.properties.options, {id: `option_${uuid.v4().replace(/-/g, "")}` , value: option}]);
            });
            this.$emit('input', this.properties);
            this.updateQuestion();
        },
    }
}
</script>


<style lang="scss" scoped>
.grab{
    cursor: grab;
    position: relative;;
    &:hover {
        color: var(v-primary-dark);
    }
}
.ghost{
    opacity: 0;
}
</style>