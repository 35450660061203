<template>
    <div>
        <question-text
            :question="question"
            :errors="errors"
            @change="updateQuestion"
            @focus="clearTextInput"
        ></question-text>
        <div class="d-flex mt-5">
            <v-select
                :items="choiceCountArray" 
                v-model="choiceCount"
                @change="updateQuestion"
                dense
                outlined
                class="mr-8"
                hide-details="auto"
            >
            </v-select>
            <v-btn-toggle
                v-model="choiceIcon"
                @change="updateQuestion"
                mandatory
                dense
                color="primary"
            >
                <v-btn
                    v-for="icon in icons"
                    :key="icon.value"
                    icon
                    :value="icon.value"
                    outlined
                >
                    <v-icon :color="choiceIcon === icon.value? 'primary darken-1': 'secondary lighten-2'">{{icon.mdi}}</v-icon>
                </v-btn>
            </v-btn-toggle>
        </div>
    </div>
</template>

<script>
import QuestionText from "../QuestionText.vue";

export default {
    name: "RatingType",
    components: { QuestionText },
    props: ["question", "errors"],
    inject: ["updateQuestion", "deleteChoices", "setChoices"],
    data() {
        return {
            choiceCountArray: [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            icons: [
                {
                    value: 'star',
                    mdi: 'mdi-star-outline'
                },
                {
                    value: 'heart',
                    mdi: 'mdi-heart-outline'
                },
                {
                    value: 'user',
                    mdi: 'mdi-account-outline'
                },
                {
                    value: 'number',
                    mdi: 'mdi-pound'
                },
            ]
        };
    },
    computed:{
        choiceCount: {
            get(){
                return this.question && this.question.multiple_choice_items && this.question.multiple_choice_items.length || 1
            },
            set(val){
                this.generateSteps(this.choiceIcon, val)
            },
        },
        choiceIcon: {
            get(){
                return this.question 
                && this.question.multiple_choice_items 
                && this.question.multiple_choice_items[0] 
                && this.question.multiple_choice_items[0].icon || 'star'
            },
            set(val){
                this.generateSteps(val, this.choiceCount)
            },
        }
    },
    methods: {
        clearTextInput() {
            if(this.question.text === "Type your question here") {
                this.question.text = "";
            }
        },
        generateSteps(icon, count){
            const choices = [];
            for (let i = 0; i < count; i++) {
                choices.push({ icon, order: i + 1 });
            }
            this.setChoices(choices);
        }
    },
};
</script>
