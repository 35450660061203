<template>
  <div>
    <div class="text-subtitle-1 mb-3">
      Images Attachment
    </div>
    <v-card :elevation="0" :class="`drop-container pt-7 pb-6 px-4 ${draggingOver?'primary lighten-4': ''}`" @dragover="dragover" @dragleave="dragleave" @drop="drop">
      <v-icon class="mb-2" large color="primary">mdi-folder-multiple-image</v-icon>
      <p class="mb-2">Drop your Images here, or <label role="button" class="primary--text" for="assetsFieldHandle">browse</label></p>
      <p style="text-align: center;" class="mb-2 grey--text">Supports:  JPG, JPEG2000, PNG, GIF</p>
      <input type="file" hidden multiple name="fields[assetsFieldHandle][]" id="assetsFieldHandle" @change="onChange" ref="file" accept=".jpg,.jpeg,.png,.gif" />
    </v-card>
    <v-card v-if="mediaAttachments && mediaAttachments.length" :elevation="0" color="attachments grey lighten-4 mt-4"  >
        <attachment-item  v-for="attachment in mediaAttachments" :key="attachment.order" :attachment="attachment" />
    </v-card>
    <template v-for="fileKey in  fileUploadKeys" >
        <v-card v-if="fileUploadMap[fileKey].status !== 'done'" :set="uploadItem = fileUploadMap[fileKey]" :key="fileKey" :elevation="0" color="upload-item grey lighten-4 pa-2 mt-4" class="uploading-card"  >
            <v-img
              class="loading-image-thumbnail"
              height="48px"
              width="48px"
              :src="getImage(uploadItem.file)"
            ></v-img>
            <div class="upload-item-info px-2">
              <p class="mb-1">{{isLoading(uploadItem)?'Uloading...': uploadItem.file.name }}</p>
              <p :class="[isError(uploadItem) ? 'error--text' : '', 'message-text']" v-if="uploadItem.message">{{uploadItem.message}}</p>
              <v-progress-linear
                indeterminate
                color="primary"
                v-if="isLoading(uploadItem)"
              ></v-progress-linear>
            </div>
            <div class="upload-item-controls">
              <v-btn v-if="isError(uploadItem)" class="mr-2" @click="uploadImage(fileUploadMap[fileKey].file)" icon :elevation="0">
                  <v-icon>
                    mdi-refresh
                  </v-icon>
                </v-btn>

              <v-btn v-if="!isLoading(uploadItem)" @click="remove(fileKey)" icon :elevation="0">
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </div>
        </v-card>
    </template>
  </div>
</template>

<script>
  import { uploadMediaFile} from '@/api/survey'
  import AttachmentItem from './AttachmentItem.vue'


  export default {
    name: "MediaAttachmentSection",
    data() {
      return {
        fileUploadMap: {},
        draggingOver: false
      }
    },
    components: {AttachmentItem},
    computed: {
      fileUploadKeys: function() { return Object.keys(this.fileUploadMap) },
      mediaAttachments(){
        const mediaAttachments = this.question.media_attachments || [];
        return mediaAttachments.sort((a, b) => a.order - b.order);
      }
    },
    inject: [
        "updateQuestion",
        "question",
        'survey'
    ],
    methods: {
      onChange() {
        Array.from(this.$refs.file?.files).forEach(file => {
          this.uploadImage(file)
        }); 
      },
      isLoading(uploadItem){
        return uploadItem.status === 'uploading'
      },
      isError(uploadItem){
        return uploadItem.status === 'error'
      },
      remove(key) {
        this.$delete(this.fileUploadMap, key)
      },
      getImage(file){
        return URL.createObjectURL(file)
      },
      addMediaAttachmentToQuestion(attachment){
          if(this.question.media_attachments){
            this.question.media_attachments.push(attachment)
          }else{
            this.question.media_attachments = [attachment]
          }
      },
      async uploadImage(file){

        try {
          if(this.fileUploadMap[file.name]){
            if(this.fileUploadMap[file.name].status === 'done'){
              return
            }
            this.$set(this.fileUploadMap, file.name, {
              ...this.fileUploadMap[file.name],
              status: 'uploading',
            })
          }else{
            this.$set(this.fileUploadMap, file.name, {
              status: 'uploading',
              file
            })
          }

          const res = await uploadMediaFile(file, this.survey.hash)

          if(res && res.data){

            const order = this.mediaAttachments.length
            this.addMediaAttachmentToQuestion({
              order,
              url: res.data.url,
            })
            this.updateQuestion()
            return this.$delete(this.fileUploadMap, file.name)
          }          
        } catch (error) {
          this.$set(this.fileUploadMap, file.name, {
            ...this.fileUploadMap[file.name],
            status: 'error',
          })
          console.error('Upload error', error);
        }
        
      },
      dragover(event) {
        event.preventDefault();
        this.draggingOver=true
      },
      dragleave(event) {
        event.preventDefault();
        this.draggingOver=false
      },
      drop(event) {
        event.preventDefault();
        this.$refs.file.files = event.dataTransfer.files;
        this.onChange(); // Trigger the onChange event manually
        // Clean up
        this.draggingOver=false
      }
    }
    
  }
</script>

<style lang="scss" scoped>
  .drop-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dashed rgba(0, 0, 0, 0.23);
  }
  .loading-image-thumbnail{
    flex-grow: 0;
    border-radius: 4px;
  }
  .attachments{
    display: flex;
    flex-wrap: wrap;
  }
  .upload-item{
    display: flex;
    &-info{
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
    &-controls {
      display: flex;
      flex-grow: 0;
      justify-content: center;
      align-items: center;
    }
  }
  .message-text {
    font-size: 12px;
  }
</style>
