<template>
    <div>
        <v-tooltip max-width="250" bottom>
            <template v-slot:activator="{ on, attrs }">
                <div class="mt-4" v-bind="attrs" v-on="on">
                    <v-badge
                        bordered
                        color="primary"
                        :content="vocabularyLength"
                        :value="!!vocabularyLength"
                        overlap
                    >
                        <v-btn 
                            elevation="0"
                            class="text-body-2"
                            @click="handleVocabularyOpen"
                        >
                            <v-icon left>mdi-book-open-outline</v-icon>
                            Vocabulary
                        </v-btn>
                    </v-badge>
                </div>
            </template>
            <span class="text-caption">Add vocabulary for better transcription performance.</span>
        </v-tooltip>
        <v-dialog
            v-model="vocabularyDialog"
            width="500"
            eager
        >
            <v-card class="pa-4">
                <div class="subtitle-1 mb-3">Vocabulary Settings</div>
                <div class="text-body-1 mb-3">List words that you would like to be transcribed properly.</div>
                <div class="mb-3">
                    <input-tag 
                        class="vocabulary-input text-body-1" 
                        :limit="50" add-tag-on-blur 
                        :add-tag-on-keys="[13, 188, 9]" 
                        placeholder="Enter words, separated by commas"  
                        v-model="vocabularyWords"
                        ref="inputTag"
                    >
                    </input-tag>
                </div>
                <div class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn class="mr-2" @click="vocabularyDialog = false" elevation="0">Cancel</v-btn>
                    <v-btn color="primary" elevation="0" @click="saveVocabulary">Save</v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import InputTag from 'vue-input-tag'

export default {
    name: "VocabularySettings",
    inject: ["updateQuestion", 'question'],
    components: {InputTag},
    data(){
        return {
            vocabularyDialog: false,
            vocabularyWords: this.question.properties.vocabulary || [],
        }
    },
    computed: {
        vocabularyLength(){
            return this.question.properties.vocabulary && this.question.properties.vocabulary.length || 0;
        },
    },
    methods: {
        saveVocabulary(){
            const inputElement = this.$refs?.inputTag?.$el?.querySelector('input');
            if(inputElement){
                inputElement.blur();
            }

            const formattedWords = this.vocabularyWords.reduce((acc, word) => {
                const formattedWord = word.trim();
                if(formattedWord){
                    acc.push(formattedWord);
                }
                return acc;
            }, []);

            this.question.properties = {
                ...this.question.properties,
                vocabulary: formattedWords && formattedWords.length ? formattedWords : null
            }

            this.updateQuestion();
            this.vocabularyDialog = false;
        },
        handlePaste(event) {
            // Access the pasted data
            let pastedData = (event.clipboardData || window.clipboardData).getData('text');

            // Process the pasted data (e.g., split by commas)
            let newTags = pastedData.split(',').map(tag => tag.trim()) || [];

            // Combine existing tags and new tags, ensuring uniqueness
            let allTags = new Set([...this.vocabularyWords, ...newTags]);

            // Convert Set back to array
            this.vocabularyWords = Array.from(allTags);

            // Prevent the default paste behavior
            event.preventDefault();
        },
        handleVocabularyOpen(){
            this.vocabularyWords = this.question.properties.vocabulary || [];
            this.vocabularyDialog = true;
        },

    },

    mounted() {
        // Wait for the next DOM update cycle to ensure the input element is available
        this.$nextTick(() => {
            const inputElement = this.$refs?.inputTag?.$el?.querySelector('input');
            if (inputElement) {
                inputElement.addEventListener('paste', this.handlePaste);
            }
        });
    },
    beforeDestroy() {
        const inputElement = this.$refs?.inputTag?.$el?.querySelector('input');
        if (inputElement) {
            inputElement.removeEventListener('paste', this.handlePaste);
        }
    }
};
</script>

<style lang="scss" scoped>

.vocabulary-input{
    border-radius: 4px;

}
.vocabulary-input::v-deep .input-tag {
    padding: 4px 8px;
    color: var(--v-primary-darken4);
    background-color: var(--v-primary-lighten4);
    border: 1px solid var(--v-primary-lighten3);
    .remove{
        color: var(--v-primary-darken4);
        padding: 0 4px;
    }
}
</style>