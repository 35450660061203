var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(_vm.editing)?_c('div',{staticClass:"hover-container",style:({
            borderRadius: '4px',
            color: _vm.survey.style ? _vm.survey.style.color : _vm.survey.font_color,
            backgroundColor:  _vm.styles.dark?'rgba(255,255,255,0.15)':'rgba(0,0,0,0.03)',
        })},[_c('editor-content',{staticClass:"headingEditor",attrs:{"editor":_vm.editor}})],1):_c('div',{class:['text-h5', {'question-required': !_vm.question.is_optional, 'hover-container':hover}],style:({
            color: _vm.survey.style ? _vm.survey.style.color : _vm.survey.font_color,
            backgroundColor: hover ? (_vm.styles.dark?'rgba(255,255,255,0.15)':'rgba(0,0,0,0.03)') : 'transparent',
            opacity: _vm.showPlaceholder ? 0.4 : 1,
            textStyle: _vm.showPlaceholder ? 'italic' : 'normal',
        }),on:{"click":_vm.startEditing}},[_vm._v(" "+_vm._s(_vm.questionText)+" "),(_vm.showPlaceholder)?_c('i',{staticClass:"pr-2"},[_vm._v(_vm._s(("Type " + (_vm.question.type==='description-slide'? 'description title': 'question') + " here")))]):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }