<template>
    <div class="question" v-if="question">
        <div class="pa-6">
            <v-card color="deepOrange lighten-5" v-if="showLogicBanner" flat class="d-flex px-1 py-2 mb-4" rounded>
                <v-icon color="deepOrange" class="px-4">mdi-transit-connection-variant</v-icon>
                <div class="text-body-2 deepOrange--text text--deepOrange-darken4">This question has logic. Please make sure its not affected after edits.</div>
            </v-card>
            <type-switcher :questionType="question.type" @changeType="changeType"></type-switcher>
            <component
                :is="question.type"
                :question="question"
                :errors="errors"
            />
        </div>
        <v-divider></v-divider>
        <actions :key="`question-actions-${question.id}`"></actions>
        <v-divider></v-divider>
        <v-expansion-panels style="margin-left: 1px; margin-right: 1px;" flat>
            <v-expansion-panel>
                <v-expansion-panel-header color="grey lighten-5" flat>
                    Attachments
                </v-expansion-panel-header>
                <v-expansion-panel-content class="attachments-panel">
                    <div v-if="showMediaAttachment" class="pa-6">
                        <media-attachment-section />
                    </div>
                    <v-divider v-if="showAudioAttachment"></v-divider>
                    <audio-attachment v-if="showAudioAttachment"/>
                    <v-divider v-if="showVideoAttachment"></v-divider>
                    <video-attachment-section v-if="showVideoAttachment"/>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-divider></v-divider>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import ContainerCard from "@/components/dashboard/ContainerCard.vue";
import StepTypeIcon from "@/components/icons/StepTypeIcon.vue";
import CustomPopup from "@/components/CustomPopup.vue";
import MediaAttachmentSection from './MediaAttachmentSection.vue'
import TypeSwitcher from './TypeSwitcher.vue';
import Actions from './Actions.vue';

import VoiceQuestion from "./types/VoiceQuestionType.vue";
import VoiceResponse from "./types/VoiceResponseType.vue";
import MultipleChoice from "./types/MultipleChoiceType.vue";
import DatePicker from "./types/DatePickerType.vue";
import Checkbox from "./types/CheckboxType.vue";
import Email from "./types/EmailType.vue";
import PhoneNumber from "./types/PhoneNumberType.vue";
import NumericInput from "./types/NumericInputType.vue";
import Rating from "./types/RatingType.vue";
import NetPromoterScore from "./types/NetPromoterScoreType.vue";
import Name from "./types/NameType.vue";
import ZipCode from './types/ZipCodeType.vue'
import Yesno from "./types/YesnoType.vue";
import Dropdown from "./types/DropdownType.vue";
import CustomField from "./types/CustomFieldType.vue";
import FileUpload from "./types/FileUploadType.vue";
import Matrix from './types/MatrixType.vue';
import Editor from '../../../../../../components/Editor.vue';
import DescriptionSection from './DescriptionSection.vue';
import VideoAttachmentSection from "./VideoAttachmentSection.vue";
import AudioAttachment from './AudioAttachment.vue';
import BuilderApi from '@/api/BuilderApi';
import { DROPDOWN, MULTIPLE_CHOICE, CHECKBOX } from "@/configs/questionTypes";


export default {
    name: "Question",
    components: {
        VoiceResponse,
        MultipleChoice,
        DatePicker,
        Checkbox,
        Email,
        PhoneNumber,
        Rating,
        NetPromoterScore,
        Name,
        ZipCode,
        Yesno,
        Dropdown,
        CustomField,
        FileUpload,
        ContainerCard,
        StepTypeIcon,
        CustomPopup,
        VoiceQuestion,
        MediaAttachmentSection,
        Editor,
        DescriptionSection,
        VideoAttachmentSection,
        AudioAttachment,
        TypeSwitcher,
        NumericInput,
        Matrix,
        Actions
    },
    provide() {
        return {
            deleteChoiceFromQuestion: this.deleteChoiceFromQuestion,
            addChoiceToQuestion: this.addChoiceToQuestion,
            setChoices: this.setChoices,
            deleteChoices: this.deleteChoices,
        };
    },
    data() {
        return {
            stepTypesPopup: false,
            errors: {
                name: false,
            },
        };
    },

    computed: {
        ...mapGetters({
            features: "auth/features",
        }),

        showMediaAttachment(){
            return !!this.features && this.features.image_attachment
        },

        showVideoAttachment(){
            return !!this.features && this.features.video_attachment
        },
        showAudioAttachment(){
            return this.question && this.question.type!=='voice-question'
        },
        showLogicBanner(){
            return [MULTIPLE_CHOICE, CHECKBOX, DROPDOWN].includes(this.question.type) && this.question.has_condition_reference 
        }
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        addChoiceToQuestion() {
            this.question?.multiple_choice_items?.push({ value: "" })
            this.updateQuestion()
        },
        deleteChoiceFromQuestion(index) {
            this.question?.multiple_choice_items?.splice(index, 1)
        },
        setChoices(choices) {
            this.question.multiple_choice_items = choices
        },
        deleteChoices() {
            this.question.multiple_choice_items = []
        },
        async changeType(newType) {
            try {
                const res = await BuilderApi.changeQuestionType(
                    this.$route.params.surveyId,
                    this.$route.params.stepType,
                    newType
                );

                if (res && res.data && res.data.newQuestionId) {
                    this.refreshQuestionsList()
                    this.$router.replace({
                        name: 'dashboard.voiceform.show.question',
                        params: {
                            stepType: res.data.newQuestionId,
                            surveyId: this.$route.params.surveyId
                        },
                    });
                }
            } catch (error) {
                this.showSnackBar({
                    text: 'Cannot change question type.',
                    color: 'error',
                    timeout: 2000,
                });
                console.log('change question type error: ', error);
            }
        },
    },
    inject: ['question', 'updateQuestion', 'refreshQuestionsList']
};
</script>

<style lang="scss" scoped>
.question {
    width: 100%;
    height: max-content;
    &-card{
        overflow: hidden;
    }
    &--buttons {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        .back {
            @include button-black;
        }
        .add {
            @include button;
        }
    }
    padding-bottom: 100px;
}

.question-types {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20rem;
    row-gap: 2rem;
}

.attachments-panel::v-deep .v-expansion-panel-content__wrap{
    padding: 0px;
}
</style>
