<template>
    <NodeViewWrapper>
        <div :class="wrapperClassName" :style="{ width: node.attrs.width, maxWidth: '600px' }" @click="handleClick">
            <div contentEditable="false">
                <img class="block" :src="src" alt="" />
            </div>
        </div>
    </NodeViewWrapper>
</template>

<script>
import {NodeViewWrapper } from '@tiptap/vue-2';

export default {
    props: {
        editor: {
            type: Object,
            required: true,
        },
        node: {
            type: Object,
            required: true,
        },
        getPos: {
            type: Function,
            required: true,
        },
        updateAttributes: {
            type: Function,
            required: true,
        }
    },
    computed: {
        src() {
            return this.node?.attrs?.src || '';
        },
        wrapperClassName() {
            const align = this.node?.attrs?.align || 'center';
            return {
                'block': true,
                'ml-0': align === 'left',
                'mr-0': align === 'right',
                'mx-auto': align === 'center',
                'ml-auto': align !== 'left',
                'mr-auto': align !== 'right',
                'selected': this.isSelected,
            };
        },
        isSelected() {
            const { from, to } = this.editor.state.selection;
            const nodePos = this.getPos();
            return from <= nodePos && to >= nodePos + this.node.nodeSize;
        }
    },
    methods: {
        handleClick() {
            this.editor.commands.setNodeSelection(this.getPos());
        }
    },
    components: {
        NodeViewWrapper
    }
};
</script>

<style scoped>
.ml-0 {
    margin-left: 0;
}
.mr-0 {
    margin-right: 0;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.ml-auto {
    margin-left: auto;
}
.mr-auto {
    margin-right: auto;
}
.block {
    display: block;
    cursor: pointer;
}
.selected {
    outline: 1px solid var(--v-primary-lighten1);
    border-radius: 4px;
}
</style>
