<template>
    <div class="width-resize-right" @mousedown.stop="startDrag"></div>
</template>

<script>
export default {
  data() {
    return {
      initialX: 0,
      totalDelta: 0, // Added to track the total movement
    };
  },
  methods: {
    startDrag(e) {
      e.preventDefault(); // Prevent default action.
      this.isDragging = true;
      this.initialX = e.clientX;
      // Attach mousemove and mouseup listeners to document to ensure smooth dragging.
      document.addEventListener('mousemove', this.dragging);
      document.addEventListener('mouseup', this.stopDrag);
    },
    dragging(e) {
      if (!this.isDragging) return;
      // Calculate delta.
      const deltaX = e.clientX - this.initialX;
      // Emit the delta value through an event.
      this.$emit('resizeDragging', deltaX);
      // Update initialX for continuous dragging.
      this.initialX = e.clientX;
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.dragging);
      document.removeEventListener('mouseup', this.stopDrag);
      // Optionally, emit a final resize event with delta 0 or emit a 'stop' event if needed.
      this.$emit('resizeStopDragging');
    }
  }
};
</script>

<style lang="scss" scoped>
.width-resize-right{
    position: absolute;
    top: -1px;
    bottom: -1px;
    right: -4px;
    width: 8px;
    cursor: col-resize;
    z-index: 2;
    height: 100%;
}
</style>

