<template>
    <v-card flat color="grey lighten-5" class="mt-4 pa-4">
        <div class="mb-3 d-flex">
            <div>
                <v-icon left>mdi-form-textbox</v-icon>Text input option
            </div>
            <v-spacer></v-spacer>
            <v-switch hide-details="auto" @change="updateQuestion" class="ma-0" small
                v-model="question.enable_text_input"></v-switch>
        </div>
        <v-text-field clearable hide-details="auto" min="0" suffix="character minimum"
            placeholder="Unset" @change="updateQuestion" dense outlined v-model="number" type="number">
        </v-text-field>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'RecordingLimit',
    inject: ['updateQuestion'],
    props: {
        question: Object
    },
    computed: {
        ...mapGetters({
            features: 'auth/features'
        }),
        number: {
            get() {
                return this.question.properties.minNumberOfCharacters || '';
            },
            set(val) {
                try { 
                    const minNumberOfCharacters = val===''?null: Number(val);
                    this.question.properties = {
                        ...this.question.properties,
                        minNumberOfCharacters: minNumberOfCharacters > 0 ? Math.round(minNumberOfCharacters) : null,
                    };
                } catch (error) {
                    this.question.properties = {
                        ...this.question.properties,
                        minNumberOfCharacters: null,
                    };
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.dialog-title {
    display: inline-block;
    max-width: 360px;
}
</style>