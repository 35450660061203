<template>
    <div>
        <question-text
            :question="question"
            :errors="errors"
            @change="updateQuestion"
            @focus="clearTextInput"
            class="mb-3"
        ></question-text>
        <v-checkbox
            v-model="randomizeOptions"
            label="Randomize Options"
            hide-details="auto"
        ></v-checkbox>
        <v-expand-transition>
            <div v-if="randomizeOptions" class="d-flex align-center mt-2 mb-2">
                <v-icon color="grey">mdi-arrow-right-bottom</v-icon>
                <v-autocomplete
                    v-model="excludedFromRandomization"
                    :items="itemsList"
                    outlined
                    dense
                    chips
                    small-chips
                    label="Exclude from randomization"
                    multiple
                    class="text-body-2"
                    hide-details="auto"
                ></v-autocomplete>
            </div>
        </v-expand-transition>
        <options-editor></options-editor>
    </div>
</template>

<script>
import QuestionText from "../QuestionText.vue"
import OptionsEditor from './OptionsEditor.vue';

export default {
    name: "DropdownType",
    props: ["errors"],
    components: { QuestionText, OptionsEditor },
    inject: [
        "updateQuestion",
        "question"
    ],
    computed: {
        randomizeOptions: {
            get() {
                return this.question.properties && this.question.properties.randomize_options || false;
            },
            set(value) {
                this.question.properties = {...this.question.properties, randomize_options: value };
                this.updateQuestion();
            }
        },
        itemsList() {
            // Create a Set to hold unique values
            const uniqueValuesSet = new Set();

            // Check if multiple_choice_items is defined and is an array
            if (Array.isArray(this.question.multiple_choice_items)) {
                // Add values from multiple_choice_items to the Set
                this.question.multiple_choice_items.forEach(item => {
                    if (item && item.value !== undefined) {
                        uniqueValuesSet.add(item.value);
                    }
                });
            }

            // Check if excluded_from_randomization is defined and is an array
            const excludedValues = this.question.properties?.excluded_from_randomization;
            if (Array.isArray(excludedValues)) {
                // Add values from excluded_from_randomization to the Set
                excludedValues.forEach(value => {
                    if (value !== undefined) {
                        uniqueValuesSet.add(value);
                    }
                });
            }

            // Convert the Set back to an array
            return Array.from(uniqueValuesSet);
        },
        excludedFromRandomization: {
            get() {
                return this.question.properties && this.question.properties.excluded_from_randomization || [];
            },
            set(value) {
                this.question.properties = {...this.question.properties, excluded_from_randomization: [...value] };
                this.updateQuestion();
            }
        },
    },
    methods: {
        clearTextInput() {
            if(this.question.text === "Type your question here") {
                this.question.text = "";
            }
        }
    }
};
</script>