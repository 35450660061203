<template>
    <v-hover v-slot="{ hover }">
        <div v-if="editing" 
            class="hover-container"
            :style="{
                borderRadius: '4px',
                color: survey.style ? survey.style.color : survey.font_color,
                backgroundColor:  styles.dark?'rgba(255,255,255,0.15)':'rgba(0,0,0,0.03)',
            }"
        >
            <editor-content class="headingEditor"   :editor="editor" />
        </div>
        <div 
            v-else
            @click="startEditing" 
            :class="['text-h5', {'question-required': !question.is_optional, 'hover-container':hover}]" 
            :style="{
                color: survey.style ? survey.style.color : survey.font_color,
                backgroundColor: hover ? (styles.dark?'rgba(255,255,255,0.15)':'rgba(0,0,0,0.03)') : 'transparent',
                opacity: showPlaceholder ? 0.4 : 1,
                textStyle: showPlaceholder ? 'italic' : 'normal',
            }"
        >
            {{ questionText}}

            <i class="pr-2" v-if="showPlaceholder">{{`Type ${question.type==='description-slide'? 'description title': 'question'} here`}}</i>
        </div>
    </v-hover>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import Document from '@tiptap/extension-document'
import Text from '@tiptap/extension-text'
import Heading from '@tiptap/extension-heading'
import History from '@tiptap/extension-history'
export default {
    name: "QuestionTextInput",
    props: ['styles'],
    inject: ["updateQuestion", 'survey', 'question'],
    components: {
        EditorContent,
    },

    data() {
        return {
            editing: false,
            editor: null,
        }
    },
    computed: {
        questionText() {
            return this.question.text;
        },
        showPlaceholder() {
            if(this.question.text){
                return !this.question.text.trim()
            }
            return !this.question.text;
        }
    },
    methods: {
        startEditing() {
            this.editing = true;
            this.$nextTick(() => {
                this.editor.commands.focus();
                this.editor.commands.selectAll();
            });
        }
    },
    mounted() {
        const NoBreakText = Text.extend({
            addKeyboardShortcuts() {
                return {
                    Enter: () => {
                        this.editor.commands.blur();
                        return true;
                    },
                    'Shift-Enter': () => {
                        this.editor.commands.blur();
                        return true;
                    }
                };
            },
        });
        this.editor = new Editor({
            content: this.question.text,
            extensions: [
                Document,
                NoBreakText,
                Heading.configure({
                    levels: [1],
                }),
                History
            ],
            onBlur: () => {
                this.editing = false;
                let text = this.editor.getText();
                // trim
                text = text.trim();
                // remove all line breaks
                text = text.replace(/\n/g, ' ');
                if(text !== this.question.text){
                    this.question.text = text;
                    this.updateQuestion();
                }
            }
        })



        


    },
    beforeDestroy() {
        this.editor.destroy()
    },

}
</script>

<style lang="scss" scoped>
.headingEditor::v-deep h1{
    font-size: 3rem !important;
    font-weight: 500 !important;
    line-height: unset !important;
    letter-spacing: normal !important;
    font-family: "Poppins", sans-serif !important;
}
.headingEditor::v-deep .ProseMirror{
    padding: 0 !important;
    margin: 0 !important;
}
.question {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 700px;
    &--description{
        word-break: break-word;
    }
    &-required::after{
        content: "*";
        
    }
    .audio-play {
        width: 100%;
        display: grid;
        grid-auto-flow: column;
        grid-gap: 1.4rem;
        align-items: center;
        grid-template-columns: max-content 1fr;

        &--button {
            background: #000000;
            border-radius: 8px;
            cursor: pointer;

            height: max-content;
            font-weight: 700;
            font-size: 17px;
            line-height: 21px;

            color: #ffffff;
            padding: 10px;
            display: flex;

            .play-icon {
                border: 0;
                background: transparent;
                box-sizing: border-box;
                width: 0;
                height: 10px;

                border-color: transparent transparent transparent #ffffff;
                transition: 100ms all ease;
                cursor: pointer;

                border-style: solid;
                border-width: 5px 0 5px 8px;

                &.pause {
                    border-style: double;
                    border-width: 0px 0 0px 8px;
                }
            }
        }
    }
}
.hover-container {
    cursor: pointer;
    padding: 4px 8px;
    margin: -4px -8px;
    border-radius: 4px;
}
</style>