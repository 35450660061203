<template>

    <v-card elevation="0" :color="locked?'deep-orange lighten-5':'#75a99b15'" class="mt-4 pa-4">
        <div class="mb-3 d-flex">
            <div class="d-flex align-center">
                <span class=" gpt-icon mr-2 deep-orange" v-if="locked">
                    <v-icon dark>mdi-lock</v-icon>
                </span>
                <span v-else class="gpt-icon mr-2" :style="{backgroundColor: error? 'var(--v-error-darken1)' :'rgb(25, 195, 125)'}">
                    <v-icon dark>{{error?'mdi-robot-dead':'mdi-robot'}}</v-icon>
                </span>
                AI Probing
            </div>

            <v-spacer></v-spacer>
            <v-switch :disabled="locked" color="rgb(25, 195, 125)" hide-details="auto" @change="updateQuestion" class="ma-0" small v-model="question.properties.enable_probing"></v-switch>
        </div>
        <div class="text-caption" v-if="locked">
            You have reached your AI Probe limit until <b>{{$date(features.probing_unlocks_at).format('MMM D')}}</b>.
            Please <a @click="handleContactUs">contact us</a> if you wish to upgrade.
        </div>
        <v-card v-if="error" elevation="0" class="pa-3 mb-3" color="red darken-2 text-caption">
            <v-icon left color="white">mdi-alert</v-icon>
            <span class="white--text">{{error}}</span>
        </v-card>
        <v-tooltip v-if="question.properties.enable_probing" color="#75a99b" left>
            <template  v-slot:activator="{ on }">
                <div v-on="on">
                    <v-select
                        :items="followupLimits"
                        v-model="maxProbes"
                        label="max number of probes"
                        dense
                        outlined
                        hide-details="auto"
                        @change="updateQuestion"
                    ></v-select>
                </div>
            </template>
            <div class="text-caption" style="max-width: 250px">
                <v-icon color="white" small>mdi-information</v-icon>
                The maximum number of times the AI will follow up with a question to get more information.
            </div>
        </v-tooltip>

        <v-tooltip v-if="question.properties.enable_probing" color="#75a99b" left>
            <template  v-slot:activator="{ on }">
                <div v-on="on">
                    <v-textarea rows="4" class="mt-3"  hide-details="auto" auto-grow outlined placeholder="What is the goal for your question probes? (i.e collect at least 3 pain points with the product)" v-model="question.properties.goal" @change="updateQuestion"></v-textarea>
                </div>
            </template>
            <div class="text-caption" style="max-width: 250px">
                <v-icon color="white" small>mdi-information</v-icon>
                The goal for your question probes. This will help the AI to ask the right questions and make sure that you get the deep insights you need.
            </div>
        </v-tooltip>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { Crisp } from 'crisp-sdk-web';

export default {
    name: 'QuestionProbing',
    inject: ['updateQuestion'],
    props: {
        question: Object
    },
    data() {
        return {
            showNumberOfProbesTooltip: false,
            followupLimits: [
                {value: null, text: 'Auto'},
                {value: 1, text: '1'},
                {value: 2, text: '2'},
                {value: 3, text: '3'},
                {value: 4, text: '4'},
                {value: 5, text: '5'}
            ] 
        }
    },
    computed: {
        ...mapGetters({
            features: 'auth/features'
        }),
        locked(){
            return (this.features.probing_unlocks_at && new Date(this.features.probing_unlocks_at) > new Date())
        },
        error(){
            if(this.question.properties.enable_probing && !this.question.text && !this.question.properties.goal){
                return 'Please provide the goal below or specify the question above to enable AI Probing.'
            }

            return false
        },
        maxProbes: {
            get() {
                return this.question.properties.max_probes || null;
            },
            set(value) {
                let number =  parseInt(value, 10)
                if(number<0){
                    number = 0
                }
                this.question.properties.max_probes =  Math.round(number)
            }
        },
    },
    methods: {
        handleContactUs(){
            Crisp.chat.open();
            Crisp.message.send("text", "Hi, I've used all trial AI probes. I would like to upgrade my plan.")
        }
    }
};
</script>

<style lang="scss" scoped>
.gpt-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 4px
}
.dialog-title {
    display: inline-block;
    max-width: 360px;
}
</style>