<template>
    <div>
        <div>
            <div>
                <v-icon left>mdi-timer-outline</v-icon> Response duration: <span class="text-subtitle-1">{{ recordLengthConstraints }}</span>
            </div>
            <v-range-slider class="mx-0" v-model="intervalBound" :max="maxRecordingLimit>600? maxRecordingLimit : 600" min="0" step="5"
                 :rules="planUpperLimit" hide-details="true" @change="handleRangeChange">
            </v-range-slider>
            <div class="d-flex text-caption mx-2 mt-n2 grey--text text--darken-1">
                <span>0s</span>
                <v-spacer></v-spacer>
                <span class="text-right">{{maxRecordingLimit>600? `${maxRecordingLimit/60}min`:'10min'}}</span>
            </div>
        </div>
        <v-dialog v-model="showUpgradeDialog" width="500" class="audio-upgrade-dialog">
            <v-card class="px-7 pt-7 pb-5">
                <div class="text-h5 dialog-title">
                    Sorry, selecting a longer time limit is not available on your current plan
                </div>
                <img src="https://cdn.voiceform.com/media/time-upgrade.svg" class="my-4 mx-auto"
                    style="display: block; width: 350px" />
                <router-link :to="{ name: 'dashboard.upgrade' }">
                    <v-btn block color="primary" elevation="0">Upgrade your plan</v-btn>
                </router-link>
                <v-btn block text class="mt-3" @click="showUpgradeDialog = false">Maybe later</v-btn>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'RecordingLimit',
    data() {
        return {
            showUpgradeDialog: false,
            planUpperLimit: [v => {
                return v[0] >= 0 && v[1] >= 30 && v[1] <= this.features.max_audio_response_time;
            }]
        };
    },
    inject: ['updateQuestion'],
    props: {
        question: Object
    },
    computed: {
        ...mapGetters({
            features: 'auth/features'
        }),
        maxRecordingLimit() {
            return this.features.max_audio_response_time;
        },
        
        recordLengthConstraints() {
            const lowerBound = this.$date.duration(this.question.properties.minRecordTime*1000).format('mm:ss') 
            const upperBound = this.$date.duration(this.question.properties.maxRecordTime*1000).format('mm:ss')
            return `${lowerBound}s - ${upperBound}s`;
        },
        intervalBound: {
            get() {
                return [this.question.properties.minRecordTime, this.question.properties.maxRecordTime];
            },
            set([minRecordTime, maxRecordTime]) {
                this.question.properties = {...this.question.properties, minRecordTime, maxRecordTime };
            }
        }
    },
    methods: {
        handleRangeChange() {
            if (this.question.properties.maxRecordTime > this.features.max_audio_response_time) {
                this.setUpperMaxAudioLength();
                return this.showUpgradeDialog = true;
            }
            if (this.question.properties.maxRecordTime < 30) {
                this.question.properties.maxRecordTime = 30;
            }
            this.updateQuestion();
        },
        setUpperMaxAudioLength() {
            this.question.properties.maxRecordTime = this.features.max_audio_response_time;
            this.updateQuestion();
        }
    }
};
</script>

<style lang="scss" scoped>
.dialog-title {
    display: inline-block;
    max-width: 360px;
}
</style>