<template>
    <div class="welcome">
        <v-alert v-if="survey.settings.hide_welcome_step" color="secondary lighten-4" :style="{opacity: 0.9, color: 'white'}">
            <v-icon color="white" left>mdi-eye-off</v-icon>
            This page will be hidden in the published form.
        </v-alert>
        <div class="welcome-content mb-4" :style="{ color: styles.color }">
            <v-img
                v-if="survey.welcome_page_file_url"
                height="120"
                width="120"
                :src="survey.welcome_page_file_url"
                class="welcome-image"
            />
            <div
                class="text-h4 welcome-title"
            >
                {{ survey.title }}
            </div>

            <div
                class="welcome-subtitle"
                v-html="survey.subtitle"
            >
            </div>
            <div class="d-flex flex-column align-center">
                <div v-if="showPrivacyPolicyText" class="text-center text-caption mt-10" v-html="survey.privacy_text"
                :style="{ color: styles.backgroundTextColor }"></div>
                <click-prevent>
                    <div :class="[{ 'mt-10': !showPrivacyPolicyText }, { 'mt-4': showPrivacyPolicyText }, 'd-flex flex-column']">
                        <v-btn min-width="200" :width="'200'"
                            elevation="0" large :color="styles.button.background"
                            :style="`color: ${styles.button.color}`">
                            {{ this.localization.start_button_label || "Let's start" }}
                        </v-btn>
                    </div>
                </click-prevent>
            </div>
        </div>
    </div>
</template>

<script>
import ClickPrevent from './ClickPrevent.vue';
export default {
  components: { ClickPrevent },
    name: "WelcomeStep",
    inject: ['survey'],
    props: ["contrastTextColor", 'styles'],
    computed: {
        showPrivacyPolicyText(){
            return this.survey.privacy_text && this.survey.privacy_text !== '<p></p>'
        },
        localization(){
            return this.survey.settings.localization
        },
    }
};
</script>

<style lang="scss" scoped>
.welcome {
    &-image {
        border-radius: 50%;
        border: solid 5px #ffffff50;
    }

    &-content {
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
    }

    &-title {
        padding-top: 32px;
        font-size: 30px !important;
        text-align: center;
    }
    &-subtitle {
        padding-top: 20px;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 31px;
        max-width: 400px;
        text-align: center;
    }
    &-overline {
        max-width: 420px;
        margin-top: 8px;
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
    }

    &-raw {
        display: flex;
        align-items: center;
    }
}
</style>
