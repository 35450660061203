<template>
    <div>
        <table
            v-click-outside="{
                handler: onClickOutsideOfTable,
                closeConditional: shouldTriggerClickOutsideTable
            }"
            class="matrix-table"
        >
            <thead>
                <tr>
                    <!-- for empty corner -->
                    <td class="editor-cell"></td>
                    <!-- for row titles -->
                    <td class="editor-cell"></td>
                    <td
                        v-for="column in columns"
                        :key="`top-editing-${column.id}`"
                        class="editor-cell hoverable"
                        @click="handleColumnSelection(column.id)"
                        :class="[
                            'editor-cell',
                            {
                                'selected-edit-bar':
                                    columnSelections[column.id] ||
                                    hoveredColumn === column.id,
                                'linked-edit-bar': linkedColumns[column.id]
                            }
                        ]"
                        @mouseenter="handleCellMouseEnter(null, column.id)"
                        @mouseleave="handleCellMouseLeave(null, column.id)"
                    >
                        <div class="absolute-column-container">
                            <column-properties
                                v-if="columnSelections[column.id]"
                                :column="column"
                                @delete:column="handleDeleteColumn"
                                @update:column="handleUpdateColumn"
                                :groups="groups"
                            >
                            </column-properties>
                            <div v-else-if="linkedColumns[column.id]">
                                <v-icon small>mdi-link</v-icon>
                            </div>
                            <add-button
                                v-else
                                @add="handleAddClick"
                                :beforeColumnId="column.id"
                            ></add-button>
                        </div>
                    </td>
                    <td class="editor-cell">
                        <div class="absolute-column-container">
                            <add-button @add="handleAddClick"></add-button>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="editor-cell"></td>
                    <th>
                        <b>
                            <title-edit
                                :title="table_title"
                                @update:title="title => updateTableTitle(title)"
                                placeholder="Table Name"
                            ></title-edit>
                        </b>
                        <resize-column-width
                            @resizeDragging="handleRowHeaderResize"
                            @resizeStopDragging="stopRowHeaderResize"
                        ></resize-column-width>
                    </th>
                    <th
                        :class="{
                            'selected-column':
                                columnSelections[column.id] ||
                                hoveredColumn === column.id,
                            'selected-linked-column-header':
                                linkedColumns[column.id]
                        }"
                        v-for="(column, index) in columns"
                        :key="index"
                        @mouseenter="handleCellMouseEnter(null, column.id)"
                        @mouseleave="handleCellMouseLeave(null, column.id)"
                        @click="handleColumnSelection(column.id)"
                    >
                        <title-edit
                            :key="`column-title-edit-${column.id}`"
                            :title="column.title"
                            @update:title="
                                title => updateColumnTitle(title, index)
                            "
                            placeholder="Column Name"
                            :isRequired="!column.optional"
                        ></title-edit>
                        <resize-column-width
                            @resizeDragging="(delta)=>resizeDragging(column.id, delta, index)"
                            @resizeStopDragging="stopResizeDragging"
                        ></resize-column-width>
                    </th>
                    <td class="editor-cell"></td>
                </tr>
            </thead>
            <transition-group
                name="row"
                tag="tbody"
                v-click-outside="onClickOutside"
            >
                <!-- Top Editing -->
                <tr v-for="(row, index) in rows" :key="`row-${row.id}`">
                    <td
                        @click="handleRowSelection(row.id)"
                        :class="[
                            'editor-cell hoverable',
                            {
                                'selected-edit-bar':
                                    rowsSelections[row.id] ||
                                    hoveredRow === row.id
                            }
                        ]"
                        @mouseenter="handleRowHeaderMouseEnter(row.id)"
                        @mouseleave="handleRowHeaderMouseLeave"
                    >
                        <div
                            class="absolute-row-container d-flex flex-column align-end"
                            :style="{ height: '100%' }"
                        >
                            <add-button
                                @add="handleAddClick"
                                :row="true"
                                :beforeRowId="row.id"
                            ></add-button>
                            <div
                                class="d-flex flex-grow-1 align-center"
                                v-if="rowsSelections[row.id]"
                            >
                                <v-btn
                                    icon
                                    small
                                    @click="handleDeleteRow(row.id)"
                                >
                                    <v-icon small>mdi-close</v-icon>
                                </v-btn>
                            </div>
                        </div>
                    </td>
                    <th
                        @mouseenter="handleRowHeaderMouseEnter(row.id)"
                        @mouseleave="handleRowHeaderMouseLeave(row.id)"
                        @click="handleRowSelection(row.id)"
                        :class="{
                            'selected-row':
                                rowsSelections[row.id] || hoveredRow === row.id
                        }"
                        :style="{'min-width': row_headers_width+'px !important'}"
                    >
                        <title-edit
                            :key="`row-title-edit-${row.id}`"
                            :title="row.title"
                            @update:title="
                                title => updateRowTitle(title, index)
                            "
                            placeholder="Row Name"
                            :isRequired="!question.is_optional"
                        ></title-edit>
                        <resize-column-width
                            @resizeDragging="handleRowHeaderResize"
                            @resizeStopDragging="stopRowHeaderResize"
                        ></resize-column-width>
                    </th>
                    <cell
                        v-for="(column, columnIndex) in columns"
                        :key="`cell-${row.id}-${column.id}`"
                        :column="column"
                        :columnIndex="columnIndex"
                        :row="row"
                        :selections="selections"
                        :columnSelections="columnSelections"
                        :linkedColumns="linkedColumns"
                        :rowsSelections="rowsSelections"
                        :hoveredColumn="hoveredColumn"
                        :hoveredRow="hoveredRow"
                        @click="handleCellClick"
                        @mouseenter="handleCellMouseEnter"
                        @mouseleave="handleCellMouseLeave"
                        @resizeDragging="resizeDragging"
                        @stopResizeDragging="stopResizeDragging"
                    >
                    </cell>
                    <td
                        :class="[
                            'editor-cell hoverable',
                            {
                                'selected-edit-bar':
                                    rowsSelections[row.id] ||
                                    hoveredRow === row.id
                            }
                        ]"
                        @click="handleRowSelection(row.id)"
                        @mouseenter="handleRowHeaderMouseEnter(row.id)"
                        @mouseleave="handleRowHeaderMouseLeave"
                    ></td>
                </tr>
                <tr key="editing-footer">
                    <!-- for empty corner -->
                    <td class="editor-cell">
                        <div class="absolute-row-container">
                            <add-button
                                @add="handleAddClick"
                                :row="true"
                            ></add-button>
                        </div>
                    </td>
                    <!-- for row titles -->
                    <td class="editor-cell"></td>
                    <td
                        v-for="column in columns"
                        :key="`bottom-editing-${column.id}`"
                        @click="handleColumnSelection(column.id)"
                        :class="[
                            'editor-cell hoverable',
                            {
                                'selected-edit-bar':
                                    columnSelections[column.id] ||
                                    hoveredColumn === column.id,
                                'linked-edit-bar': linkedColumns[column.id]
                            }
                        ]"
                        @mouseenter="handleCellMouseEnter(null, column.id)"
                        @mouseleave="handleCellMouseLeave"
                    ></td>
                    <td class="editor-cell"></td>
                </tr>
            </transition-group>
        </table>
    </div>
</template>

<script>
import AddButton from "./AddButton.vue";
import Cell from "./Cell.vue";
import ColumnProperties from "./ColumnProperties.vue";
import TitleEdit from "./TitleEdit.vue";
import { uuid } from "vue-uuid";
import ResizeColumnWidth from './ResizeColumnWidth.vue';

export default {
    name: "MatrixTable",
    components: { Cell, AddButton, TitleEdit, ColumnProperties, ResizeColumnWidth },
    inject: [
        "updateQuestion",
        "question"
    ],
    data() {
        return {
            columnSelections: {},
            hoveredColumn: null,
            hoveredRow: null,
            rowsSelections: {},
            selections: {}
        };
    },
    computed: {
        groups() {
            const groups = [];
            if(this.columnSelections){
                const selectedColumnsKeys = Object.keys(this.columnSelections);
                selectedColumnsKeys.forEach(key => {
                    const column = this.columns.find(
                        column => column.id === key
                    );
                    if (["checkbox", "radio"].includes(column.type)) {
                        this.columns.forEach(columnItem => {
                            if (columnItem.group_name && !groups.includes(columnItem.group_name) && column.type === columnItem.type) {
                                groups.push(columnItem.group_name);
                            }
                        });
                    }
                });
            }
            return groups;
        },
        columns: {
            get() {
                return this.question.properties && this.question.properties.columns || [];
            },
            set(value) {
                this.question.properties = {...this.question.properties, columns: value };
                this.updateQuestion();
            }
        },
        rows: {
            get() {
                return this.question.properties && this.question.properties.rows || [];
            },
            set(value) {
                this.question.properties = {...this.question.properties, rows: value };
                this.updateQuestion();
            }
        },
        table_title: {
            get() {
                return this.question.properties && this.question.properties.table_title || 'Matrix Table';
            },
            set(value) {
                this.question.properties = {...this.question.properties, table_title: value };
                this.updateQuestion();
            }
        },
        row_headers_width:{
            get(){
                return this.question.properties && this.question.properties.row_headers_width || 100;
            },
            set(value){
                this.question.properties = {...this.question.properties, row_headers_width: value };
                // DO NOT USE this.updateQuestion(); this will cause too many requests to server
            }
        },
        linkedColumns() {
            if (this.columnSelections) {
                const selectedColumnsKeys = Object.keys(this.columnSelections);

                const linkedColumns = {};
                for (const key of selectedColumnsKeys) {
                    const column = this.columns.find(
                        column => column.id === key
                    );
                    if (["checkbox", "radio"].includes(column.type)) {
                        this.columns.forEach(columnItem => {
                            if (column.id !== columnItem.id) {
                                if (column.group_name) {
                                    if (
                                        column.group_name ===
                                            columnItem.group_name &&
                                        column.type === columnItem.type
                                    ) {
                                        linkedColumns[columnItem.id] =
                                            columnItem.id;
                                    }
                                } else {
                                    if (
                                        !columnItem.group_name &&
                                        column.type === columnItem.type
                                    ) {
                                        linkedColumns[columnItem.id] =
                                            columnItem.id;
                                    }
                                }
                            }
                        });
                    }
                }
                return linkedColumns;
            }
            return {};
        }
    },
    methods: {
        onClickOutside() {
            this.selections = {};
        },
        handleAddClick(isRow, beforeId) {
            const id = uuid.v4().replace(/-/g, "");
            let newItem;
            if (isRow) {
                newItem = {
                    id: `row_${id}`,
                    title: `Row ${this.rows.length + 1}`
                };
                let newRows;
                if (!beforeId) {
                    newRows = [...this.rows, newItem];
                } else {
                    const index = this.rows.findIndex(row => row.id === beforeId);
                    newRows = [...this.rows];
                    newRows.splice(index, 0, newItem);
                }
                this.rows = newRows; // This should trigger the setter
            } else {
                newItem = {
                    id: `column_${id}`,
                    title: `Column ${this.columns.length + 1}`,
                    type: "radio",
                    properties: {},
                    optional: true
                };
                const columnWithSameType = this.columns.find(
                    columnItem =>
                        !columnItem.group_name &&
                        columnItem.type === "radio"
                );
                if (columnWithSameType) {
                    newItem.optional = columnWithSameType.optional;
                }
                let newColumns;
                if (!beforeId) {
                    newColumns = [...this.columns, newItem];
                } else {
                    const index = this.columns.findIndex(column => column.id === beforeId);
                    newColumns = [...this.columns];
                    newColumns.splice(index, 0, newItem);
                }
                this.columns = newColumns; // This should trigger the setter
            }
        },
        updateTableTitle(newTitle) {
            this.table_title = newTitle;
        },
        updateColumnTitle(newTitle, index) {
            const updatedColumns = [...this.columns];
            updatedColumns[index] = {...updatedColumns[index], title: newTitle};
            this.columns = updatedColumns; // This should trigger the setter
        },
        updateRowTitle(newTitle, index) {
            const updatedRows = [...this.rows];
            updatedRows[index] = {...updatedRows[index], title: newTitle};
            this.rows = updatedRows; // This should trigger the setter
        },
        handleColumnSelection(columnId) {
            this.rowsSelections = {};
            if (this.columnSelections[columnId]) {
                this.$delete(this.columnSelections, columnId);
            } else {
                // this.$set(this.columnSelections, columnId, true);
                this.columnSelections = {
                    [columnId]: true
                };
            }
        },
        handleRowSelection(rowId) {
            this.columnSelections = {};
            if (this.rowsSelections[rowId]) {
                this.$delete(this.rowsSelections, rowId);
            } else {
                this.rowsSelections = {
                    [rowId]: true
                };
            }
        },
        onClickOutsideOfTable() {
            this.columnSelections = {};
            this.rowsSelections = {};
        },
        shouldTriggerClickOutsideTable() {
            const el = document.querySelector(".block-table-outside");
            if (el) {
                return false;
            }
            return true;
        },

        handleDeleteColumn(columnId) {
            const index = this.columns.findIndex(
                column => column.id === columnId
            );
            this.$delete(this.columnSelections, columnId);
            this.$delete(this.linkedColumns, index);
            this.columns = this.columns.filter(
                column => column.id !== columnId
            );
        },
        handleDeleteRow(rowId) {
            this.$delete(this.rowsSelections, rowId);
            this.rows = this.rows.filter(row => row.id !== rowId);
        },
        // eslint-disable-next-line no-unused-vars
        handleCellClick(rowId, columnId) {
            this.handleColumnSelection(columnId);
        },
        // eslint-disable-next-line no-unused-vars
        handleCellMouseEnter(rowId, columnId) {
            this.hoveredColumn = columnId;
        },
        handleCellMouseLeave() {
            this.hoveredColumn = null;
        },
        handleRowHeaderMouseEnter(rowId) {
            this.hoveredRow = rowId;
        },
        handleRowHeaderMouseLeave() {
            this.hoveredRow = null;
        },
        handleUpdateColumn(column) {

            const index = this.columns.findIndex(c => c.id === column.id);

            // check if optional is changed for group
            if (["checkbox", "radio"].includes(column.type) && this.columns[index].optional !== column.optional) {
                this.columns.forEach((columnItem, i) => {
                    if (column.group_name) {
                        if (
                            column.group_name === columnItem.group_name &&
                            column.type === columnItem.type
                        ) {
                            this.$set(
                                this.columns[i],
                                "optional",
                                column.optional
                            );
                        }
                    } else {
                        if (
                            !columnItem.group_name &&
                            column.type === columnItem.type
                        ) {
                            this.$set(
                                this.columns[i],
                                "optional",
                                column.optional
                            );
                        }
                    }
                });
            }

            // check if type is changed for the column
            if (this.columns[index].type !== column.type) {
                column.group_name = null;
                column.properties = {};
                if (["checkbox", "radio"].includes(column.type)) {
                    const columnWithSameType = this.columns.find(
                        columnItem =>
                            !columnItem.group_name &&
                            columnItem.type === column.type &&
                            columnItem.id !== column.id
                    );
                    if (columnWithSameType) {
                        column.optional = columnWithSameType.optional;
                    }
                }
            }

            // check if group name is changed for the column
            if(["checkbox", "radio"].includes(column.type) && this.columns[index].group_name !== column.group_name) {
                const columnWithSameType = this.columns.find(
                    columnItem =>
                        columnItem.group_name === column.group_name &&
                        columnItem.type === column.type &&
                        columnItem.id !== column.id
                );
                if (columnWithSameType) {
                    column.optional = columnWithSameType.optional;
                }
            }

            this.$set(this.columns, index, column);
            this.columns = [...this.columns]; // This should trigger the setter
        },
        resizeDragging(columnId, delta, columnIndex) {
            if(delta === 0) return;
            let column = null;
            if(this.columns[columnIndex] && this.columns[columnIndex].id === columnId){
                column = this.columns[columnIndex];
            } else {
                const columnIndex = this.columns.findIndex(
                    column => column.id === columnId
                );
                column = this.columns[columnIndex];
            }
            const newWidth = (column.width || 100) + delta;

            if (newWidth > 100) {
                this.$set(this.columns[columnIndex], "width", newWidth);
            }
        },
        stopResizeDragging() {
            this.columns = [...this.columns]; // This should trigger the setter
        },
        handleRowHeaderResize(delta) {
            if(delta === 0) return;
            const newWidth = this.row_headers_width + delta;
            if (newWidth > 100) {
                this.row_headers_width = newWidth;
            }
        },
        stopRowHeaderResize() {
            this.updateQuestion();
        }
    }
};
</script>

<style lang="scss" scoped>
.editor-cell {
    padding: 5px !important;
    background-color: var(--v-grey-lighten2);
    position: relative;
    min-width: unset !important;

    &.selected-edit-bar {
        background-color: var(--v-primary-base);
        border: 1px solid var(--v-primary-base) !important;
    }
    &.selected-edit-bar:after {
        content: "";
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        border: 1px solid var(--v-primary-base);
    }

    &.linked-edit-bar {
        background-color: var(--v-deepPurple-lighten4);
        border: 1px solid var(--v-deepPurple-lighten4) !important;
    }

    &.linked-edit-bar:after {
        content: "";
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        border: 1px solid var(--v-deepPurple-lighten4);
    }

    &.hoverable:hover {
        cursor: pointer;
        background-color: var(--v-primary-lighten1);
    }
}

.selected-column:after {
    content: "";
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    border-left: 1px solid var(--v-primary-base);
    border-right: 1px solid var(--v-primary-base);
    background-color: rgba(0, 102, 255, 0.1);
    pointer-events: none;
}
.selected-linked-column-header:after {
    content: "";
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    border-left: 1px solid var(--v-deepPurple-base) !important;
    border-right: 1px solid var(--v-deepPurple-base) !important;
    background: repeating-linear-gradient(
        -45deg,
        rgba(187, 0, 255, 0.1),
        rgba(187, 102, 255, 0.1) 10px,
        rgba(187, 102, 255, 0.2) 10px,
        rgba(187, 102, 255, 0.2) 20px
    ) !important;
    pointer-events: none;
}
.selected-row:after {
    content: "";
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    border-top: 1px solid var(--v-primary-base);
    border-bottom: 1px solid var(--v-primary-base);
    background-color: rgba(0, 102, 255, 0.1);
    pointer-events: none;
}
.linked-column:after {
    content: "";
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    border-left: 1px solid var(--v-deepPurple-base) !important;
    border-right: 1px solid var(--v-deepPurple-base) !important;
    background: rgba(187, 0, 255, 0.1);
}
.absolute-column-container {
    position: absolute;
    bottom: 100%;
    left: 0px;
}
.absolute-row-container {
    position: absolute;
    right: 100%;
    top: 0px;
}

.matrix-table {
    /* width: 100%; */
    border-collapse: collapse;
    background-color: white;
    color: black;
}

.matrix-table th,
.matrix-table td {
    border: 1px solid var(--v-grey-lighten3);
    padding: 8px;
    text-align: left;
    min-width: 100px;
    word-break: break-word;
    position: relative;
}

.matrix-table th {
    background-color: var(--v-grey-lighten4);
    font-weight: 500;
    font-size: 14px;
}
.row-move {
    transition: transform 0.2s ease, opacity 0.2s ease;
}

.row-enter-active,
.row-leave-active {
    transition: transform 0.2s ease, opacity 0.2s ease;
}

.row-enter,
.row-leave-to {
    opacity: 0;
    transform: translateY(30px);
}

.column-enter-active,
.column-leave-active {
    transition: width 0.2s ease, opacity 0.2s ease;
}
.column-enter,
.column-leave-to {
    opacity: 0;
    width: 0;
    overflow: hidden;
}
</style>
