<template>
    <div>
        <v-text-field @change="updateQuestion" v-model="fieldPlaceholder" hide-details="auto" outlined dense class="mb-2 text-caption" placeholder="Field Placeholder"></v-text-field>
    </div>
</template>

<script>

export default {
    name: "TextTypeProperties",
    props: {
        value: {
            required: true,
        },
    },
    data(){
        return {
            properties : {
                field_placeholder: "",
                ...this.value
            }
        }
    },
    methods: {
        updateQuestion() {
            this.$emit('change', this.properties);
        },
    },
    computed: {
        fieldPlaceholder: {
            get() {
                return this.properties.field_placeholder || "";
            },
            set(value) {
                this.properties = {...this.properties, field_placeholder: value };
                this.$emit('input', this.properties);
            }
        },
    },
}
</script>