<template>
    <question-text
        :question="question"
        :errors="errors"
        @change="updateQuestion"
        @focus="clearTextInput"
        style="width: 100%;"
    ></question-text>
</template>

<script>
import QuestionText from "../QuestionText.vue"

export default {
    name: "PhoneNumberType",
    props: ["question", "errors"],
    components: { QuestionText },
    inject: [
        "deleteChoiceFromQuestion",
        "addChoiceToQuestion",
        "updateQuestion",
    ],
    methods: {
        clearTextInput() {
            if(this.question.text === "Type your question here") {
                this.question.text = "";
            }
        }
    }
};
</script>