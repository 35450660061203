<template>
    <div ref="root" class="root mt-4">
      <v-img @click="overlay=true" :max-width="minWidth" v-if="attachments && attachments.length === 1" :src="attachments[0].url"></v-img>
      <div class="images-container" v-if=" attachments && attachments.length>1" >
        <div class="attachment-wrapper" v-for="(attachment, i) in attachments" :key="`attachment-${i}`">
          <v-badge
            :color="survey.style.button.background"
            :content="i+1"
            overlay
            bordered
            offset-x="10"
            offset-y="10"
          >
            <template v-slot:badge>
              <span :style="{color: survey.style? survey.style.button.color: '#FFFFFF'}">{{i+1}}</span>
            </template>
            <v-img
              rounded
              class="image-thumbnail"
              height="120px"
              width="120px"
              :src="attachment.url"
              @click="handleAttachmentsClick(i)"
            ></v-img>
          </v-badge>
        </div>
      </div>
      <v-overlay
            :absolute="$root.isPreview"
            :opacity="0.8"
            :value="overlay"
            class="overlay"
          >
        <div class="close-overlay-container">
          <v-btn @click="overlay=false">
            Close
          </v-btn>
        </div>
        <v-carousel :show-arrows="false" ref="overlay" touch v-model="model">
          <v-carousel-item
            v-for="(attachment, i) in attachments"
            :key="`attachment-${i}`"
          >
            <v-sheet
              height="100%"
              :min-width="minWidth"
              tile
            >
              <v-row
                class="fill-height"
                align="center"
                justify="center"
              >
              <v-img :width="minWidth" maxHeight="500px" contain :src="attachment.url"></v-img>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-overlay>
    </div>
  </template>
  <script>
  export default {
    name: 'MediaAttachment',
    props: ['attachments', 'survey'],
    data: () => ({
        absolute: true,
        opacity: 1,
        overlay: false,
        model: 0,
        minWidth: 300,
        
    }),
    mounted(){
      if(this.$refs.root && this.$refs.root.clientWidth){
        this.minWidth=this.$refs.root.clientWidth
      }
    }, 
    methods: {
      handleAttachmentsClick(i){
        this.model=i
        this.overlay=true;
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .root{
    display: flex;
    // justify-content: center;
  }
  .attachment-wrapper{
    max-height: 120px;
    flex-grow: 0;
    margin: 12px 16px;
  }
  .attachments-container{
    flex-grow: 0;
    display: flex;
    padding: 8px 12px;
    background-color: rgba($color: #000000, $alpha: 0.3);
  }
  .images-container {
    display: flex;
    flex-wrap: wrap;
  }
  .image-thumbnail {
    border-radius: 4px;
    flex-grow: 0
  }
  .right-side-control{
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .overlay{
    width: 100%;
  }
  .close-overlay-container{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
  }
  </style>
  