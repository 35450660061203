<template>
    <v-text-field
        label="Name"
        v-model="answer"
        :dark="styles.dark"
        :color="survey.style.color"
        @change="$emit('change')"
        outlined
        :rules="[rules.name]"
        validate-on-blur
        autocomplete="name"
        clearable
    ></v-text-field>
</template>

<script>
const pattern =/^(?=.*\s)[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
export default {
    name: "Name",
    props: {
        value: {
            required: true,
        },
        survey: {
            required: true,
        },
        styles: {
            required: true,
        },
    },
    data() {
        return {
            rules: {
                name: this.validateName,
            },
        };
    },
    computed: {
        answer: {
            get: function () {
                return this.value ? this.value.value : "";
            },
            set: function (val) {
                if(val && val.trim()){
                    this.$emit("input", { answer: { type: "name", value: val }, valid: this.validateName(val)});
                }else{
                    this.$emit("input", null);
                }
            },
        },
    },
    methods: {
        validateName(value){
            if(this.survey && this.survey.inbounds){
                return !value || pattern.test(value) || "Specify first and last name.";
            }
            return true
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
