<template>
    <div v-if="survey">
        <div class="text-subtitle-1 mt-4">General Colors</div>
        <div class="d-flex align-center mt-4">
            <div class="text-body-1 mb-2">Background</div>
            <v-spacer></v-spacer>
            <color-picker v-model="survey.style.background" @change="updateSurvey"></color-picker>
        </div>

        <div class="d-flex align-center mt-4">
            <div class="text-body-1 mb-2">Text</div>
            <v-spacer></v-spacer>
            <color-picker v-model="survey.style.color" @change="updateSurvey"></color-picker>
        </div>

        <div class="text-subtitle-1 mt-4">Buttons & Badges Colors</div>

        <div class="d-flex align-center mt-4">
            <div class="text-body-1 mb-2" >Background</div>
            <v-spacer></v-spacer>
            <color-picker v-model="survey.style.button.background" @change="updateSurvey"></color-picker>
        </div>

        <div class="d-flex align-center mt-4">
            <div class="text-body-1 mb-2" > Text</div>
            <v-spacer></v-spacer>
            <color-picker v-model="survey.style.button.color" @change="updateSurvey"></color-picker>
        </div>
        <v-divider class="my-5 mx-n6"></v-divider>
        <div>
            <div v-if="survey.top_left_file_url">
                <div class="d-flex align-center">
                    <div class="text-subtitle-1 mb-2">Logo</div>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="removeLogo">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </div>
                <v-card class="pa-3 justify" elevation="0" color="grey lighten-4">
                    <v-img max-height="40"  :src="survey.top_left_file_url" contain></v-img>
                </v-card>
            </div>
            <v-file-input
                v-else
                accept="image/png, image/jpeg, image/bmp, image/gif"
                placeholder="Pick a logo"
                prepend-icon="mdi-image-outline"
                label="Logo"
                :disabled="!showLogoUpload"
                outlined
                @change="handleAddLogo"
                dense
            ></v-file-input>
        </div>
    </div>
</template>

<script>
import ContainerCard from "@/components/dashboard/ContainerCard.vue";
import { mapGetters, mapActions } from "vuex";
import PaperClip from "@/components/icons/PaperClip.vue";
import ColorPicker from './ColorPicker.vue';
import BuilderApi from '@/api/BuilderApi';
import { uploadLogo } from '@/api/survey';

export default {
    name: "Customize",
    components: {
        ContainerCard,
        PaperClip,
        ColorPicker,
    },
    data(){
        return {
            backgroundMenu: false
        }
    },
    inject: ['survey', 'refreshSurvey'],
    computed: {
        ...mapGetters({
            features: 'auth/features'
        }),
        showLogoUpload(){
            return !!this.features && this.features.upload_logo
        },
    },
    methods: {
        ...mapActions({
            setLogo: "survey/setLogo",
            setSurvey: "survey/setSurvey",
        }),
        async updateSurvey(){
            await BuilderApi.patchForm(this.survey.id, {style: this.survey.style})
            await this.refreshSurvey()
        },
        async handleAddLogo(file){
            try {
                if(file){
                    const res = await uploadLogo(file, this.survey.hash)
                    if(res && res.data){
                        await BuilderApi.patchForm(this.survey.id, {
                            top_left_file_path: res.data.path,
                            top_left_file_url: res.data.url
                        })
                        await this.refreshSurvey()
                    }
                }
            } catch (error) {
                console.log('error in handleAddLogo', error);
            }
        },
        async removeLogo(){
            try {
                await BuilderApi.patchForm(this.survey.id, {
                    top_left_file_path: null,
                    top_left_file_url: null
                })
                await this.refreshSurvey()
            } catch (error) {
                console.log('error in removeLogo', error);
            }
        },
    },
};
</script>

