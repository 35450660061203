<template>
    <v-card
        elevation="2"
        color="grey lighten-5"
        :class="[
            'column-properties-container d-flex pa-1',
            { 'block-table-outside': blockTableOutside }
        ]"
        @click.stop
    >
        <v-select
            ref="type-select"
            @change="handleTypeSelectChange"
            @focus="typeSelectorFocused = true"
            @blur="typeSelectorFocused = false"
            :menu-props="{ class: 'block-table-outside' }"
            :items="types"
            v-model="type"
            class="mr-1 text-body-2"
            dense
            hide-details="auto"
            outlined
        >
            <template v-slot:selection="data">
                <div class="text-caption trimmed-option">
                    {{ data.item.text }}
                </div>
            </template>
            <template v-slot:item="data">
                <div class="text-caption trimmed-option">
                    {{ data.item.text }}
                </div>
            </template>
        </v-select>
        <template v-if="deletionConfirmation">
            <v-btn
                @click="deletionConfirmation = false"
                class="pa-0 mr-1"
                elevation="0"
                width="40"
                height="40"
                min-width="40"
                small
                ><v-icon>mdi-cancel</v-icon></v-btn
            >
            <v-btn
                @click="$emit('delete:column', column.id)"
                class="pa-0"
                elevation="0"
                width="40"
                height="40"
                min-width="40"
                small
                ><v-icon color="primary">mdi-check</v-icon></v-btn
            >
        </template>
        <template v-else>
            <!-- <v-btn class="pa-0 mr-1" elevation="0" width="40" height="40" min-width="40" small><v-icon small>mdi-tune</v-icon></v-btn> -->
            <v-menu
                v-model="showPropertiesMenu"
                offset-y
                style="max-width: 600px"
                left
                :close-on-content-click="false"
                :nudge-right="40"
                :nudge-bottom="6"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="pa-0 mr-1"
                        elevation="0"
                        width="40"
                        height="40"
                        min-width="40"
                        small
                        ><v-icon small>mdi-tune</v-icon></v-btn
                    >
                </template>
                <v-card width="300px" class="pa-3 menu-card">
                    <template v-if="showGroup">
                        <div class="text-caption mb-2">
                            <v-icon small class="mr-1">mdi-link</v-icon>
                            <b
                                >{{
                                    type === "checkbox" ? "Checkbox" : "Radio"
                                }}
                                Group</b
                            >
                        </div>

                        <v-autocomplete
                            :items="groupNameSuggestions"
                            @change="updateColumn"
                            :rules="[variableRule]"
                            prefix="Group name"
                            :placeholder="`grouped by default_${type}`"
                            height="30"
                            class="text-caption mb-2 variable-input"
                            outlined
                            dense
                            hide-details="auto"
                            v-model="group_name"
                            @update:search-input="handleSearchInput"
                            clearable
                        >
                            <template v-slot:prepend-item>
                                <!-- Create item list item if it doesn't exist -->
                                <v-list-item
                                    v-if="showCreateGroupItem"
                                    ripple
                                    @click="handleCreateGroup"
                                >
                                    <v-list-item-content>
                                        <v-list-item-title
                                            class="text-body-2"
                                        >Create: {{formattedSearchInput}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                            <template v-slot:no-data>
                                <div class="text-caption px-4 pa-2">
                                    No matching options.
                                </div>
                            </template>
                        </v-autocomplete>

                        <div class="d-flex align-center mb-2">
                            <div class="text-caption">Optional</div>
                            <v-switch
                                class="ml-2 mt-0 pt-0"
                                @change="updateColumn"
                                hide-details="auto"
                                v-model="optional"
                                dense
                                small
                            ></v-switch>
                        </div>
                        <v-divider class="my-2"></v-divider>
                    </template>
                    <!-- Common Properties -->
                    <template v-else>
                        <div class="text-caption mb-2">
                            <b>Column Properties</b>
                        </div>
                        <div class="d-flex align-center mb-2">
                            <div class="text-caption">Optional</div>
                            <v-switch
                                class="ml-2 mt-0 pt-0"
                                @change="updateColumn"
                                hide-details="auto"
                                v-model="optional"
                                dense
                                small
                            ></v-switch>
                        </div>
                    </template>
                    <!-- Numeric properties -->
                    <template v-if="type === 'numeric'">
                        <numeric-type-properties
                            @change="updateColumn"
                            v-model="properties"
                        ></numeric-type-properties>
                    </template>
                    <template v-if="type === 'text'">
                        <text-type-properties
                            @change="updateColumn"
                            v-model="properties"
                        ></text-type-properties>
                    </template>
                    <!-- Dropdown properties -->
                    <template v-if="type === 'dropdown'">
                        <dropdown-type-properties
                            @change="updateColumn"
                            v-model="properties"
                        ></dropdown-type-properties>
                    </template>
                </v-card>
            </v-menu>
            <v-btn
                @click="deletionConfirmation = true"
                class="pa-0"
                elevation="0"
                width="40"
                height="40"
                min-width="40"
                small
                ><v-icon small>mdi-delete</v-icon></v-btn
            >
        </template>
    </v-card>
</template>

<script>
import DropdownTypeProperties from "./DropdownTypeProperties.vue";
import NumericTypeProperties from "./NumericTypeProperties.vue";
import TextTypeProperties from "./TextTypeProperties.vue";

export default {
    name: "ColumnProperties",
    components: {
        NumericTypeProperties,
        TextTypeProperties,
        DropdownTypeProperties
    },
    data() {
        return {
            types: [
                { text: "Checkbox", value: "checkbox" },
                { text: "Radio", value: "radio" },
                { text: "Text", value: "text" },
                { text: "Numeric", value: "numeric" },
                { text: "Dropdown", value: "dropdown" }
            ],
            typeSelectorFocused: false,
            deletionConfirmation: false,
            showPropertiesMenu: false,
            columnProperties: Object.assign({}, this.column),
            variableRule: v => {
                if (!v) return true;
                return /^[a-zA-Z_]\w*$/.test(v) || "Invalid group name";
            },
            searchInput: ""
        };
    },
    props: {
        column: {
            required: true
        },
        groups: {
            default: []
        }
    },
    computed: {
        showCreateGroupItem() {
            if(this.searchInput){
                return (
                    this.groupNameSuggestions.filter(
                        item => item.text === this.searchInput
                    ).length === 0
                );
            }

            return false;
        },
        showGroup() {
            return this.type === "checkbox" || this.type === "radio";
        },
        type: {
            get() {
                return this.columnProperties.type;
            },
            set(value) {
                this.$set(this.columnProperties, "type", value);
            }
        },
        optional: {
            get() {
                return this.columnProperties.optional;
            },
            set(value) {
                this.$set(this.columnProperties, "optional", value);
            }
        },
        group_name: {
            get() {
                return this.columnProperties.group_name
            },
            set(value) {
                this.$set(this.columnProperties, "group_name", value);
            }
        },
        properties: {
            get() {
                return this.columnProperties.properties;
            },
            set(value) {
                this.$set(this.columnProperties, "properties", value);
            }
        },
        blockTableOutside() {
            return this.typeSelectorFocused || this.showPropertiesMenu;
        },
        groupNameSuggestions() {
            return this.groups.map(group => ({
                text: group.replace(`${this.type}_`, ""),
                value: group
            }));
        },

        formattedSearchInput() {
            if (!this.searchInput) return "";
            return this.searchInput
                .replaceAll(" ", "_")
                .replaceAll(/[^\w]/g, "")
                .toLowerCase().trim();
        }
    },
    methods: {
        handleTypeSelectChange() {
            this.$refs["type-select"]?.blur();
            this.updateColumn();
        },
        updateColumn() {
            this.$emit("update:column", this.columnProperties);
        },
        handleSearchInput(value) {
            this.searchInput = value;
        },
        handleCreateGroup() {
            if(this.formattedSearchInput){
                const group_name = `${this.type}_${this.formattedSearchInput}`;
                this.$set(this.columnProperties, "group_name", group_name);
                this.updateColumn();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.column-properties-container {
    position: absolute;
    top: -12px;
    left: -4px;
    z-index: 10;
    min-width: 230px;
}
.menu-card {
    width: 300px;
    max-height: 400px;
    overflow-y: auto;
}
.variable-input {
    font-family: "Roboto Mono", monospace;
}
</style>