<template>
    <div class="d-flex flex-column ma-4">
        <div class="text-subtitle-1 mb-3">
            Audio Attachment
        </div>
        <div>
            <div class="d-flex align-center">
                <audio style="width: 262px;" class="my-4" :controls="question.audio_url && !recording" :src="question.audio_url" ref="player"></audio>
                <v-btn color="grey" class="ml-4" v-if="question.audio_url && !recording" icon @click="removeQuestion">
                    <v-icon medium>mdi-delete</v-icon>
                </v-btn>
            </div>
            <v-checkbox class="pa-0 ma-0" hide-details="auto" v-if="question.audio_url && !recording" v-model="playOnPageOpen" label="Play audio on page load"></v-checkbox>
            <div class="mb-6 upload-progress" v-if="uploading" style="width: 322px;">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
            </div>
            <av-media
                v-if="recording"
                ref-link="player"
                type="frequ"
                :media="stream"
                line-color="#0066FF"
                canv-class="audio"
                :canv-height="50"
                :canv-width="322"
                :line-width="1.5"
                :radius="10"
                class="mt-2"
            />
        </div>
        <div v-if="!question.audio_url" class="d-flex flex-column">
            <v-btn @click="recording = !recording" elevation="0" medium>
                <v-icon
                    left
                    dark
                >
                    {{recording ? 'mdi-stop': 'mdi-microphone' }}
                </v-icon>
                 {{ recording ? "Stop" : "Record Audio" }}
            </v-btn>
            <v-btn :loading="isSelecting" @click="handleUploadAudioClick" class="mt-4" elevation="0" medium>
                <v-icon
                    left
                    dark
                >
                    mdi-upload
                </v-icon>
                 Upload Audio
            </v-btn>
            <input
                ref="uploader"
                hidden
                type="file"
                accept="audio/mp3,audio/*;capture=microphone"
                @change="uploadAudioFile($event)"
            />
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { uploadAudio } from '../../../../../../api/survey';
import { mapMutations } from 'vuex';

export default {
    name: "VoiceQuestionType",
    inject: ["updateQuestion", "question", 'survey'],
    data() {
        return {
            stream: null,
            recorder: null,
            recording: false,
            audio: null,
            paused: true,
            isSelecting: false,
            uploading: false
        };
    },
    watch: {
        recording(value) {
            if (value) {
                this.startRecord();
            } else if (this.stream) {
                const tracks = this.stream.getAudioTracks();
                tracks[0].stop();
                this.stream = null;
                this.recorder.state === 'recording' && this.recorder.stop();
            }
        },
    },
    mounted() {
        this.audio = this.$refs.player;
    },
    computed: {
        playOnPageOpen: {
            get() {
                return this.question.properties.audio_attachment_play_on_mount || false;
            },
            set(value) {
                this.question.properties = {
                    ...this.question.properties,
                    audio_attachment_play_on_mount: value
                }
                this.updateQuestion();
            }
        }
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        async startRecord() {
            this.recorded = false;
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
                this.stream = stream;
                this.audio.srcObject = stream;

                this.recorder = new MediaRecorder(stream);
                this.recorder.addEventListener("dataavailable",
                    async (e) => {
                        this.audio.srcObject = null;
                        let { data } = await this.uploadRecording(e.data);
                        this.question.audio_url = data.url;
                        this.updateQuestion();
                    }
                );

                this.recorder.start();

            } catch (error) {
                this.recording = false;
                this.showSnackBar({
                    text: 'Microphone not available (Permission denied)', 
                    color: 'error', 
                    timeout: 3000,
                    right: true,
                    top: true,
                })
                console.error(error);
            }
        },
        handleUploadAudioClick() {
            this.isSelecting = true
            window.addEventListener('focus', () => {
                this.isSelecting = false
            }, { once: true })

            this.$refs.uploader.click()
        },
        async uploadRecording(data) {
            try {
                const formParams = new FormData();
                formParams.append("audioBlobs[]", data);
                formParams.append('mimeType', this.recorder.mimeType)
                formParams.append('survey_hash', this.survey.hash)
                formParams.append('directory', 'media');
                this.uploading=true;
                const res = await uploadAudio(formParams)
                this.uploading=false;
                return res
            } catch (error) {
                console.error('uploadRecording', error);
                this.uploading=false;
            }
            
        },
        async uploadFile(data){
            let formParams = new FormData();
            formParams.append("file", data);
            formParams.append("survey_hash", this.survey.hash);
            let res = await axios.post(
                `${process.env.VUE_APP_APP_URL}/api/upload`,
                formParams
            );

            return res;
        },
        stopPlay() {
            if(!this.audio.paused) {
                this.audio.pause();
                this.paused = true;
            }
        },
        async uploadAudioFile(e) {
            try {
                this.isSelecting=false
                this.uploading = true
                let { data } = await this.uploadFile(e.target.files[0]);
                this.uploading = false
                this.question.audio_url = data.url;
                this.updateQuestion();
            } catch (error) {
                console.error('uploadAudioFile', error);
                this.uploading= false
            }
        },

        removeQuestion(){
            this.stopPlay();
            this.question.audio_url = null;
            this.updateQuestion();
        }
    },
};
</script>

<style lang="scss" scoped>
.upload-progress{
    width: 300px;
}
</style>
