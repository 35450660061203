<template>
    <div>
        <question-text
            :question="question"
            :errors="errors"
            @change="updateQuestion"
            @focus="clearTextInput"
            style="width: 100%;"
        ></question-text>
        <v-divider class="my-3"></v-divider>
        <div>
            <div class="text-caption grey--text text--darken-1 mb-2">Allowed date range</div>
            <v-select
                :items="rangeOptions"
                v-model="rangeOption"
                item-text="text"
                item-value="value"
                dense
                outlined
                hide-details="auto"
                @change="updateQuestion"
                class="text-body-2 mb-2"
            >
            </v-select>
            <div v-if="rangeOption" class="d-flex align-center">
                <div v-if="rangeOption!=='from_now'" class="d-flex flex-grow-1">
                    <v-menu
                        v-model="minDateMenu"
                        :close-on-content-click="false"
                        max-width="290"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            :value="formattedMinDate"
                            clearable
                            :suffix="rangeOption==='until_now'? 'to present': ''"
                            :placeholder="rangeOption==='until_now'?'From any date':'Start date'"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            @click:clear="handleClearMinDate"
                            outlined
                            dense
                            hide-details="auto"
                            class="text-body-2 suffix"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="minDateProperty"
                            :max="rangeOption==='until_now'?maxDateProperty: null"
                            @change="handleMinDateChange"
                        ></v-date-picker>
                    </v-menu>
                </div>
                <div v-if="rangeOption!=='until_now'" class="d-flex flex-grow-1">
                    <v-menu
                        v-model="maxDateMenu"
                        :close-on-content-click="false"
                        max-width="290"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            :value="formattedMaxDate"
                            clearable
                            :prefix="rangeOption==='from_now'? 'From present to': ''"
                            :placeholder="rangeOption==='from_now'?'any date':'End date'"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            @click:clear="handleClearMaxDate"
                            outlined
                            dense
                            hide-details="auto"
                            :class="['text-body-2 prefix', {'ml-2': rangeOption!=='from_now'}]"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="maxDateProperty"
                            :min="rangeOption==='from_now'?minDateProperty: null"
                            @change="handleMaxDateChange"
                        ></v-date-picker>
                    </v-menu>
                </div>
            </div>
            <div class="text-caption grey--text text--darken-1 mb-2 mt-2">Starting picker state</div>
            <v-select 
                :items="startingActiveState"
                v-model="startingActiveStateProperty"
                item-text="text"
                item-value="value"
                dense
                outlined
                hide-details="auto"
                @change="updateQuestion"
                class="text-body-2 mb-2"
            >
            </v-select>
            <div class="d-flex align-center mb-2 mt-2">
                <v-checkbox
                    v-model="inputLookProperty"
                    hide-details
                    @change="updateQuestion"
                    small
                    class="ma-0 pa-0"
                ></v-checkbox>
                <div class="text-caption grey--text text--darken-1">Text input look</div>
            </div>
        </div>
    </div>
</template>

<script>
import QuestionText from "../QuestionText.vue"

export default {
    name: "DatePickerType",
    props: ["question", "errors"],
    components: { QuestionText },
    inject: [
        "deleteChoiceFromQuestion",
        "addChoiceToQuestion",
        "updateQuestion",
    ],
    data() {
        return {
            rangeOptions: [
                { text: "Any Date", value: null },
                { text: "Until now", value: "until_now" },
                { text: "From now", value: "from_now" },
                { text: "Custom date range", value: "custom" },
            ],
            startingActiveState: [
                {text: "Date", value: null},
                {text: "Month", value: 'MONTH'},
                {text: "Year", value: 'YEAR'},
            ],
            minDateMenu: false,
            maxDateMenu: false,
        }
    },
    computed: {
        rangeOption: {
            get() {
                return this.question?.properties?.range_option || null;
            },
            set(value) {
                if (value === "until_now") {
                    this.minDateProperty = null;
                    this.maxDateProperty = new Date().toISOString().substr(0, 10);
                } else if (value === "from_now") {
                    this.minDateProperty = new Date().toISOString().substr(0, 10);
                    this.maxDateProperty = null;
                } else {
                    this.minDateProperty = null;
                    this.maxDateProperty = null;
                }

                this.question.properties = {
                    ...this.question.properties,
                    range_option: value,
                };
            }
        },
        minDateProperty: {
            get() {
                return this.question.properties.min_date;
            },
            set(value) {
                // check for max date and change it if it is less than min date
                if (this.maxDateProperty && value > this.maxDateProperty) {
                    this.maxDateProperty = value;
                }

                this.question.properties = {
                    ...this.question.properties,
                    min_date: value,
                };
            }
        },
        maxDateProperty: {
            get() {
                return this.question.properties.max_date;
            },
            set(value) {
                
                if (this.minDateProperty && value < this.minDateProperty) {
                    this.minDateProperty = value;
                }
                this.question.properties = {
                    ...this.question.properties,
                    max_date: value,
                };
            }
        },
        startingActiveStateProperty: {
            get() {
                return this.question.properties.starting_active_state || null;
            },
            set(value) {
                this.question.properties = {
                    ...this.question.properties,
                    starting_active_state: value,
                };
            }
        },
        inputLookProperty: {
            get() {
                return this.question.properties.text_input_look || false;
            },
            set(value) {
                this.question.properties = {
                    ...this.question.properties,
                    text_input_look: value,
                };
            }
        },
        formattedMinDate() {
            return this.minDateProperty? this.$date(this.minDateProperty).format('MMM D, YYYY')  : '';
        },
        formattedMaxDate() {
            return this.maxDateProperty? this.$date(this.maxDateProperty).format('MMM D, YYYY')  : '';
        },
    },
    methods: {
        clearTextInput() {
            if(this.question.text === "Type your question here") {
                this.question.text = "";
            }
        },
        handleClearMinDate() {
            this.minDateProperty = null;
            this.updateQuestion();
        },
        handleMinDateChange() {
            this.minDateMenu = false;
            this.updateQuestion();
        },
        handleClearMaxDate() {
            this.maxDateProperty = null;
            this.updateQuestion();
        },
        handleMaxDateChange() {
            this.maxDateMenu = false;
            this.updateQuestion();
        },
    }
};
</script>

<style lang="scss" scoped>
    .prefix::v-deep .v-text-field__prefix{
        color: var(--v-grey-base);
        padding-right: 16px;
    }
    .suffix::v-deep .v-text-field__suffix{
        color: var(--v-grey-base);
        padding-right: 16px;
    }
</style>