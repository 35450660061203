<template>
    <div>
        <question-text
            :question="question"
            :errors="errors"
            @change="updateQuestion"
            @focus="clearTextInput"
            style="width: 100%;"
        ></question-text>
        <div class="mb-3"></div>
        <v-text-field
            v-model="yesLabel"
            placeholder="Type custom label for yes"
            outlined
            dense
            clearable
            @change="updateQuestion"
            hide-details="auto"
            class="text-caption mb-2"
        >
        </v-text-field>
        <v-text-field
            class="text-caption mb-2"
            v-model="noLabel"
            placeholder="Type custom label for no"
            outlined
            dense
            clearable
            @change="updateQuestion"
            hide-details="auto"
        >
        </v-text-field>
    </div>
</template>

<script>
import QuestionText from "../QuestionText.vue"

export default {
    name: "YesnoType",
    props: ["question", "errors"],
    components: { QuestionText },
    inject: [
        "deleteChoiceFromQuestion",
        "addChoiceToQuestion",
        "updateQuestion",
    ],
    methods: {
        clearTextInput() {
            if(this.question.text === "Type your question here") {
                this.question.text = "";
            }
        }
    },
    computed: {
        yesLabel: {
            get() {
                return this.question.properties && this.question.properties.yes_label || "";
            },
            set(value) {
                if(!value || value.trim() === ""){
                   return this.question.properties = {...this.question.properties, yes_label: null };
                }
                this.question.properties = {...this.question.properties, yes_label: value };
            }
        },
        noLabel: {
            get() {
                return this.question.properties && this.question.properties.no_label || "";
            },
            set(value) {
                if(!value || value.trim() === ""){
                   return this.question.properties = {...this.question.properties, no_label: null };
                }

                this.question.properties = {...this.question.properties, no_label: value };
            }
        },
    },
};
</script>
