var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
            handler: _vm.onClickOutsideOfTable,
            closeConditional: _vm.shouldTriggerClickOutsideTable
        }),expression:"{\n            handler: onClickOutsideOfTable,\n            closeConditional: shouldTriggerClickOutsideTable\n        }"}],staticClass:"matrix-table"},[_c('thead',[_c('tr',[_c('td',{staticClass:"editor-cell"}),_c('td',{staticClass:"editor-cell"}),_vm._l((_vm.columns),function(column){return _c('td',{key:("top-editing-" + (column.id)),staticClass:"editor-cell hoverable",class:[
                        'editor-cell',
                        {
                            'selected-edit-bar':
                                _vm.columnSelections[column.id] ||
                                _vm.hoveredColumn === column.id,
                            'linked-edit-bar': _vm.linkedColumns[column.id]
                        }
                    ],on:{"click":function($event){return _vm.handleColumnSelection(column.id)},"mouseenter":function($event){return _vm.handleCellMouseEnter(null, column.id)},"mouseleave":function($event){return _vm.handleCellMouseLeave(null, column.id)}}},[_c('div',{staticClass:"absolute-column-container"},[(_vm.columnSelections[column.id])?_c('column-properties',{attrs:{"column":column,"groups":_vm.groups},on:{"delete:column":_vm.handleDeleteColumn,"update:column":_vm.handleUpdateColumn}}):(_vm.linkedColumns[column.id])?_c('div',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-link")])],1):_c('add-button',{attrs:{"beforeColumnId":column.id},on:{"add":_vm.handleAddClick}})],1)])}),_c('td',{staticClass:"editor-cell"},[_c('div',{staticClass:"absolute-column-container"},[_c('add-button',{on:{"add":_vm.handleAddClick}})],1)])],2),_c('tr',[_c('td',{staticClass:"editor-cell"}),_c('th',[_c('b',[_c('title-edit',{attrs:{"title":_vm.table_title,"placeholder":"Table Name"},on:{"update:title":function (title) { return _vm.updateTableTitle(title); }}})],1),_c('resize-column-width',{on:{"resizeDragging":_vm.handleRowHeaderResize,"resizeStopDragging":_vm.stopRowHeaderResize}})],1),_vm._l((_vm.columns),function(column,index){return _c('th',{key:index,class:{
                        'selected-column':
                            _vm.columnSelections[column.id] ||
                            _vm.hoveredColumn === column.id,
                        'selected-linked-column-header':
                            _vm.linkedColumns[column.id]
                    },on:{"mouseenter":function($event){return _vm.handleCellMouseEnter(null, column.id)},"mouseleave":function($event){return _vm.handleCellMouseLeave(null, column.id)},"click":function($event){return _vm.handleColumnSelection(column.id)}}},[_c('title-edit',{key:("column-title-edit-" + (column.id)),attrs:{"title":column.title,"placeholder":"Column Name","isRequired":!column.optional},on:{"update:title":function (title) { return _vm.updateColumnTitle(title, index); }}}),_c('resize-column-width',{on:{"resizeDragging":function (delta){ return _vm.resizeDragging(column.id, delta, index); },"resizeStopDragging":_vm.stopResizeDragging}})],1)}),_c('td',{staticClass:"editor-cell"})],2)]),_c('transition-group',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],attrs:{"name":"row","tag":"tbody"}},[_vm._l((_vm.rows),function(row,index){return _c('tr',{key:("row-" + (row.id))},[_c('td',{class:[
                        'editor-cell hoverable',
                        {
                            'selected-edit-bar':
                                _vm.rowsSelections[row.id] ||
                                _vm.hoveredRow === row.id
                        }
                    ],on:{"click":function($event){return _vm.handleRowSelection(row.id)},"mouseenter":function($event){return _vm.handleRowHeaderMouseEnter(row.id)},"mouseleave":_vm.handleRowHeaderMouseLeave}},[_c('div',{staticClass:"absolute-row-container d-flex flex-column align-end",style:({ height: '100%' })},[_c('add-button',{attrs:{"row":true,"beforeRowId":row.id},on:{"add":_vm.handleAddClick}}),(_vm.rowsSelections[row.id])?_c('div',{staticClass:"d-flex flex-grow-1 align-center"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.handleDeleteRow(row.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1):_vm._e()],1)]),_c('th',{class:{
                        'selected-row':
                            _vm.rowsSelections[row.id] || _vm.hoveredRow === row.id
                    },style:({'min-width': _vm.row_headers_width+'px !important'}),on:{"mouseenter":function($event){return _vm.handleRowHeaderMouseEnter(row.id)},"mouseleave":function($event){return _vm.handleRowHeaderMouseLeave(row.id)},"click":function($event){return _vm.handleRowSelection(row.id)}}},[_c('title-edit',{key:("row-title-edit-" + (row.id)),attrs:{"title":row.title,"placeholder":"Row Name","isRequired":!_vm.question.is_optional},on:{"update:title":function (title) { return _vm.updateRowTitle(title, index); }}}),_c('resize-column-width',{on:{"resizeDragging":_vm.handleRowHeaderResize,"resizeStopDragging":_vm.stopRowHeaderResize}})],1),_vm._l((_vm.columns),function(column,columnIndex){return _c('cell',{key:("cell-" + (row.id) + "-" + (column.id)),attrs:{"column":column,"columnIndex":columnIndex,"row":row,"selections":_vm.selections,"columnSelections":_vm.columnSelections,"linkedColumns":_vm.linkedColumns,"rowsSelections":_vm.rowsSelections,"hoveredColumn":_vm.hoveredColumn,"hoveredRow":_vm.hoveredRow},on:{"click":_vm.handleCellClick,"mouseenter":_vm.handleCellMouseEnter,"mouseleave":_vm.handleCellMouseLeave,"resizeDragging":_vm.resizeDragging,"stopResizeDragging":_vm.stopResizeDragging}})}),_c('td',{class:[
                        'editor-cell hoverable',
                        {
                            'selected-edit-bar':
                                _vm.rowsSelections[row.id] ||
                                _vm.hoveredRow === row.id
                        }
                    ],on:{"click":function($event){return _vm.handleRowSelection(row.id)},"mouseenter":function($event){return _vm.handleRowHeaderMouseEnter(row.id)},"mouseleave":_vm.handleRowHeaderMouseLeave}})],2)}),_c('tr',{key:"editing-footer"},[_c('td',{staticClass:"editor-cell"},[_c('div',{staticClass:"absolute-row-container"},[_c('add-button',{attrs:{"row":true},on:{"add":_vm.handleAddClick}})],1)]),_c('td',{staticClass:"editor-cell"}),_vm._l((_vm.columns),function(column){return _c('td',{key:("bottom-editing-" + (column.id)),class:[
                        'editor-cell hoverable',
                        {
                            'selected-edit-bar':
                                _vm.columnSelections[column.id] ||
                                _vm.hoveredColumn === column.id,
                            'linked-edit-bar': _vm.linkedColumns[column.id]
                        }
                    ],on:{"click":function($event){return _vm.handleColumnSelection(column.id)},"mouseenter":function($event){return _vm.handleCellMouseEnter(null, column.id)},"mouseleave":_vm.handleCellMouseLeave}})}),_c('td',{staticClass:"editor-cell"})],2)],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }