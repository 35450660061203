<template>
    <v-hover v-slot="{hover}"> 
      <v-card rounded="0" :elevation="hover?1:0">
        <v-img
            height="80px"
            width="80px"
            :src="attachment.url"
        >
          <template v-if="hover" transition="scale-transition">
              <v-row
                class="fill-height ma-0 overllay"
                align="center"
                justify="center"
                
              >
                <v-btn icon>
                  <v-icon @click="handleDeleteClick" medium color="white">mdi-delete</v-icon>
                </v-btn>
              </v-row>
          </template>
        </v-img>
      </v-card>
    </v-hover>
</template>

<script>
export default {
  name: "AttachmentItem",
  props: {
    'attachment': {
      required: true
    }
  },
  inject: ["updateQuestion", "question"],
  methods: {
    deleteMediaAttachmentFromQuestion(attachment){
        const index = this.question.media_attachments && this.question.media_attachments.findIndex(item => item.url === attachment.url)
        if(typeof index === 'number' && index > -1){
            this.question.media_attachments.splice(index, 1)
        }
    },
    handleDeleteClick(){
      this.deleteMediaAttachmentFromQuestion(this.attachment)
      this.updateQuestion()
    }
  }

}

</script>

<style lang="scss" scoped>
  .overllay{
    background-color: rgba($color: #000000, $alpha: 0.3);
  }
</style>
