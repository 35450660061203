<template>
  <div class="ma-4">
    <div class="text-subtitle-1 mb-3">
        Video Attachment
    </div>
    <v-btn-toggle
        v-model="activeTab"
        mandatory
        color="primary"
        class="mb-4"
        group
    >
        <v-btn 
            value="link"
            class="px-4 ma-0" 
            style="border-radius: 4px !important"
        >
            <v-icon class="mr-1">
                mdi-link
            </v-icon>
            Link
        </v-btn>

        <v-btn 
            value="embed"
            class="px-4 ma-0" 
            style="border-radius: 4px !important"
            @click="handleEmbeddingClick"
        >
            <v-icon class="mr-1">
                mdi-code-tags
            </v-icon>
            Embed
        </v-btn>
    </v-btn-toggle>
    <v-text-field
        v-if="activeTab === 'link'"
        v-model="videoSrc"
        :rules="linkRules"
        label="Youtube link"
        outlined
        @change="isValidYoutubeLink && updateQuestion()"
        clearable
    >
    </v-text-field>

    <v-text-field
        v-else
        ref="fakeVideoInput"
        placeholder="Pass iframe code here"
        @click="handleOpenIframe"
        outlined
        @click:clear="removeEmbedding"
        clearable
        v-model="iframeSrc"
    >
    </v-text-field>

    <v-dialog width="500" v-model="openEmbeddingDialog" @click:outside="closeDialog">
        <v-form
            ref="form" 
            v-model="isEmbedCodeValid"
            lazy-validation
        >
            <v-card class="pa-5">
                <div class="text-subtitle-1 mb-4">Embedding</div>
                <v-textarea
                    v-model="embeddingValue"
                    :rules="embedRules"
                    placeholder="Pass iframe code here"
                    outlined
                    clearable
                    autofocus
                >
                </v-textarea>
                <div class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn text class="mr-2" @click="closeDialog">Cancel</v-btn>
                    <v-btn elevation="0" color="primary" @click="handleSave" :disabled="!isEmbedCodeValid">Save</v-btn>
                </div>
            </v-card>
        </v-form>
    </v-dialog>
  </div>
</template>

<script>

  export default {
    name: "VideoAttachmentSection",
    data() {
        return {
            isEmbedCodeValid: false,
            openEmbeddingDialog: false,
            embeddingValue: '',
            activeTab: null,
            isLink: true,
            isValidYoutubeLink: true,
            linkRules: [
                value =>  !value || this.isValidYoutubeLink || 'Invalid youtube link',
            ],
            embedRules: [
                value =>  !value || this.validIframeFormat(value) || 'Invalid iframe embedding',
            ],
        }
    },
    mounted() {
        this.activeTab = this.isIframe ? 'embed' : "link";
    },
    methods: {
        handleOpenIframe(){
            this.embeddingValue = this.iframeSrc || ''
            this.openEmbeddingDialog = true
            this.unfocusFakeInput();
        },
        handleEmbeddingClick(){
            if(!this.isIframe){
                this.handleOpenIframe()
            }
        },
        handleSave(){
            const isEmbedCodeValid = this.$refs.form.validate();
            if(!isEmbedCodeValid) {
                return;
            }
            this.question.video_source = this.embeddingValue
            this.updateQuestion()
            this.openEmbeddingDialog = false
        },
        validIframeFormat(val){
            // eslint-disable-next-line
            const regex = /^<iframe.*>.*(<\/iframe>)$/;
            const match = val && val.match(regex);
            return !!match;
        },
        unfocusFakeInput(){
            if(this.$refs.fakeVideoInput){
                this.$refs.fakeVideoInput.blur()
            }
        },
        closeDialog(){
            this.unfocusFakeInput()
            this.openEmbeddingDialog=false
        },

        removeEmbedding(){
            this.question.video_source=null
            this.updateQuestion()
            this.unfocusFakeInput()
        }
    },
    computed: {
        isIframe() {
            if(this.question.video_source === '' || this.question.video_source === null) return false;
            return this.validIframeFormat(this.question.video_source)
        },
        videoSrc: {
            get() {
                if(this.isIframe || this.question.video_source === null) return '';
                return this.question.video_source;
            },
            set(val) {
                if(!val) {
                    this.isValidYoutubeLink = true;
                    return this.question.video_source = null;
                }
                // eslint-disable-next-line
                const regExp = /^https?\:\/\/.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
                const match = val.match(regExp);
                const uniqueId = (match && match[2].length === 11) ? match[2] : false;
                this.isValidYoutubeLink = !!uniqueId;
                if(this.isValidYoutubeLink) {
                    this.question.video_source = val;
                }
            }
        },
        iframeSrc: {
            get() {
                if(!this.isIframe) {
                    return '';
                }
                return this.question.video_source;
            },
            set(val) {
                this.question.video_source = val;
            }
        }
    },
    inject: [
        "updateQuestion",
        "question"
    ],
  }
</script>