import { render, staticRenderFns } from "./Themes.vue?vue&type=template&id=0f4f312a&scoped=true&"
import script from "./Themes.vue?vue&type=script&lang=js&"
export * from "./Themes.vue?vue&type=script&lang=js&"
import style0 from "./Themes.vue?vue&type=style&index=0&id=0f4f312a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f4f312a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VCard,VCardText,VCardTitle,VDivider})
