<template>
    <div>
        <upgrade-wrapper illustration="https://cdn.voiceform.com/media/translation-illustration.png" include-dialog :blocked="!surveyTranslations" bodyText="Translate your entire survey—regardless of question type—into 50+ languages instantly with AI. Save time and effort compared to manual translations, and make your survey accessible to a global audience in seconds. Perfect for creating a single survey that works worldwide.">
            <v-btn @click="openTranslations" outlined elevation="0" :color="surveyTranslations?'primary':'deep-purple accent-3'">
                <v-icon  left>{{surveyTranslations?'mdi-translate':'mdi-diamond'}}</v-icon>
                Translations
            </v-btn>
        </upgrade-wrapper>

        <v-dialog v-model="dialog" max-width="600">
            <v-card>
                <div class="d-flex align-center px-4">
                    <div class="text-h6">Survey Translations</div>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-divider></v-divider>
                <div class="pa-4">

                    <template v-if="loadingStatuses">
                        <div class="d-flex justify-center align-center py-6">
                            <v-progress-circular
                                :size="64"
                                indeterminate
                                color="primary"
                            ></v-progress-circular>
                        </div>
                    </template>
                    <template v-else>
                        <template v-if="!translationJobs.length">
                            <div class="d-flex flex-column justify-center align-center py-6">
                                <div class="text-subtitle-1">No translations available</div>
                                <div class="text-center">Please publish your survey to start generating translations for additional languages.</div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="d-flex flex-column mb-3" v-for="translationJob in translationJobs" :key="translationJob.id">
                                <div class="d-flex align-center">
                                    <div class="text-subtitle-1">{{ getLanguageName(translationJob.language) }}</div>
                                    <v-spacer></v-spacer>
                                    <div class="d-flex align-center">
                                        <div class="text-caption grey--text mr-2">Updated {{ $date(translationJob.updated_at).fromNow() }}</div>
                                        <div v-if="translationJob.status==='in_progress'" class="text-caption d-flex align-center primary--text"> 
                                            <v-progress-circular :width="2" :size="14" indeterminate color="primary" small class="mr-2"></v-progress-circular> in progress
                                        </div>
                                        <v-chip v-else :color="getColorFromStatus(translationJob.status)" small>{{ getStatusLabel(translationJob.status) }}</v-chip>
                                        <!-- link to survey -->
                                        <v-btn v-if="translationJob.status==='completed'" small target="_blank" :href="getSurveyLink(translationJob.language)" icon class="ml-2">
                                            <v-icon small>mdi-open-in-new</v-icon>
                                        </v-btn>
                                    </div>
                                </div>
                                <v-divider class="mt-2"></v-divider>
                            </div>
                        </template>
                    </template>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import BuilderApi from '@/api/BuilderApi';
import { languagesMap } from '@/configs/languages';
import UpgradeWrapper from '../../../../components/UpgradeWrapper.vue';
import { mapGetters } from 'vuex';

export default {
  components: { UpgradeWrapper },
    name: "SurveyTranslations",
    inject: ['survey'],
    data() {
        return {
            dialog: false,
            loadingStatuses: false,
            translationJobs: [],
            intervalId: null
        }
    },
    methods: {
        getLanguageName(code) {
            if(languagesMap[code]){
                return `${languagesMap[code]} (${code})`;
            }
            return code;
        },
        getColorFromStatus(status) {
            if (status === 'completed') {
                return 'success';
            } else if (status === 'failed') {
                return 'error';
            } else if (status === 'in_progress') {
                return 'primary';
            } else if (status === 'pending') {
                return 'grey lighten-5';
            } else {
                return 'warning';
            }
        },
        getStatusLabel(status) {
            if (status === 'completed') {
                return 'Completed';
            } else if (status === 'failed') {
                return 'Failed';
            } else if (status === 'in_progress') {
                return 'In Progress';
            } else {
                return 'Pending';
            }
        },
        getSurveyLink(language) {
            if (this.survey) {
                return `${process.env.VUE_APP_APP_URL}/to/${this.survey.hash}/?lang=${language}`;
            }
            return '';
        },
        openTranslations() {
            this.dialog = true;
        },
        startRefreshing() {
            this.refreshStatuses(true); // Pass true to indicate initial load
            this.intervalId = setInterval(() => {
                this.refreshStatuses(false); // Pass false to skip loader during refresh
            }, 4000);
        },
        stopRefreshing() {
            if (this.intervalId) {
                clearInterval(this.intervalId);
                this.intervalId = null;
            }
        },
        async refreshStatuses(showLoader = false) {
            if (showLoader) {
                this.loadingStatuses = true;
            }
            try {
                const res = await BuilderApi.listTranslationStatuses(this.$route.params.surveyId);
                if (res.status === 200) {
                    this.translationJobs = res.data;
                }
            } catch (error) {
                console.error('Error in getting survey statuses:', error);
            } finally {
                if (showLoader) {
                    this.loadingStatuses = false;
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            features: 'auth/features'
        }),
        surveyTranslations(){
            return !!this.features && this.features.survey_translations
        },
    },
    watch: {
        dialog(newVal) {
            if (newVal) {
                // Dialog opened
                this.startRefreshing();
            } else {
                // Dialog closed
                this.stopRefreshing();
            }
        }
    }
}
</script>
