<template>
    <v-select
        ref="select"
        :items="items"
        item-text="value"
        item-value="value"
        outlined
        v-model="answer"
        dense
        :dark="styles.dark"
        :color="survey.style.color"
        @change="$emit('change')"
        :menu-props="{bottom: true, offsetY: true}"
        clearable
        @click:clear="answer = null"
        @keydown="handleKeydown"
    ></v-select>
</template>

<script>
export default {
    name: "Dropdown",
    props: {
        value: {
            required: true,
        },
        question: {
            required: true,
        },
        survey: {
            required: true,
        },
        styles: {
            required: true,
        },
    },
    data() {
        return {
            dropdown: false,
        };
    },

    computed: {
        answer: {
            get: function () {
                return this.value ? this.value.value : "";
            },
            set: function (val) {
                if(val === null) {
                    return this.$emit("input", null);
                }
                this.$emit("input", { type: "dropdown", value: val });
            },
        },
        items(){
            return this.question.multipleChoiceItems;
        }
    },
    methods: {
        handleKeydown(e){
            e.preventDefault()
            if(this.$refs.select) {
                e.stopPropagation()
                setTimeout(()=>{
                    this.$refs.select.isMenuActive = false;
                }, 10)
            }
        }
    },
};
</script>

<style lang="scss" scoped>
</style>
