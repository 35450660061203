<template>
    <v-navigation-drawer permanent class="d-flex nav-drawer" clipped width="300" app>
        <div class="d-flex align-center justify-space-between pa-4">
            <div class="text-h6">Content</div>
            <v-btn min-width="auto" width="44" height="44" class="add-question-button" elevation="0" large color="grey lighten-3" @click="stepTypesPopup = true">
                <v-icon color="secondary">mdi-plus</v-icon>
            </v-btn>
        </div>
        <div v-if="survey" class="px-4 d-flex questions-list-container">
            <div class="questions">
                <v-card
                    outlined
                    width="100%"
                    class="d-flex pa-3 align-center question-card disable-focus-effect"
                    :to="{
                        name: 'dashboard.voiceform.show.question',
                        params: {
                            stepType: 'welcome_screen',
                            surveyId: survey.id
                        },
                    }"
                    @click.self="checkCurrentRequest"
                    :color="!stepType || stepType==='welcome_screen'? 'primary lighten-5': null"
                >
                    <v-icon color="primary" class="mr-4" >mdi-ray-start</v-icon>
                    <div class="text-caption"> {{ formatTitle(survey.title) || 'Welcome Message'}}</div>
                    <v-spacer></v-spacer>
                    <v-btn @click.prevent="handleToggleWelcomeStepHide" icon><v-icon>{{survey.settings.hide_welcome_step?'mdi-eye-off':'mdi-eye'}}</v-icon></v-btn>
                </v-card>
                <div class="divider d-flex justify-center mt-2">
                    <v-divider vertical></v-divider>
                </div>
                <template v-if="[STATES.PENDING].includes(state)">
                    <div v-for="key in 10" :key="`placeholder-${key}`" class="my-2">
                        <skeleton-loader-vue
                            type="rect"
                            :width="268"
                            :height="62"
                            animation="fade"
                            rounded
                            radius="4"
                        />
                    </div>
                </template>
                <draggable v-else-if="questionsListData && questionsListData.length"
                    ghost-class="ghost" :animation="200" :list="questionsListData" @change="handleChangeEvent" @end="questionDragging=false" @start="questionDragging=true" :options="{group:'questions'}">
                    <v-card 
                        v-for="question in questionsListData"
                        outlined
                        :to="{
                            name: 'dashboard.voiceform.show.question',
                            params: {
                                stepType: question.id,
                                surveyId: survey.id
                            },
                        }"
                        @click.self="checkCurrentRequest"
                        :ripple="false"
                        plain
                        class="pa-3 my-2 disable-focus-effect"

                        :key="question.id"
                        :color="$route.params.stepType === question.id ? 'primary lighten-5': null"
                    >
                        <v-hover v-slot="{ hover }">
                            <div class="d-flex">
                                <div class="d-flex">
                                    <div class="d-flex align-center question-type-badge">
                                        <v-icon size="16" dark v-text="questionIcons[question.type]"></v-icon>
                                        <div class="text-body-2">{{question.order+1}}</div>
                                    </div>
                                    <span class="error--text pl-3" >{{question.is_optional?'':'*'}}</span>
                                    <div class="pl-1 text-caption">{{ formatTitle(question.text, question.type) }}</div>
                                </div>
                                <v-spacer></v-spacer>
                                <v-menu
                                    offset-x
                                    rounded
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn plain class="mx-n4" v-bind="attrs" @click.prevent v-on="on" icon>
                                            <v-icon v-if="hover || (attrs &&  attrs['aria-expanded']==='true')">mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item @click="copyQuestionLink(question)">
                                            <v-icon left small>mdi-link-variant</v-icon>
                                            <v-list-item-title class="text-body-2" >Copy link</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="duplicate(question)">
                                            <v-icon left small>mdi-content-duplicate</v-icon>
                                            <v-list-item-title class="text-body-2">Duplicate</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="handleAddQuestionClick(question.order) ">
                                            <v-icon left small>mdi-plus-circle-outline</v-icon>
                                            <v-list-item-title class="text-body-2">Add question below</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="handleDeleteClick(question)">
                                            <v-icon left small>mdi-delete-outline</v-icon>
                                            <v-list-item-title class="text-body-2">Delete</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                        </v-hover>
                    </v-card>
                </draggable>
                <div v-else class="d-flex justify-center my-4 text-body-2 grey--text text--darken-1">
                    No questions yet
                </div>
                <div class="divider d-flex justify-center mb-2">
                    <v-divider vertical></v-divider>
                </div>
                <v-card
                    outlined
                    class="pa-4 disable-focus-effect" 
                    :to="{
                            name: 'dashboard.voiceform.show.question',
                            params: {
                                stepType: 'goodbye_page',
                                surveyId: survey.id
                            },
                        }"
                        @click.self="checkCurrentRequest"
                    :color="stepType==='goodbye_page'? 'primary lighten-5': null"
                    >
                        <div class="d-flex align-center">

                            <v-icon color="primary" class="mr-4">mdi-ray-end</v-icon>

                            <div class="text-caption">{{formatTitle(survey.goodbye_message) || 'Goodbye Message'}}</div>
                        </div>
                </v-card>
                <div class="pt-12"></div>
            </div>
        </div>
        <!-- Questions options -->
        <v-dialog width="650" v-model="stepTypesPopup" @click:outside="handleStepTypesPopupClose">
            <v-card class="pa-6">
                <div class="question-types">
                    <v-card color="transparent" :elevation="0" @click="addQuestion(type.value)" v-for="(type, i) in availableTypes" :key="i" :disabled="!type.enabled">
                        <StepTypeIcon :loading="type.value === createQuestionLoadingType" :icon="type.icon" :title="type.title" />
                    </v-card>
                </div>
            </v-card>
        </v-dialog>
        <!-- Delete question confirmation -->
        <v-dialog
                v-model="deleteDialog"
                width="500"
                @click:outside="closeDeleteDialog"
            >
            <v-card class="remove-dialog pt-4 pb-3 px-6">
                <div class="text-h6 mb-4">
                    Delete Question
                </div>
                <p>
                    Are you sure you want to delete {{ deleteQuestionTitle }} question?
                    Once deleted, you will not be able to recover this
                    question or its contents. Answers previously
                    submitted for this question won't be available.
                </p>
                <v-alert v-if="survey.has_logic" icon="mdi-alert" outlined type="warning">
                    This voiceform has logic. Please be aware that deleting this question might impact its functionality.
                </v-alert>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        @click="closeDeleteDialog"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="red"
                        dark
                        :elevation="0"
                        @click="deleteQuestion"
                    >
                        Yes, Delete it
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-navigation-drawer>
</template>

<script>
import useSWRV from 'swrv'
import fetcher from '../../../../api/fetcher'
import { mapActions, mapGetters, mapMutations } from "vuex";
import CustomPopup from "@/components/CustomPopup.vue";
import StepTypeIcon from "../../../../components/icons/StepTypeIcon.vue";
import CustomInput from "@/components/elements/CustomInput.vue";
import draggable from 'vuedraggable'
import FormBuilder from '../../../../layouts/FormBuilder.vue';
import {questionIcons} from '../../../../configs/questionTypes'
import { useRoute } from "vue-router/composables"
import BuilderApi from '../../../../api/BuilderApi'
import useSwrvState from '@/utils/useSwrvState'
import { uuid } from "vue-uuid";

export default {
    components: {
        CustomPopup,
        StepTypeIcon,
        CustomInput,
        draggable,
        FormBuilder
    },
    data() {
        return {
            createSurveyLoading: false,
            createQuestionLoadingType: null,
            stepTypesPopup: false,
            stepTypesPopupTimeout: null,
            addingQuestionOrder: null,
            surveyNamePopup: false,
            deletingQuestion: null,
            deleteDialog: false,
            activeQuestion: false,
            pendingNavigatingNode: null,
            questionIcons,
            loadingNextQuestions: false,
            questionDragging: false,
        };
    },
    inject: ['survey', 'refreshSurvey'],
    computed: {
        ...mapGetters({
            ongoingPutRequest: "survey/ongoingPutRequest",
            features: 'auth/features'
        }),

        stepType() {
            return this.$route.params.stepType;
        },

        deleteQuestionTitle() {
            if (this.deletingQuestion && this.deletingQuestion.type === 'description-slide') {
                return '"Description Slide"';
            } else if (this.deletingQuestion && this.deletingQuestion.text) {
                return `"${this.deletingQuestion.text}"`;
            } else if (this.deletingQuestion) {
                return `"${this.stepTypes.find(e => e.value === this.deletingQuestion.type).title}"`;
            } else {
                return "this";
            }
        },
        availableTypes(){
            return this.stepTypes.filter(type => !type.hidden)
        },
        stepTypes() {
            const commonTypes = [
                { title: "Voice Response", value: "voice-response", icon: "microphone", group: "Voice", enabled: true },
                { title: "Audio Question", value: "voice-question", icon: "account-voice", group: "Voice", enabled: true },
            ];

            const additionalTypes = [
                { title: "Date Picker", value: "date-picker", icon: "calendar", group: "Basic Components" },
                { title: "Name", value: "name", icon: "profile", group: "Personal Information" },
                { title: "Email", value: "email", icon: "email", group: "Personal Information" },
                { title: "Phone Number", value: "phone-number", icon: "mdi-phone", group: "Personal Information" },
                { title: "Numeric Input", value: "numeric-input", icon: "mdi-numeric-0-box-multiple-outline", group: "Basic Component" },
                { title: "Zip Code", value: "zip-code", icon: "mdi-map-marker-outline", group: "Personal Information", hidden: !this.survey.inbounds },
                { title: "Dropdown", value: "dropdown", icon: "polygon", group: "Basic Components" },
                { title: "Yes / No", value: "yesno", icon: "yesno", group: "Basic Components" },
                { title: "Rating", value: "rating", icon: "star", group: "Basic Components" },
                { title: "Checkbox", value: "checkbox", icon: "checkbox-marked-outline", group: "Basic Components" },
                { title: "Custom Field", value: "custom-field", icon: "pen", group: "Basic Components" },
                { title: "Multiple Choice", value: "multiple-choice", icon: "radiobox-marked", group: "Basic Components" },
                { title: "Net Promoter Score", value: "net-promoter-score", icon: "mdi-speedometer", group: "Basic Components" },
                { title: "Matrix", value: "matrix", icon: "mdi-table", group: "Basic Components", hidden: !this.features.matrix_question},
                { title: "Description Slide", value: "description-slide", icon: "mdi-image-text", group: "Basic Components" },
            ];

            const enabled = this.features && this.features.all_question_types;

            additionalTypes.forEach(type => type.enabled = enabled);

            additionalTypes.push({title: "File Upload", value: "file-upload", icon: "mdi-file-upload", group: "Basic Components", enabled: this.features.file_upload})

            return commonTypes.concat(additionalTypes);
        }
    },
    methods: {
        ...mapActions({
            updateSurvey: "survey/updateSurvey",
            createQuestion: "survey/createQuestion",
            duplicateQuestion: "survey/duplicateQuestion",
        }),
        ...mapMutations({
            destroyQuestionInSurvey: "survey/destroyQuestionInSurvey",
            setSurveyToStore: "survey/setSurvey",
            setDefaultQuestion: "survey/setDefaultQuestion",
            setDefaultSurvey: "survey/setDefaultSurvey",
            addQuestionToSurvey: "survey/addQuestionToSurvey",
            setSettings: "survey/setSettings",
        }),
        ...mapMutations(['showSnackBar']),

        formatTitle(title, questionTypeValue = undefined) {
            if (!title && !questionTypeValue) {
                return null;
            }

            if (title && title.length > 30) {
                // we can't directly slice the string because it won't handle unicode/emojis correctly
                return `${Array.from(title).slice(0, 30).join("")}...`;
            }
            if ((!title || title === 'Type your question here') && questionTypeValue) {
                return this.stepTypes.find(type => type.value === questionTypeValue).title;
            }

            return title;
        },
        // checking if there is currently a put request going on, if so, we want ot schedule a redirection
        // when the pull request is done
        checkCurrentRequest(event) {
            if (this.ongoingPutRequest) {
                this.pendingNavigatingNode = event.target;
            }
        },
        cancelSurvey() {
            this.$router.back();
        },
        async copyQuestionLink(question) {
            try {
                await navigator.clipboard.writeText(window.location.origin + "/dashboard/voiceform/"
                    + this.survey.id + "/question/" + question.id);
            } catch (err) {
                console.error(err);
            }
            this.showSnackBar({
                text: "Question link copied.",
                color: "success",
                timeout: 1000,
            });
        },
        handleDeleteClick(question) {
            this.deleteDialog = true
            this.deletingQuestion = {...question}
        },
        closeDeleteDialog(){
            this.deleteDialog = false
            this.deletingQuestion = null
        },
        async handleToggleWelcomeStepHide(){
            try {
                this.survey.settings.hide_welcome_step = !this.survey.settings.hide_welcome_step
                await BuilderApi.patchForm(this.survey.id, {settings: {...this.survey.settings, hide_welcome_step: this.survey.settings.hide_welcome_step}})
                await this.refreshSurvey()
            } catch (error) {
                console.error('error in handleToggleWelcomeStepHide', error)
            }
        },
        async deleteQuestion() {
            try {
                if (this.deletingQuestion) {
                    const order = this.deletingQuestion.order;
                    const res = await BuilderApi.deleteQuestion(this.survey.id,this.deletingQuestion.id);
                    this.refreshSurvey()
                    // impacts survey question count, so we need to refresh
                    this.questionsListData = res.data;
                    if(this.$route.params.stepType === this.deletingQuestion.id){
                        if(res.data && res.data.length){
                            this.$router.push({
                                name: 'dashboard.voiceform.show.question',
                                params: {
                                    stepType: order>res.data.length-1? res.data[res.data.length-1].id : res.data[order].id,
                                    surveyId: this.survey.id
                                },
                            })
                        }else if (!this.survey.settings.hide_welcome_step) {
                            this.$router.push({
                                name: 'dashboard.voiceform.show.question',
                                params: {
                                    stepType: 'welcome_screen',
                                    surveyId: this.survey.id
                                },
                            })
                        } else {
                            this.$router.push({
                                name: 'dashboard.voiceform.show.question',
                                params: {
                                    stepType: 'goodbye_page',
                                    surveyId: this.survey.id
                                },
                            })
                        }
                    }
                    this.closeDeleteDialog();
                }
            } catch (error) {
                console.error('deleteQuestion', error);
            }
        },
        async addQuestion(questionType) {
            if (this.stepTypes.find(stepType => stepType.value == questionType)) {

                this.createQuestionLoadingType = questionType
                const multiple_choice_items = questionType === 'rating' ? [
                    { icon: 'star', order: 1 },
                    { icon: 'star', order: 2 },
                    { icon: 'star', order: 3 },
                    { icon: 'star', order: 4 },
                    { icon: 'star', order: 5 },
                ] : []
                const properties = (questionType === "voice-response" || questionType === 'voice-question') ? {
                    maxRecordTime: 150,
                    minRecordTime: 0
                } : {};
                const questionData = {
                    multiple_choice_items,
                    properties,
                    type: questionType,
                }
                if(questionType === "description-slide") {
                    questionData['is_optional'] = 1;
                }

                if(this.addingQuestionOrder !== null){
                    questionData['order'] = this.addingQuestionOrder
                }

                // MATRIX defaults
                if (questionType === 'matrix') {
                    const generatedColumns = []
                    for (let i = 0; i < 4; i++) {
                        generatedColumns.push({
                            id: `column_${uuid.v4().replace(/-/g, "")}`,
                            title: `Column ${i + 1}`,
                            type: 'radio',
                            properties: [],
                            optional: true,
                        })
                    }

                    const generatedRows = []
                    for (let i = 0; i < 3; i++) {
                        generatedRows.push({
                            id: `row_${uuid.v4().replace(/-/g, "")}`,
                            title: `Row ${i + 1}`,
                        })
                    }


                    questionData['properties'] = {
                        ...questionData['properties'],
                        columns: generatedColumns,
                        rows: generatedRows,
                    }

                }

                const res = await BuilderApi.createQuestion(this.survey.id,questionData);
                const question = res.data.question;
                this.revalidateQuestionsList();
                this.refreshSurvey()
                this.createQuestionLoadingType = null;

                _cio.track("add_question.success", {
                    questionType,
                })

                this.handleStepTypesPopupClose();
                this.$router.push({
                    name: 'dashboard.voiceform.show.question',
                    params: {
                        stepType: question.id,
                    },
                    props: {
                        question
                    }
                });
            }
        },
        async duplicate(question) {
            try {
                const res = await BuilderApi.duplicateQuestion(this.survey.id,question.id);
                this.refreshSurvey()
                this.questionsListData = res.data;
                
                this.$router.push({
                    name: 'dashboard.voiceform.show.question',
                    params: {
                        stepType: res.data[question.order + 1].id,
                        surveyId: this.survey.id
                    },
                })

            } catch (error) {
                this.showSnackBar({
                    text: "Cannot duplicate question.",
                    color: 'error', 
                    timeout: 2000,
                })
            }
        },
        getQuestionIcon(value){
          return this.stepTypes.find(stepType=> stepType.value === value)
        },
        async handleChangeEvent(event){
            // if element has been moved
            if(event.moved && event.moved.element){
                if(!this.survey.has_logic || window.confirm("This voiceform has logic. Please be aware that reordering this question might impact the logic functionality.")){
                    const updatedQuestions = await BuilderApi.updateQuestionOrder(this.survey.id, event.moved.element.id, event.moved.newIndex)
                    this.questionsListData = updatedQuestions.data;
                }else{
                    this.revalidateQuestionsList();
                }
            }
        },

        handleAddQuestionClick(order) {
            this.stepTypesPopup = true;
            this.addingQuestionOrder = order+1;
        },

        handleStepTypesPopupClose() {
            this.stepTypesPopup = false;
            this.addingQuestionOrder = null;
        },


    },
    updated() {
        // if we have a pending click to a question/navigation, trigger it when the currest request finish
        if (this.pendingNavigatingNode && !this.ongoingPutRequest) {
            this.pendingNavigatingNode.click();
            this.pendingNavigatingNode = null;
        }
    },
    mounted: function () {
        if (this.$route.params.openStepTypesPopup) {
            this.stepTypesPopupTimeout = setTimeout(() => this.stepTypesPopup = true, 500);
        }
    },
    beforeDestroy() {
        clearTimeout(this.stepTypesPopupTimeout);
    },
    setup(){
        const route = useRoute();
        const { data, error, isValidating, mutate } = useSWRV(`/api/builder/${route.params.surveyId}/questions`, fetcher);
        const { state, STATES } = useSwrvState(data, error, isValidating)
        return {state, STATES, questionsListData:data, error, isValidating, revalidateQuestionsList:mutate };
    }
};
</script>

<style lang="scss" scoped>
.divider{
    height: 40px
}
.add-question-button{
    width: 44px; 
    height: 44px;
    min-width: unset; 
    background-color: var(--v-primary-lighten4)
}
.question-type-badge{
    width: 52px;
    min-width: 52px;
    height: 24px;
    justify-content: space-between;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 4px;
    background-color: var(--v-primary-base);
    color: white;
}
.question-card{
    width: 100%;
}
.nav-drawer::v-deep .v-navigation-drawer__content{
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.questions-list-container{
    overflow-y: scroll;
    width: 100%;
    flex: 1;
}
.questions {
    display: flex;
    flex-direction: column;
    flex: 1
}

.ghost{
    opacity: 0;
}

.question-types {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20rem;
    row-gap: 2rem;
}

.flip-list-move {
  transition: transform 0.5s;
}
.disable-focus-effect:focus{
    &::before{
        opacity: 0 !important;
    }
}

</style>