<template>
    <v-fade-transition>
        <v-card v-if="showProbingTrialBanner" rounded elevation="0" class="gradient-banner pa-3">
            <v-btn icon class="close-button">
                <v-icon @click="closeBanner">mdi-close</v-icon>
            </v-btn>
            <div class="text-subtitle-1 mb-3 d-flex">
                <v-icon class="mr-4" color="primary">mdi-robot-love</v-icon>
                You’ve been given 100 AI probes for FREE!
            </div>
            <div class="mb-3">
                Leverage Voiceform’s new AI probing feature to gain deeper insights from your respondents.
            </div>
    
            <div class="d-flex flex-wrap">
                <v-btn @click="enableProbing" :loading="loading" :disabled="loading"  elevation="0" color="primary" class="mr-1"><v-icon left>mdi-shimmer</v-icon>Enable AI Probing</v-btn>
                <v-btn href="https://youtu.be/rUOY0XbxfnA?si=xZUVV2vxgwmAOWNH" target="_blanc" color="primary" text>Learn More</v-btn>
            </div>
        </v-card>
    </v-fade-transition>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import UserInfoApi from '@/api/UserInfoApi';
export default {
    data(){
        return {
            showBanner: true,
            loading: false
        }
    },
    computed: {
        ...mapGetters({ 
            features: 'auth/features',
            user: 'auth/user',
        }),
        hasProbingFeature(){
            return !!(this.features && this.features.probing)
        },
        showProbingTrialBanner(){
            if(this.showBanner && this.user && this.user.price_plan_id !== null && this.features.probing_trial === false && this.features.probing_trial_ended_at === null && !this.hasProbingFeature){
                const bannerClosed = localStorage.getItem('probing_trial_banner_closed')
                if(bannerClosed){
                    const bannerClosedDate = new Date(parseInt(bannerClosed))
                    const now = new Date()
                    if(now.getTime() < bannerClosedDate.getTime()){
                        return false
                    }
                }
                return true
            }

            return false
        }
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        closeBanner(){
            // store to local storage for 3 days
            localStorage.setItem('probing_trial_banner_closed', new Date().getTime() + 3 * 24 * 60 * 60 * 1000)
            this.showBanner = false
        },

        async enableProbing(){
            try {
                this.loading = true
                const res = await UserInfoApi.startProbingTrial(this.$route.params.surveyId)
                if(res.data){
                    this.showSnackBar({
                        text: 'You have successfully enabled AI Probing trial.',
                        color: 'success'
                    })
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000);
                }
            } catch (error) {
                this.showSnackBar({
                    text: 'Something went wrong. Please try again.',
                    color: 'error'
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.close-button{
    position: absolute;
    top: 0;
    right: 0;

}
.gradient-banner {
    /* border: 2px solid;  */
    background: linear-gradient(45deg, #eeeefd, rgb(255, 238, 255)) padding-box,
              linear-gradient(to right, #0000ff, rgb(251, 0, 255)) border-box;
    border-radius: 10px !important;
    border: 1px solid transparent;
}
</style>