<template>
    <div>
        <question-text
            :question="question"
            :errors="errors"
            @change="updateQuestion"
            @focus="clearTextInput"
            class="mb-3"
        ></question-text>
        <v-divider class="my-3"></v-divider>
        <div class="d-flex align-center mb-2">
            <v-checkbox
                v-model="randomizeOptions"
                hide-details
                small
                class="ma-0 pa-0"
            ></v-checkbox>
            <div class="text-caption grey--text text--darken-1">Randomize Options</div>
        </div>
        <v-expand-transition>
            <div v-if="randomizeOptions" class="d-flex align-center mb-2">
                <v-icon color="grey">mdi-arrow-right-bottom</v-icon>
                <v-autocomplete
                    v-model="excludedFromRandomization"
                    :items="itemsList"
                    outlined
                    dense
                    chips
                    small-chips
                    label="Exclude from randomization"
                    multiple
                    class="text-body-2"
                    hide-details="auto"
                ></v-autocomplete>
            </div>
        </v-expand-transition>
        <div class="d-flex align-center mb-2">
            <v-checkbox
                v-model="addNoneOfTheAbove"
                hide-details
                small
                class="ma-0 pa-0"
            ></v-checkbox>
            <div class="text-caption grey--text text--darken-1">Add 'None of the above' option</div>
        </div>
        <div class="mb-2">
            <div class="text-caption grey--text text--darken-1 mb-2">Allowed number of choices</div>
            <div class="d-flex">
                <v-text-field @change="handleMinChange" v-model="minChoicesNumberProperty" hide-details="auto" prefix="Min" outlined dense class="text-caption" type="number" placeholder="number"></v-text-field>
                <div class="mx-1"></div>
                <v-text-field @change="handleMaxChange" v-model="maxChoicesNumberProperty" hide-details="auto" prefix="Max" outlined dense class="text-caption" type="number" placeholder="number"></v-text-field>
            </div>
        </div>
        <options-editor></options-editor>
    </div>
</template>

<script>
import QuestionText from "../QuestionText.vue"
import OptionsEditor from './OptionsEditor.vue';

export default {
    name: "CheckboxType",
    props: ["errors"],
    components: { QuestionText, OptionsEditor},
    inject: [
        "updateQuestion",
        "question"
    ],
    computed: {
        randomizeOptions: {
            get() {
                return this.question.properties && this.question.properties.randomize_options || false;
            },
            set(value) {
                this.question.properties = {...this.question.properties, randomize_options: value };
                this.updateQuestion();
            }
        },
        addNoneOfTheAbove: {
            get() {
                return this.question.properties && this.question.properties.add_none_of_the_above || false;
            },
            set(value) {
                this.question.properties = {...this.question.properties, add_none_of_the_above: value };
                this.updateQuestion();
            }
        },
        minChoicesNumberProperty: {
            get() {
                return this.question.properties && this.question.properties.min_choices_number || '';
            },
            set(value) {
                if(value === '' || value === null) {
                    value = null;
                } else {
                    value =  Math.abs(parseInt(value));
                }
                this.question.properties = {...this.question.properties, min_choices_number: value };
            }
        },
        maxChoicesNumberProperty: {
            get() {
                return this.question.properties && this.question.properties.max_choices_number || '';
            },
            set(value) {
                if(value === '' || value === null) {
                    value = null;
                } else {
                    // make sure it's not negative
                    value = Math.abs(parseInt(value));
                }
                this.question.properties = {...this.question.properties, max_choices_number: value };
            }
        },
        itemsList() {
            // Create a Set to hold unique values
            const uniqueValuesSet = new Set();

            // Check if multiple_choice_items is defined and is an array
            if (Array.isArray(this.question.multiple_choice_items)) {
                // Add values from multiple_choice_items to the Set
                this.question.multiple_choice_items.forEach(item => {
                    if (item && item.value !== undefined) {
                        uniqueValuesSet.add(item.value);
                    }
                });
            }

            // Check if excluded_from_randomization is defined and is an array
            const excludedValues = this.question.properties?.excluded_from_randomization;
            if (Array.isArray(excludedValues)) {
                // Add values from excluded_from_randomization to the Set
                excludedValues.forEach(value => {
                    if (value !== undefined) {
                        uniqueValuesSet.add(value);
                    }
                });
            }

            // Convert the Set back to an array
            return Array.from(uniqueValuesSet);
        },
        excludedFromRandomization: {
            get() {
                return this.question.properties && this.question.properties.excluded_from_randomization || [];
            },
            set(value) {
                this.question.properties = {...this.question.properties, excluded_from_randomization: [...value] };
                this.updateQuestion();
            }
        },
    },
    methods: {
        clearTextInput() {
            if(this.question.text === "Type your question here") {
                this.question.text = "";
            }
        },
        handleMinChange(){
            if(typeof this.minChoicesNumberProperty === 'number' && typeof this.maxChoicesNumberProperty ==='number' && this.minChoicesNumberProperty > this.maxChoicesNumberProperty) {
                this.question.properties = {
                    ...this.question.properties,
                    max_choices_number: this.minChoicesNumberProperty,
                };
            }

            this.updateQuestion();
        },
        handleMaxChange(){
            if(typeof this.minChoicesNumberProperty === 'number' && typeof this.maxChoicesNumberProperty ==='number' && this.maxChoicesNumberProperty < this.minChoicesNumberProperty) {
                this.question.properties = {
                    ...this.question.properties,
                    min_choices_number: this.maxChoicesNumberProperty,
                };
            }
            this.updateQuestion();
        },
    }
};
</script>
