<template>
    <div class="icon">
        <i v-if="!icon.includes('mdi')">
            <img :src="require(`../../assets/img/icons/${icon}.svg`)" />
        </i>
        <v-icon v-if="icon.includes('mdi')" dark>{{icon}}</v-icon>
        <v-overlay color="primary" :value="loading" absolute>
            <v-progress-circular
                indeterminate
                dark
            ></v-progress-circular>
        </v-overlay>
        <span>{{ title }}</span>
    </div>
</template>

<script>
export default {
    name: "StepTypeIcon",
    props: {
        icon: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        }
    },
};
</script>

<style lang="scss" scoped>
.icon {
    display: grid;
    grid-auto-flow: column;
    align-items: center;

    justify-content: start;
    grid-gap: 3rem;
    i {
        background: var(--primary);
        border-radius: 1rem;
        width: 5rem;
        height: 5rem;

        display: grid;
        place-items: center;

        img {
            width: 3rem;
            height: 3rem;
            margin: auto;
        }
    }

    span {
        font-weight: 600;
        font-size: 2.1rem;
        line-height: 2.6rem;
        color: var(--black);
    }
}
</style>
