<template>
    <div class="mt-4 pa-6">
        <div class="d-flex align-center mb-5">
            <div class="text-subtitle-1">
                Dynamic Variables
            </div>
            <v-tooltip color="black" background-opacity="1" bottom max-width="300">
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        class="ml-2 "
                        v-bind="attrs"
                        v-on="on"
                    >
                    mdi-information-outline
                    </v-icon>
                </template>
                <span class="text-caption loop-tooltip">
                    Dynamic variables allow you to pass data into your survey dynamically. Use <code>{{}}</code> brackets to insert the variable within the survey. To assign a value in the survey link, use a URL query parameter. For example, for a variable named <code>'foo'</code>, the query parameter will be passed like that: https://surveylink.com?<code>foo=1234</code>
                </span>
            </v-tooltip>
        </div>
        <v-hover>
            <v-card class="pa-3" @click="handleEdit" style="position: relative;" elevation="0" color="grey lighten-5">
                <div v-if="variables && variables.length === 0">
                   <v-icon left>mdi-plus</v-icon> Add new variable
                </div>
                <div
                    v-for="(variable) in variables"
                    :key="variable.name"
                    class="text-caption"
                >
                    <span class="text-caption text-bold">{{variable.name}}</span> -
                    <span >{{variable.type}}</span>
                </div>
            </v-card>
        </v-hover>
        <v-dialog width="100%" max-width="900" v-model="dialog">
            <v-card class="pa-3">
                <div class="text-subtitle-1 mb-2">Edit Dynamic Variables</div>

                <dynamic-variable-edit-state createMode></dynamic-variable-edit-state>
                <v-divider class="py-3"></v-divider>
                <dynamic-variable-list-item
                    v-for="(variable) in variables"
                    :key="variable.id"
                    :variable="variable"
                ></dynamic-variable-list-item>
            </v-card>
        </v-dialog>
                
    </div>
</template>

<script>
import BuilderApi from '@/api/BuilderApi';
import DynamicVariableListItem from './DynamicVariableListItem.vue';
import DynamicVariableEditState from './DynamicVariableEditState.vue';

export default {
    name: 'DynamicVariables',
    components: {DynamicVariableListItem, DynamicVariableEditState},
    data(){
        return {
            loading: false,
            variables: null,
            dialog: false,
        }
    },
    methods: {
        async handleEdit(){
            this.dialog = true;
        },
        async loadVariables(){
            const res = await BuilderApi.listDynamicVariables(this.$route.params.surveyId);
            if(res.data){
                this.variables = res.data;
            }
            return res;
        }
    },

    provide(){
        return {
            refreshVariables: this.loadVariables
        }
    },
    async created(){
        try {
            this.loading = true;
            const res = await this.loadVariables();
            if(res.data){
                this.variables = res.data;
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.loading = false;
        }
    }
}
</script>

<style lang="scss" scoped>
    .position-absolute{
        top: 0;
        right: 0;
    }
    .placeholder{
        display: flex;
        align-items: center;
        height: 40px;
        border-radius: 4px;
    }
    .loop-tooltip{
        & > mark, & > code{
            background-color: var(--v-primary-lighten4);
            color: black;
            border-radius: 2px;
            padding: 2px;
        }
    }
</style>
