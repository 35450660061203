<template>
    <div class="question my-10">
        <div
            v-if="step.type == questionTypes.VOICE_QUESTION || step.audio_url"
            class="audio-question-container mb-4"
        >
                <audio controls :src="step.audio_url" ref="player"></audio>
        </div>
        <div :style="{color: survey.style ? survey.style.color : survey.font_color}">

        </div>
        <question-text-input :styles="styles"></question-text-input>
        <div class="mb-2"></div>
        <description-edit :styles="styles" />
        <video-attachment v-if="step.video_source" />
        <media-attachment  :attachments="step.media_attachments" :survey="survey" />
        <div class="mt-4" >
            <slot></slot>
        </div>
        <div ref="observingNode" class="d-flex flex-column mt-10">
            <click-prevent>
                <v-btn min-width="200" width="200" elevation="0" large :color="styles.button.background"
                    :style="`color: ${styles.button.color}; cursor: not-allowed;`"
                >
                        <v-icon v-if="buttonContent.icon" :color="styles.button.color" left>{{
                            buttonContent.icon
                        }}</v-icon>
                        {{ buttonContent.text }}
                </v-btn>
            </click-prevent>
        </div>
        <v-app-bar :color="styles.background"
            :style="`border-top: thin solid ${styles.dark ? '#ffffff30' : '#00000030'}`" v-if="!this.isIntersecting"
            absolute elevation="0" :bottom="true">
            <v-container>
                <v-spacer class="d-none d-sm-flex"></v-spacer>
                <click-prevent>
                    <v-btn min-width="200"
                        width="200" elevation="0" large
                        :color="styles.button.background" :style="`color: ${styles.button.color}; cursor: not-allowed;`"
                    >
                            <v-icon v-if="buttonContent.icon" :color="styles.button.color" left>{{
                                buttonContent.icon
                            }}</v-icon>
                            {{ buttonContent.text }}
                    </v-btn>
                </click-prevent>
            </v-container>
        </v-app-bar>
    </div>
</template>

<script>
import questionTypes from "@/configs/questionTypes";
import VideoAttachment from './VideoAttachmentEdit.vue';
import MediaAttachment from './MediaAttachmentEdit.vue';
import ClickPrevent from './ClickPrevent.vue';
import QuestionTextInput from './QuestionTextInput.vue';
import DescriptionEdit from './DescriptionEdit.vue';
export default {
    name: "QuestionStep",
    props: {
        step: {
            required: true,
        },
        contrastTextColor: {
            required: true,
        },
        survey: {
            required: true
        },
        styles: {
            required: true
        }
    },
    components: {VideoAttachment, MediaAttachment, ClickPrevent, QuestionTextInput, DescriptionEdit},
    data() {
        return {
            paused: true,
            questionTypes,
            isIntersecting: true,
        };
    },
    methods: {
        audioPlay(isPaused) {
            if (isPaused) {
                this.$refs.player.play();
                this.paused = false;
            } else {
                this.$refs.player.pause();
                this.paused = true;
            }
        },
        onIntersect(entries) {
            this.isIntersecting = entries[0].intersectionRatio >= 0.8;
        },
    },
    computed:{
        questionText(){
            if(this.step.text && this.step.text.trim() === 'Type your question here'){
                return ''
            }
            return this.step.text ? this.step.text : (this.step.type=== questionTypes.VOICE_QUESTION && "Audio Question") || ""
        },
        slotName(){
            if(this.step.type === questionTypes.VOICE_QUESTION){
                return questionTypes.VOICE_RESPONSE
            }
            return this.step.type
        },
        localization(){
            return this.survey.settings.localization
        },
        isDescriptionSlide() {
            return this.step.type === 'description-slide'
        },
        isLastQuestion() {
            return this.step.order+1 === this.survey.questions_count
        },
        buttonContent: function () {
            if (this.isDescriptionSlide && !this.isLastQuestion) {
                return {
                    icon: false,
                    text: "Continue"
                }
            }

            if (this.isLastQuestion || this.currentAnswerValueWillTriggerSubmitAction) {
                return {
                    icon: false,
                    text: this.localization.finish || "Finish"
                }
            }


            return {
                icon: 'mdi-check-circle',
                text: this.localization.next || "Next"
            }
        },
    },
    watch: {
        step: {
            deep: true,
            handler(newVal, oldVal) {
                if (newVal.audio_url === oldVal.audio_url) {
                    return;
                }
                this.$refs.player?.load();
            }
        },
    },
    mounted(){
        _cio && _cio.track('question_edit.viewed', {
            fullPath: this.$route.fullPath
        })
        if (this.$refs.observingNode) {
            const observer = new IntersectionObserver(this.onIntersect, { threshold: [0, 0.8, 1.0] });
            observer.observe(this.$refs.observingNode);
        }
    }
};
</script>

<style lang="scss" scoped>
.audio-question-container {
    display: flex;
    // justify-content: center;
    padding: 8px
}
.question {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 700px;
    &--description{
        word-break: break-word;
    }
    &-required::after{
        content: "*";
        
    }
    .audio-play {
        width: 100%;
        display: grid;
        grid-auto-flow: column;
        grid-gap: 1.4rem;
        align-items: center;
        grid-template-columns: max-content 1fr;

        &--button {
            background: #000000;
            border-radius: 8px;
            cursor: pointer;

            height: max-content;
            font-weight: 700;
            font-size: 17px;
            line-height: 21px;

            color: #ffffff;
            padding: 10px;
            display: flex;

            .play-icon {
                border: 0;
                background: transparent;
                box-sizing: border-box;
                width: 0;
                height: 10px;

                border-color: transparent transparent transparent #ffffff;
                transition: 100ms all ease;
                cursor: pointer;

                border-style: solid;
                border-width: 5px 0 5px 8px;

                &.pause {
                    border-style: double;
                    border-width: 0px 0 0px 8px;
                }
            }
        }
    }
}
</style>
