<template>
    <v-hover v-slot="{ hover }">
        <v-card :color="hover? 'grey lighten-4': 'grey lighten-5'" elevation="0" @click="openDialog" class="pa-3">
            <div class="d-flex align-center">
                <v-chip v-if="survey.send_submission_confirmation_email" color="green accent-1" label x-small class="mr-1">
                    On
                </v-chip>
                <v-chip v-else label x-small class="mr-1">
                    Off
                </v-chip>
                <div class="text-subtitle-2">
                    Submission Confirmation
                </div>
            </div>
            <div class="text-caption">The respondent will receive an email with the confirmation that the survey was successfully submitted after its processing</div>
            <div v-if="hover">
                <v-icon class="edit-icon pa-2">mdi-cog</v-icon>
            </div>
            <v-dialog width="800" v-model="dialog">
                <v-card>
                    <v-app-bar dense elevation="0" color="primary lighten-5">
                        <div class="text-subtitle-1">Submission Confirmation Email</div>
                        <v-spacer></v-spacer>
                        <v-btn :disabled="savingSettings" :loading="savingSettings" dark elevation="0" color="primary" @click="saveSettings">
                            Save
                        </v-btn>
                    </v-app-bar>
                    <div class="pa-3">
                        <v-switch v-model="send_submission_confirmation_email" label="Enable Confirmation Email Notification"></v-switch>
                        <v-divider class="mb-2"></v-divider>
                        <div class="text-subtitle-1 mb-3">Email Customization</div>
                        <v-form 
                            ref="emilCustomizationForm" 
                            v-model="valid"
                            lazy-validation
                        >
                            <v-text-field validate-on-blur :rules="[requiredRule, nonEmptyStringRule]" hide-details="auto" class="text-body-2 mb-3" dense placeholder="Email subject" outlined v-model="subject"></v-text-field>
                            <v-text-field validate-on-blur :rules="[requiredRule, nonEmptyStringRule]" hide-details="auto" class="text-body-2 mb-4" dense placeholder="Main email message" outlined v-model="message"></v-text-field>
                            <div class="d-flex align-center mb-3">
                                <v-checkbox class="ma-0 pa-0" :disabled="!allowHidingBranding" v-model="voiceformBranding" hide-details="auto" label="Voiceform Branding"></v-checkbox>
                                <v-chip class="ml-1" dark color="deep-purple accent-3" x-small  v-if="!allowHidingBranding">PRO</v-chip>
                            </div>
                            <div class="d-flex align-center mb-5">
                                <v-checkbox class="ma-0 pa-0" :disabled="!aiSummaryFeature" v-model="aiSummary" hide-details="auto" label="Include AI Summary"></v-checkbox>
                                <v-chip class="ml-1" dark color="deep-purple accent-3" x-small  v-if="!aiSummaryFeature">PRO</v-chip>
                            </div>
                            <div class="d-flex align-center mt-4 mb-3">
                                <div class="mr-2 text-body-1">Top banner color</div>
                                <color-picker v-model="bannerColor"></color-picker>
                            </div>

                            <div class="pt-5"></div>

                            <upgrade-section :blocked="!allowHidingBranding">
                                <div class="d-flex align-center mb-2">
                                    <v-tooltip right bottom max-width="250">
        
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon small left v-on="on" v-bind="attrs">mdi-information-outline</v-icon>
                                        </template>
                                        <span class="text-caption">
                                            The logo will be displayed in the top left corner of the email. If not added, no logo will be shown. The logo width is 140px and height is adjusted automatically.
                                        </span>
                                    </v-tooltip>
                                    <div class="text-subtitle-2">Top Left Logo</div>
                                </div>
                                <div class="d-flex mb-2">
                                    <div class="d-flex align-center" v-if="topLeftLogoFileURL">
                                        <v-card style="max-width: 140px;" class="pa-3 justify" elevation="0" color="grey lighten-4">
                                            <v-img max-height="40"  :src="topLeftLogoFileURL" contain></v-img>
                                        </v-card>
                                        <v-btn class="ml-1" elevation="0" min-width="40" height="40" @click="removeTopLogo">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </div>
                                    <div class="d-flex flex-column flex-grow-1" v-else>
                                        <v-file-input
                                            accept="image/png, image/jpeg, image/bmp, image/gif"
                                            placeholder="Click to add a logo file"
                                            outlined
                                            @change="handleAddTopLogo"
                                            dense
                                            prepend-inner-icon="mdi-file"
                                            prepend-icon=""
                                            hide-details="auto"
                                            class="text-caption"
                                            :disabled="!allowHidingBranding"
                                            :rules="[requiredTopLogoRule]"
                                            validate-on-blur
                                        ></v-file-input>
                                        <div class="text-caption grey--text mt-1">280px x 80px</div>
                                    </div>
                                    <v-text-field validate-on-blur clearable :rules="[linkRule]" :disabled="!allowHidingBranding" hide-details="auto" class="text-caption ml-4" dense outlined placeholder="Link to website e. g. https://yourwebsite.com" prepend-inner-icon="mdi-open-in-new" v-model="topLeftLogoLink"></v-text-field>
                                </div>
        
                                <div class="d-flex align-center mb-2">
                                    <v-tooltip right bottom max-width="250">
        
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon small left v-on="on" v-bind="attrs">mdi-information-outline</v-icon>
                                        </template>
                                        <span class="text-caption">
                                            The logo will be displayed in the footer of the email. If not added, no logo will be shown. The logo width is 140px and height is adjusted automatically.
                                        </span>
                                    </v-tooltip>
                                    <div class="text-subtitle-2">Footer Logo</div>
                                </div>
        
                                <div class="d-flex mb-3">
                                    <div class="d-flex align-center" v-if="footerLogoFileURL">
                                        <v-card style="max-width: 140px;" class="pa-3 justify" elevation="0" color="grey lighten-4">
                                            <v-img max-height="40"  :src="footerLogoFileURL" contain></v-img>
                                        </v-card>
                                        <v-btn class="ml-1" elevation="0" min-width="40" height="40" @click="removeFooterLogo">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </div>
                                    <div class="d-flex flex-column flex-grow-1" v-else>
                                        <v-file-input
                                            accept="image/png, image/jpeg, image/bmp, image/gif"
                                            placeholder="Click to add a logo file"
                                            outlined
                                            @change="handleAddFooterLogo"
                                            dense
                                            prepend-inner-icon="mdi-file"
                                            prepend-icon=""
                                            :loading="uploadingFooterLogo"
                                            hide-details="auto"
                                            class="text-caption"
                                            :disabled="!allowHidingBranding"
                                            :rules="[requiredFooterLogoRule]"
                                            validate-on-blur
                                        ></v-file-input>
                                        <div class="text-caption grey--text mt-1">280px x 80px</div>
                                    </div>
                                    <v-text-field validate-on-blur clearable :rules="[linkRule]" :disabled="!allowHidingBranding" hide-details="auto" class="text-caption ml-4" dense outlined placeholder="Link to website e. g. https://yourwebsite.com" prepend-inner-icon="mdi-open-in-new" v-model="footerLogoLink"></v-text-field>
                                </div>
                            </upgrade-section>
                        </v-form>
                        
                        <div class="pt-5"></div>
                        <v-card outlined class="pa-4 test-section" elevation="0">
                            <div class="text-subtitle-2 mb-2">
                                <v-icon>mdi-test-tube</v-icon>
                                Test confirmation email
                            </div>
                            <v-text-field validate-on-blur ref="testingEmailInput" :rules="emailRules" type="email" hide-details="auto" class="text-body-2 mb-3" dense placeholder="Type your email address" outlined v-model="testingEmail"></v-text-field>
                            <v-btn @click="sendTestEmail" dark small :disabled="sendingTestEmail" :loading="sendingTestEmail" elevation="0" color="grey darken-1">
                                Send Test Email
                                <v-icon right>mdi-send</v-icon>
                            </v-btn>
                        </v-card>
                        <div class="pt-5"></div>
                    </div>
                </v-card>
            </v-dialog>
        </v-card>
    </v-hover>
    

</template>

<script>
import ColorPicker from './design/ColorPicker.vue'
import { uploadLogo } from '@/api/survey';
import UpgradeSection from '../../../../components/UpgradeSection.vue';
import { mapGetters, mapMutations } from 'vuex';
import BuilderApi from '@/api/BuilderApi';
export default {
    components: { ColorPicker, UpgradeSection },
    name: "SubmissionConfirmationEmail",
    inject: ['survey', 'refreshSurvey'],
    data() {
        return {
            send_submission_confirmation_email: false,
            dialog: false,
            subject: 'Submission Confirmation',
            message: 'Your Voiceform was successfully submitted. Thank you for your time!',
            voiceformBranding: true,
            includeAISummary: true,
            bannerColor: '#2341E0',
            topLeftLogoFileURL: null,
            topLeftLogoLink: null,
            footerLogoFileURL: null,
            footerLogoLink: null,
            testingEmail: '',
            uploadingTopLogo: false,
            uploadingFooterLogo: false,
            requiredRule: v => !!v || 'Required',
            nonEmptyStringRule: v => (v && v.trim().length > 0) || 'Cannot be empty or just spaces',
            requiredTopLogoRule: v => !this.topLeftLogoLink || (v && v.trim().length > 0) || 'The logo is required if the link is provided.',
            requiredFooterLogoRule: v => !this.footerLogoLink || (v && v.trim().length > 0) || 'The logo is required if the link is provided.',
            linkRule: value => {
                const pattern = /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/;
                return !value || pattern.test(value) || 'Invalid link'
            },
            emailRules: [
                value => !!value || 'Required',
                value => /.+@.+\..+/.test(value) || 'E-mail must be valid',
            ],
            sendingTestEmail: false,
            savingSettings: false,
            valid: true
        }
    },
    computed: {
        ...mapGetters({
            features: 'auth/features'
        }),
        allowHidingBranding(){
            return !!this.features && this.features.remove_branding
        },
        aiSummaryFeature(){
            return !!this.features && this.features.response_summarization
        },
        aiSummary: {
            get(){
                return this.aiSummaryFeature? this.includeAISummary : false
            },
            set(value){
                this.includeAISummary = value
            }
        }
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        openDialog(){
            // populate data from survey

            if(this.survey.send_submission_confirmation_email){
                this.send_submission_confirmation_email = this.survey.send_submission_confirmation_email
            }

            if(this.survey.submission_confirmation_settings){
                const settings = this.survey.submission_confirmation_settings
                this.subject = typeof settings.subject === 'string'? settings.subject : 'Submission Confirmation'
                this.message = typeof settings.message === 'string'? settings.message : 'Your Voiceform was successfully submitted. Thank you for your time!'
                if(this.allowHidingBranding){
                    this.voiceformBranding = typeof settings.voiceformBranding === 'boolean'? settings.voiceformBranding : true
                }else{
                    this.voiceformBranding = true
                }
                this.includeAISummary = typeof settings.includeSummary === 'boolean'? settings.includeSummary : true
                this.bannerColor = typeof settings.coverBackgroundColor === 'string'? settings.coverBackgroundColor : '#2341E0'
                this.topLeftLogoFileURL = typeof settings.topCustomLogoUrl === 'string'? settings.topCustomLogoUrl : null
                this.topLeftLogoLink = typeof settings.topCustomLogoLink === 'string'? settings.topCustomLogoLink : null
                this.footerLogoFileURL = typeof settings.footerCustomLogoUrl === 'string'? settings.footerCustomLogoUrl : null
                this.footerLogoLink = typeof  settings.footerCustomLogoLink === 'string'? settings.footerCustomLogoLink : null
            }

            this.sendingTestEmail = false
            this.savingSettings = false
            this.valid = true

            this.dialog = true
        },
        async handleAddTopLogo(file){
            try {
                if(file){
                    this.uploadingTopLogo = true
                    const res = await uploadLogo(file, this.survey.hash)
                    if(res && res.data){
                        this.topLeftLogoFileURL = res.data.url
                    }
                }
            } catch (error) {
                console.error('error in handleAddLogo', error);
            } finally {
                this.uploadingTopLogo = false
            }
        },
        async handleAddFooterLogo(file){
            try {
                if(file){
                    this.uploadingFooterLogo = true
                    const res = await uploadLogo(file, this.survey.hash)

                    if(res && res.data){
                        this.footerLogoFileURL = res.data.url
                    }
                }
            } catch (error) {
                console.error('error in handleAddLogo', error);
            } finally {
                this.uploadingFooterLogo = false
            }
        },
        removeTopLogo(){
            this.topLeftLogoFileURL = null;
        },
        removeFooterLogo(){
            this.footerLogoFileURL = null;
        },
        async saveSettings(){
            try {
                this.savingSettings = true
                
                // validate customization form
                const validForm = this.$refs.emilCustomizationForm.validate();
                if(!validForm){
                    return this.showSnackBar({
                        text: "Please check if all fields are filled correctly.", 
                        color: 'error', 
                        timeout: 2000
                    })
                }

                const settingsPayload = {
                    send_submission_confirmation_email: this.send_submission_confirmation_email,
                    submission_confirmation_settings: {
                        subject: this.subject,
                        message: this.message,
                        voiceformBranding: this.voiceformBranding,
                        includeSummary: this.includeAISummary,
                        coverBackgroundColor: this.bannerColor,
                        topCustomLogoUrl: this.topLeftLogoFileURL,
                        topCustomLogoLink: this.topLeftLogoLink,
                        footerCustomLogoUrl: this.footerLogoFileURL,
                        footerCustomLogoLink: this.footerLogoLink,
                    }
                }

                const res = await BuilderApi.updateSubmissionConfirmationEmailSettings(this.survey.id, settingsPayload)
                if(res && res.data){
                    this.showSnackBar({
                        text: "Settings saved successfully.", 
                        color: 'success', 
                        timeout: 2000
                    })

                    this.refreshSurvey();

                    this.dialog = false
                }

                
            } catch (error) {

                this.showSnackBar({
                    text: "Error saving settings.", 
                    color: 'error', 
                    timeout: 2000
                })
                console.error('error in saveConfirmationEmailSettings', error);
                
            }finally{
                this.savingSettings = false
            }
        },
        async sendTestEmail(){
            try {
                this.sendingTestEmail = true
                const validEmail = this.$refs.testingEmailInput.validate()
                if(!validEmail){
                    return this.showSnackBar({
                        text: "Invalid testing email address.", 
                        color: 'error', 
                        timeout: 2000
                    })
                }

                // validate customization form
                const validForm = this.$refs.emilCustomizationForm.validate();
                if(!validForm){
                    return this.showSnackBar({
                        text: "Please check if all fields are filled correctly.", 
                        color: 'error', 
                        timeout: 2000
                    })
                }



                // validate testing email
                const res = await BuilderApi.sendConfirmationTestEmail(this.survey.id, {
                    'toEmail': this.testingEmail,
                    'subject': this.subject,
                    'message': this.message,
                    'voiceformBranding': this.voiceformBranding,
                    'includeSummary': this.includeAISummary,
                    'coverBackgroundColor': this.bannerColor,
                    'topCustomLogoUrl': this.topLeftLogoFileURL,
                    'topCustomLogoLink': this.topLeftLogoLink,
                    'footerCustomLogoUrl': this.footerLogoFileURL,
                    'footerCustomLogoLink': this.footerLogoLink,
                })
                if(res && res.data && res.data.sent === true){
                    this.showSnackBar({
                        text: "Test email sent successfully.", 
                        color: 'success', 
                        timeout: 2000
                    })
                }
            } catch (error) {
                this.showSnackBar({
                    text: "Error sending test email.", 
                    color: 'error', 
                    timeout: 2000
                })
                console.error('error in sendTestEmail', error);
            }finally{
                this.sendingTestEmail = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.confirmation-card{
    position: relative;
}
.edit-icon{
    position: absolute;
    top: 0;
    right: 0;
}
.blocked-section-wrapper{
    display: flex;
    flex: 1;
}
.test-section{
    border: 1px dashed var(--v-grey-base) !important;
    border-style: dashed;
}
</style>