
    
<template>
    <BubbleMenu
        :editor="editor"
        :pluginKey="pluginKey"
        :shouldShow="handleShouldShow"
        :updateDelay="0"
        :tippyOptions="tippyOptions"
    >
        <v-toolbar class="d-flex align-center" dense rounded height="40" ref="menuRef">
            <v-btn-toggle class="mr-2" mandatory dense v-model="alignment">
                <v-btn value="left" small icon>
                    <v-icon small>mdi-format-horizontal-align-left</v-icon>
                </v-btn>
                <v-btn value="center" small icon>
                    <v-icon small>mdi-format-horizontal-align-center</v-icon>
                </v-btn>
                <v-btn value="right" small icon>
                    <v-icon small>mdi-format-horizontal-align-right</v-icon>
                </v-btn>
            </v-btn-toggle>
            <div class="d-flex align-center">
                <v-slider class="ma-0 pa-0 mr-2 text-caption" hide-details="auto" style="min-width: 100px;" :value="imageBlockWidth" step="10" :thumb-label="false" thumb-size="24" min="10" max="100" @input="onWidthChange" />
                <span style="width: 30px;" class="text-caption">{{ imageBlockWidth }}%</span>
            </div>
        </v-toolbar>
    </BubbleMenu>
</template>

<script>
import { BubbleMenu } from '@tiptap/vue-2'
import { sticky } from 'tippy.js'
import { v4 as uuid } from 'uuid'

export default {
    name: 'ImageBlockMenu',
    components: {
        BubbleMenu,
    },
    props: {
        editor: {
            type: Object,
            required: true,
        },
        appendTo: {
            default: () => document.body,
        },
    },
    data() {
        return {
            tippyInstance: null,
            menuRef: null
        }
    },
    mounted() {
        this.menuRef = this.$refs.menuRef
    },
    computed: {
        imageBlockWidth(){
            return parseInt(this.editor.getAttributes('imageBlock').width) || 100
        },
        alignment: {
            get() {
                return this.editor.getAttributes('imageBlock').align || 'center'
            },
            set(value) {
                this.editor.chain().focus(undefined, { scrollIntoView: false }).setImageAlign(value).run()
            },
        },
        pluginKey() {
            return `imageBlockMenu-${uuid()}`
        },
        tippyOptions() {
            return {
                offset: [0, 8],
                popperOptions: {
                    modifiers: [{ name: 'flip', enabled: false }],
                },
                getReferenceClientRect: this.getReferenceClientRect,
                onCreate: (instance) => {
                    this.tippyInstance = instance
                },
                plugins: [sticky],
                sticky: 'popper',
            }
        },
    },
    methods: {
        getReferenceClientRect() {
            const renderContainer = this.getRenderContainer(this.editor, 'imageBlock')
            const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0)
            return rect
        },
        handleShouldShow() {
            return this.editor.isActive('imageBlock')
        },
        onWidthChange(value) {
            this.editor.chain().focus(undefined, { scrollIntoView: false }).setImageWidth(value).run()
        },
        getRenderContainer(editor, nodeName) {
            const { view } = editor
            const { state } = view
            const { doc, selection } = state
            const { from, to } = selection

            let nodeContainer = null

            doc.nodesBetween(from, to, (node, pos) => {
                if (node.type.name === nodeName) {
                    const domNode = view.nodeDOM(pos)
                    if (domNode) {
                        nodeContainer = domNode
                    }
                }
            })

            return nodeContainer
        },
    },
}
</script>
