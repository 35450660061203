<template>
    <div class="d-flex flex-grow-1 justify-center justify-sm-start">
        <v-rating
            v-if="icon.name === 'number'"
            v-model="answer"
            :background-color="styles.button.background"
            :length="choices.length"
            class="d-flex flex-wrap justify-center"
            hover
        >
            <template v-slot:item="props">
                <v-btn
                    elevation="0"
                    :ripple="false"
                    @click="props.click"
                    :color="props.isFilled ? styles.button.background : 'transparent'"
                    class="my-2 mr-4"
                    :style="`min-width: 48px;
                             min-height: ${$vuetify.breakpoint.xs ? 64 : 48}px;
                             color: ${props.isFilled ? styles.button.color : styles.button.background}; 
                             opacity: 0.8;
                             border: 1px solid ${styles.button.background} !important`"
                >
                    {{props.index + 1}}
                </v-btn>
            </template>
        </v-rating>
        <v-rating
            class="icon-rating"
            v-else
            v-model="answer"
            :empty-icon="icon.empty"
            :full-icon="icon.full"
            :dark="styles.dark"
            :color="styles.button.background"
            :background-color="styles.button.background"
            medium
            :length="choices.length"
            :size="$vuetify.breakpoint.xs ? 32 : 40"
        >
        </v-rating>
    </div>
</template>

<script>
export default {
    name: "Rating",
    props: {
        value: {
            required: true,
        },
        question: {
            required: true,
        },
        styles: {
            required: true,
        },
    },

    computed: {
        choices() {
            return this.question.multipleChoiceItems.slice().reverse();
        },
        icon() {
            if (this.question.multipleChoiceItems?.length) {
                switch (this.question.multipleChoiceItems[0].icon) {
                    case "number":
                        return {
                            name: "number",
                        };
                    case "heart":
                        return {
                            name: "heart",
                            full: "mdi-heart",
                            empty: "mdi-heart-outline",
                            color: this.styles.dark ? "red lighten-1" : "red",
                        };

                    case "user":
                        return {
                            name: "user",
                            full: "mdi-account",
                            empty: "mdi-account-outline",
                            color: this.styles.dark ? "pink lighten-1" : "pink",
                        };
                }
            }
            return {
                name: "star",
                full: "mdi-star",
                empty: "mdi-star-outline",
                color: this.styles.dark ? "yellow lighten-1" : "yellow darken-2",
            };
        },
        answer: {
            get: function () {
                return this.value ? this.value.value : 0;
            },
            set: function (val) {
                this.$emit("input", { type: "number", value: val });
                this.$emit("change");
            },
        },
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.container {
    display: flex;
}
.icon-rating{
    display: flex;
    flex-wrap: wrap;
}
</style>
