<template>
    <div class="d-flex flex-column">
        <v-card
            v-for="(theme, i) in themes" :key="i"
            outlined
            :class="{
                'elevation-4': isSelect(theme),
            }"
            max-width="300"
            class="mb-8"
            @click="handleThemeSelect(theme)"
        >
            <v-card-text
                class="d-block px-4 pb-4 pt-10"
                :style="{ background: theme.style.background }"
            >
                <div class="button-placeholder" :style="{
                        background: theme.style.button.background,
                }"></div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-title class="text-body-1">{{
                theme.title
            }}</v-card-title>
        </v-card>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import BuilderApi from '../../../../../api/BuilderApi';
export default {
    name: "Themes",
    inject: ['survey', 'refreshSurvey'],
    methods: {
        ...mapActions({
            updateSurvey: "survey/updateSurvey",
            setSurvey: "survey/setSurvey",
        }),
        isSelect(theme) {
            return (
                JSON.stringify(this.survey.style) == JSON.stringify(theme.style)
            );
        },
        async handleThemeSelect(theme){
            this.survey.style = theme.style
            await BuilderApi.patchForm(this.survey.id, {style: theme.style})
            await this.refreshSurvey()
        }
    },
    data() {
        return {
            themes: [
                {
                    title: "Plain Black",
                    style: {
                        color: "#000000",
                        button: {
                            color: "#FFFFFF",
                            background: "#000000",
                        },
                        background: "#FFFFFF",
                    },
                },
                {
                    title: "Plain Green",
                    style: {
                        color: "#01D268",
                        button: {
                            color: "#FFFFFF",
                            background: "#01D268",
                        },
                        background: "#FFFFFF",
                    },
                },
                {
                    title: "Plain Blue",
                    style: {
                        color: "#0066FF",
                        button: {
                            color: "#FFFFFF",
                            background: "#0066FF",
                        },
                        background: "#FFFFFF",
                    },
                },
                {
                    title: "Blue and White",
                    style: {
                        color: "#FFFFFF",
                        button: {
                            color: "#0066FF",
                            background: "#FFFFFF",
                        },
                        background: "#0066FF",
                    },
                },
                {
                    title: "Black and Grey",
                    style: {
                        color: "#000000",
                        button: {
                            color: "#FFFFFF",
                            background: "#000000",
                        },
                        background: "#C3C8D0",
                    },
                },
                {
                    title: "Purple and White",
                    style: {
                        color: "#FFFFFF",
                        button: {
                            color: "#800080",
                            background: "#FFFFFF",
                        },
                        background: "#800080",
                    },
                },
                {
                    title: "Pink and White",
                    style: {
                        color: "#FFFFFF",
                        button: {
                            color: "#FA00FF",
                            background: "#FFFFFF",
                        },
                        background: "#FA00FF",
                    },
                },
                {
                    title: "Black and Yellow",
                    style: {
                        color: "#000000",
                        button: {
                            color: "#FFE01B",
                            background: "#000000",
                        },
                        background: "#FFE01B",
                    },
                },
                {
                    title: "Teal and White",
                    style: {
                        color: "#FFFFFF",
                        button: {
                            color: "#008080",
                            background: "#FFFFFF",
                        },
                        background: "#008080",
                    },
                },
                {
                    title: "Plain Red",
                    style: {
                        color: "#FF0000",
                        button: {
                            color: "#FFFFFF",
                            background: "#FF0000",
                        },
                        background: "#FFFFFF",
                    },
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
    .button-placeholder{
        width: 36px;
        height: 20px;
        border-radius: 4px;
    }
</style>
