<template>
  <v-card outlined>
      <div v-if="editor && toolbar" class="d-flex align-center flex-wrap">
          <v-btn-toggle
            color="primary"
            dense
            group
            multiple
            :value="activeFormats"
          >
            <v-btn
              value="bold"
              text
              @click.prevent="handleActionPress('bold')"
              small
            >
              <v-icon small>mdi-format-bold</v-icon>
            </v-btn>

            <v-btn
              value="italic"
              text
              @click.prevent="handleActionPress('italic')"
              small
            >
              <v-icon small>mdi-format-italic</v-icon>
            </v-btn>

            <v-btn
              value="underline"
              text
              @click.prevent="handleActionPress('underline')"
              small
            >
              <v-icon small>mdi-format-underline</v-icon>
            </v-btn>

            <v-btn
              value="highlight"
              text
              @click.prevent="handleActionPress('highlight')"
              small
            >
              <v-icon small>mdi-marker</v-icon>
            </v-btn>
          </v-btn-toggle>

          <v-btn-toggle
            color="primary"
            dense
            group
            mandatory
            :value="activeAlignment"

          >
            <v-btn
              text
              value="left" 
              @click.prevent="handleActionPress('left')"
              small
            >
              <v-icon small>mdi-format-align-left</v-icon>
            </v-btn>

            <v-btn
              text
              value="center" 
              @click.prevent="handleActionPress('center')"
              small
            >
              <v-icon small>mdi-format-align-center</v-icon>
            </v-btn>

            <v-btn
              text
              value="right" 
              @click.prevent="handleActionPress('right')"
              small
            >
              <v-icon small>mdi-format-align-right</v-icon>
            </v-btn>
          </v-btn-toggle>
          <v-btn small text @click.stop="addLinkClick" :color="editor && editor.isActive('link')?'primary': 'default'" >
              <v-icon small>mdi-link-variant</v-icon>
          </v-btn>
      </div>
      <div v-if="editor && !toolbar" class="menu-button">
        <v-btn @click.stop="addLinkClick" :color="editor && editor.isActive('link')?'primary': 'default'" medium icon >
          <v-icon>mdi-link-variant</v-icon>
        </v-btn>
      </div>
    <editor-content :editor="editor"/>
    <div v-if="editor && limit" class="counter">
      {{ editor.getCharacterCount() }}/{{ limit }} characters
    </div>
    <v-dialog
      v-model="dialog"
      max-width="400"
      @click:outside="cancelAddLinkClick"
    >
      <v-card>
        <v-card-title class="text-h5">Add Link</v-card-title>
        <v-container>
          <v-text-field v-show="selectedText" v-model="selectedText" disabled outlined label="Text"></v-text-field>
          <v-text-field placeholder="https://" v-model="url" outlined label="Link"></v-text-field>
        </v-container>
        <v-card-actions>
        <v-spacer></v-spacer>

          <v-btn
            outlined
            @click.stop="cancelAddLinkClick"
          >
            Cancel
          </v-btn>

          <v-btn
            :elevation="0"
            color="primary"
            @click.stop="saveAddLinkClick"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import CharacterCount from '@tiptap/extension-character-count'
import TextAlign from '@tiptap/extension-text-align'
import Highlight from '@tiptap/extension-highlight'
import Underline from '@tiptap/extension-underline'


const LinkReg = new RegExp("^(http|https)://", "i");

export default {
  components: {
    EditorContent,
  },

  props: {
    value: {
      type: String,
      default: '',
    },
    limit: {
      type: Number,
    },
    toolbar: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      editor: null,
      dialog: false,
      selectedText: '',
      activeUrl: '',
      url: '',
      previousValue: '',
      focused: false
    }
  },
  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = this.editor.getJSON().toString() === value.toString()

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
  },

  computed: {
    activeFormats() {
      const textFormat = []
      if(this.editor){
          if(this.editor.isActive('bold')){
            textFormat.push('bold')
          }
          if(this.editor.isActive('italic')){
            textFormat.push('italic')
          }
          if(this.editor.isActive('underline')){
            textFormat.push('underline')
          }
          if(this.editor.isActive('highlight')){
            textFormat.push('highlight')
          }
      }

      return textFormat
    },
    activeAlignment() {
      if(this.editor){
          if(this.editor.isActive({ textAlign: 'left' })){
            return 'left'
          }
          if(this.editor.isActive({ textAlign: 'center' })){
            return 'center'
          }
          if(this.editor.isActive({ textAlign: 'right' })){
            return 'right'
          }
          if(this.editor.isActive({ textAlign: 'justify' })){
            return 'justify'
          }
      }
      return ''
    },
  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Link.configure({
          openOnClick: false,
        }),
        this.limit && CharacterCount.configure({
          limit: this.limit,
        }),
        TextAlign.configure({
          types: ['heading', 'paragraph'],
          alignments: ['left', 'right', 'center', 'justify'],
        }),
        Highlight,
        Underline
      ],
      content: this.value,
      onSelectionUpdate: ({editor}) => {
        const { view, state } = editor
        const { from, to } = view.state.selection
        // TODO: if there is no selection, and only cursor, select a spesific word
        this.selectedText = state.doc.textBetween(from, to, '')
      },
      onBlur: () => {
        this.focused = false;
        if(this.previousValue !== this.editor.getHTML()){
          this.$emit('input', this.editor.getHTML())
          this.$emit('change')
        }
      },
      onFocus: () => {
        this.previousValue = this.value;
        this.focused = true;
      },
      onUpdate: () => {
        const html = this.editor.getHTML()
        this.$emit('input', html === '<p></p>'? '' : html)
      },
    })
  },

  beforeDestroy() {
    this.editor.destroy()
  },
  methods: {
    handleActionPress(action){

      switch (action) {
        case 'bold':
          this.editor.chain().focus().toggleBold().run()
          break;
        case 'italic':
          this.editor.chain().focus().toggleItalic().run()
          break;
        case 'underline':
          this.editor.chain().focus().toggleUnderline().run()
          break;
        case 'highlight':
          this.editor.chain().focus().toggleHighlight().run()
          break;
        case 'left':
          this.editor.chain().focus().setTextAlign('left').run()
          break;
        case 'center':
          this.editor.chain().focus().setTextAlign('center').run()
          break;
        case 'right':
          this.editor.chain().focus().setTextAlign('right').run()
          break;
        case 'justify':
          this.editor.chain().focus().setTextAlign('justify').run()
          break;
        default:
          break;
      }
      if(this.previousValue !== this.editor.getHTML()){
        this.$emit('change')
      }

    },
    saveAddLinkClick(){
      this.setLink()
      this.dialog = false
      this.$emit('change')
      return this.editor.chain().focus()
    },
    cancelAddLinkClick(){
      this.resetValues()
      this.dialog = false
      return this.editor.chain().focus()
    },
    addLinkClick(){
      this.resetValues()
      if(!this.editor.isActive('link') && !this.selectedText){
        return this.editor.chain().focus()
      }

      if(this.editor.isActive('link')){
        const previousUrl = this.editor.getAttributes('link').href
        this.activeUrl = previousUrl
        this.url = previousUrl
      }

      return this.dialog = true
    },
    resetValues(){
      this.url = ""
      this.activeUrl = ""
    },
    setLink() {

      // empty
      if (this.url === '') {
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run()

        return
      }

      if(this.url && this.url.trim() && !LinkReg.test(this.url)){
        this.url = `http://${this.url}`
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: this.url })
        .run()
    }
  },
}
</script>

<style lang="scss">
  .menu-button {
    padding: 8px;
  }
  .counter{
    padding: 8px;
    font-size: 14px;
  }
</style>


