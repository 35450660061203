<template>
    <div class="d-flex flex-column">
        <v-expand-transition>
            <div v-if="showHintText" class="text-caption mb-4">
                {{customLabels.hint_text || 'Select how you want to answer...'}}
            </div>
        </v-expand-transition>
        <div class="d-flex flex-row audio-record flex-grow-1">
            <div v-if="showAudio" class="d-flex flex-column">
                <div class="d-flex flex-column align-center justify-center flex-sm-row justify-sm-start"
                    :style="{ color: styles.backgroundTextColor }">
                    <div class="d-flex flex-column align-center" style="cursor:not-allowed !important;">
                        <div style="min-height: 24">&nbsp;</div>
                        <v-tooltip :position-y="-40" bottom>
                            <div class="text-caption">
                                Recording is disabled in preview
                            </div>
                            <template v-slot:activator="{ on, attrs }">
                                <div class="relative-container" v-bind="attrs" v-on="on">
                                    <v-btn style="cursor:not-allowed !important;" :color="styles.button.background" fab dark elevation="0" width="80"
                                        height="80">
                                        <v-icon :color="styles.button.color" size="31">mdi-microphone</v-icon>
                                    </v-btn>
                                    <div class="progress">
                                        <v-progress-circular rotate="270" :value="0" size="100"
                                            :color="styles.backgroundTextColor"></v-progress-circular>
                                    </div>
                                </div>
                            </template>
                        </v-tooltip>
                        <div class="text-caption mt-2 helper-text">
                            <v-icon :color="styles.button.background" size="14">mdi-lightning-bolt</v-icon>
                            {{ customLabels.hit_record || 'Hit Record' }}
                        </div>
                    </div>
                </div>
            </div>
            <v-fade-transition hide-on-leave>
                <div v-if="showTextInput || error" class="d-flex flex-grow-1 flex-row">

                    <div v-if="showOR" class="divider flex-column justify-center mx-3">
                        <v-divider class="divider-item" :vertical="true" :dark="styles.dark"></v-divider>
                        <span class="divider-label" :style="{ color: styles.backgroundTextColor }">{{customLabels.or_divider || 'or'}}</span>
                        <v-divider class="divider-item" :vertical="true" :dark="styles.dark"></v-divider>
                    </div>
                    <div class="d-flex flex-column justify-center" v-if="!textOptionSelected">
                        <div class="d-flex align-center justify-center" style="width: 100px; height: 100px">
                            <v-btn fab elevation="0" :color="styles.dark ? '#ffffff46' : '#00000010'" width="80" height="80"
                                @click="handleTextOptionSelection">
                                <v-icon :style="{ color: styles.backgroundTextColor }">mdi-form-textbox</v-icon>
                            </v-btn>
                        </div>
                        <div class="text-caption mt-2 helper-text">{{customLabels.text_input_label || 'Type answer'}}</div>
                    </div>
                    <v-textarea v-show="textOptionSelected" v-model="textInput" clearable clear-icon="mdi-close-circle"
                        outlined placeholder="Type your answer" rows="1" auto-grow :dark="styles.dark" @focus="handleFocus"
                        @focusin="textInputFocus = true" @blur="handleTextInputBlur" :hide-details="!showCharactersCounter"
                        validate-on-blur class="desktop-input" ref="textInput" :rules="[rules.textInput]"
                        :messages="counterMessage" :loading="showCharactersCounter && progressValue">
                        <template v-slot:progress>
                            <v-progress-linear :value="progressValue" :color="progressColor" absolute height="4"
                                rounded></v-progress-linear>
                        </template>
                    </v-textarea>
                </div>
            </v-fade-transition>
        </div>
    </div>
</template>

<script>
import microphone from "@/assets/svg/microphone.svg";
import { mapGetters, mapActions } from "vuex";
import AudioRecorder from '../../../views/survey/components/audio-recorder/AudioRecorder.vue';


export default {
    name: "VoiceResponse",
    components: { AudioRecorder },
    props: {
        value: {
            required: true,
        },
        question: {
            required: true,
        },
        survey: {
            required: true,
        },
        styles: {
            required: true
        },

    },
    data() {
        return {
            stream: null,
            recorder: null,
            audio: null,
            microphone,
            error: null,
            textOptionSelected: false,
            textInputFocus: false,
            rules: {
                textInput: this.validateTextInput,
            },
        };
    },
    computed: {
        answer: {
            get() {
                if (this.value && this.value.type === 'audio') {
                    return this.value.url || this.value;
                }
                return null
            },
            set(value) {
                if (value) {
                    this.$emit("input", { type: "audio", ...value });
                } else {
                    this.$emit("input", null);
                }
                this.$emit("change");
            },
        },
        textInput: {
            get() {
                return this.value && this.value.type === 'text' ? this.value.value : "";
            },
            set(value) {
                this.$emit("input", value ? { answer: { type: "text", value }, valid: this.validateTextInput(value) } : null);
                this.$emit("change");
            },
        },
        ...mapGetters({
            permissions: 'audio/permissions',
            permissionsError: 'audio/permissionsError',
            uploadingRecording: 'audio/uploadingRecording'
        }),
        showTextInput: function () {
            return (this.permissionsError === true || this.permissions === false || !!this.question.enable_text_input) && !this.answer
        },
        showAudio() {
            return !this.textInputFocus && !this.textInput
        },
        showOR() {
            return !this.textInput && !this.textInputFocus && !this.answer
        },
        showHintText() {
            if (!this.textOptionSelected && this.question.enable_text_input && !this.answer && !this.uploadingRecording && !this.value && !this.textInput) {
                return true
            }
            return false
        },
        minNumberOfCharacters() {
            return this.question?.properties?.minNumberOfCharacters || 0
        },
        showCharactersCounter() {
            return !!(this.minNumberOfCharacters && (this.textInputFocus || this.textInput))
        },
        counterMessage() {
            if (this.showCharactersCounter) {
                if (!this.textInput.trim().length) {
                    return `Please enter at least ${this.question.properties.minNumberOfCharacters} characters`
                }

                return `${this.textInput.trim().length} characters (minimum ${this.question.properties.minNumberOfCharacters} characters)`
            }
            return ''
        },

        progressValue() {
            if (this.showCharactersCounter && this.textInput && this.textInput.trim()) {
                return this.textInput.trim().length / this.question.properties.minNumberOfCharacters * 100
            }
            return 0
        },
        progressColor() {
            if (this.progressValue < 50) {
                return this.styles.dark ? 'red lighten-3' : 'red'
            }

            if (this.progressValue < 75) {
                return this.styles.dark ? 'orange lighten-3' : 'orange'
            }

            if (this.progressValue < 100) {
                return this.styles.dark ? 'yellow lighten-3' : 'yellow'
            }

            return this.styles.dark ? 'green lighten-3' : 'green'
        },
        customLabels() {
            return this.question?.properties?.custom_labels || {}
        }
    },
    methods: {
        ...mapActions({
            stopRecording: "audio/stopRecording",
            startRecording: "audio/startRecording",
            startLoading: "audio/startLoading",
            stopLoading: "audio/stopLoading",
            startStreamInitLoading: "audio/startStreamInitLoading",
            stopStreamInitLoading: "audio/stopStreamInitLoading",
        }),
        // Virtual keyboard pushes the HTML page up in iPhone
        handleFocus() {

            window?.heap?.track('Select Text Option');

            // scroll to top
            if (/iPhone/i.test(navigator.userAgent)) {
                setTimeout(() => {
                    window.scrollTo(0, 0);
                }, 300);
            }
        },
        handleTextOptionSelection() {
            this.textOptionSelected = true
            setTimeout(() => this.$refs?.textInput?.focus(), 10);
        },
        handleTextInputBlur() {
            if (!this.textInput) {
                this.textOptionSelected = false
            }
            this.textInputFocus = false
        },
        validateTextInput(value) {
            if (this.showCharactersCounter) {
                return !value || value.trim().length >= this.question?.properties?.minNumberOfCharacters || `${value.trim().length} characters (minimum ${this.question.properties.minNumberOfCharacters} characters)`
            }
            return true
        },

    },
    mounted() {
        if (this.textInput) {
            this.textOptionSelected = true
        }
    }
};
</script>

<style lang="scss" scoped>
.text-label {
    padding-top: 8px;
    font-size: 16px;
    text-align: center;
}

.desktop-input {
    align-items: center;
}

.divider {
    display: flex;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 32px;

    &-label {
        margin-left: 8px;
        margin-right: 8px;
    }
}

.divider::v-deep .v-divider--vertical {
    align-self: unset;
    min-height: unset;
}

.audio-record {
    justify-items: center;
    width: 100%;
    min-height: 170px;

    .audio {
        width: 100%;
    }
}

.helper-text {
    opacity: 0.7;
    text-align: center;
}

.relative-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    min-height: 100px;
    touch-action: manipulation;
}

.progress {
    position: absolute;
    pointer-events: none;
}
</style>
