<template>
    <td :key="`cell_${row.id}_${column.id}`" ref="cell" :id="`cell_${row.id}_${column.id}`" :class="['cell', {'selected-column': selectedColumn, 'linked-column': linkedColumn, 'selected-row': selectedRow}]" @click="handleClick" @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
        :style="{'min-width': column.width + 'px !important'}"
    >
        <div class="disabled-area">
            <template v-if="type === 'checkbox'">
                <v-checkbox hide-details="auto" class="ma-0 pa-0" dense :name="`row-${row.id}`"></v-checkbox>
            </template>
            <template v-else-if="type === 'radio'">
                <v-radio hide-details="auto" class="ma-0 pa-0" dense :name="`row-${row.id}`"></v-radio>
            </template>
            <template v-else-if="type === 'text'">
                <v-text-field class="ma-0 pa-0 text-caption" :placeholder="column.properties.field_placeholder"  style="min-width: 200px;" hide-details="auto" dense outlined></v-text-field>
            </template>
            <template v-else-if="type === 'numeric'">
                <v-text-field  class="ma-0 pa-0 text-caption" :prefix="column.properties.prefix || ''" :suffix="column.properties.suffix || ''" :placeholder="column.properties.field_placeholder || ''"  style="min-width: 200px;" hide-details="auto" dense outlined></v-text-field>
            </template>
            <template v-else-if="type === 'dropdown'">
                <v-select class="ma-0 pa-0 text-caption"  style="min-width: 200px;" hide-details="auto"  dense outlined></v-select>
            </template>
        </div>
        <resize-column-width @resizeDragging="resizeDragging" @resizeStopDragging="stopResizeDragging"></resize-column-width>
    </td>
</template>

<script>
import ResizeColumnWidth from './ResizeColumnWidth.vue'
export default {
  components: { ResizeColumnWidth },
    props: {
        row: {
            required: true,
        },
        column: {
            required: true,
        },
        columnIndex: {
            required: true,
        },
        selections: {
            required: true,
        },
        columnSelections: {
            required: true,
        },
        linkedColumns: {
            required: true,
        },
        rowsSelections: {
            required: true,
        },
        hoveredColumn: {
            required: true,
        },
        hoveredRow: {
            required: true,
        },
    },
    computed: {
        type(){
            return this.row.type || this.column.type
        },
        selectedColumn(){
            return !!this.columnSelections[this.column.id] || this.hoveredColumn === this.column.id
        },
        selectedRow(){
            return !!this.rowsSelections[this.row.id] || this.hoveredRow === this.row.id
        },
        linkedColumn(){
            return !!this.linkedColumns[this.column.id] 
        },
    },
    methods: {
        handleClick(){
            this.$emit('click', this.row.id, this.column.id)
        },
        handleMouseEnter(){
            this.$emit('mouseenter', this.row.id, this.column.id)
        },
        handleMouseLeave(){
            this.$emit('mouseleave', this.row.id, this.column.id)
        },
        resizeDragging(delta){
            this.$emit('resizeDragging', this.column.id, delta, this.columnIndex)
        },
        stopResizeDragging(){
            this.$emit('stopResizeDragging', this.column.id, this.columnIndex)
        }
    },
}
</script>


<style lang="scss" scoped>
.cell{
    cursor: pointer;
    position: relative;
}

.selected-column:after {
    content: '';
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    border-left: 1px solid var(--v-primary-base);
    border-right: 1px solid var(--v-primary-base);
    background-color: rgba(0, 102, 255, 0.1);
}

.selected-row:after {
    content: '';
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    border-top: 1px solid var(--v-primary-base);
    border-bottom: 1px solid var(--v-primary-base);
    background-color: rgba(0, 102, 255, 0.1);
}

.linked-column:after {
    content: '';
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    border-left: 1px solid var(--v-deepPurple-base) !important;
    border-right: 1px solid var(--v-deepPurple-base) !important;
    background: repeating-linear-gradient(
        -45deg,
        rgba(187, 0, 255, 0.1),
        rgba(187, 102, 255, 0.1) 10px,
        rgba(187, 102, 255, 0.2) 10px,
        rgba(187, 102, 255, 0.2) 20px
    ) !important;
}

.cell-hover:after{
    content: '';
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    border: 1px solid var(--v-deepOrange-base);
}

.disabled-area{
    pointer-events: none;
}
input[type="text"] {
    width: 100%;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
</style>