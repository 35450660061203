<template>
    <v-app-bar v-if="!hideFullBar" :dark="styles.dark" height="62" class="header" absolute elevation="0" :style="headerStyles">
        <v-progress-linear v-if="!hideProgressBar" :color="styles.button.background" top fixed :value="answersCompletion"></v-progress-linear>
        <v-container class="pa-0">
            <v-row no-gutters align="center">
                <div class="d-flex flex-0">
                    <img
                        v-if="survey.top_left_file_url"
                        height="40px"
                        class="logo-image"
                        :src="survey.top_left_file_url"
                    />
                </div>
                <v-spacer></v-spacer>
                <v-fade-transition>
                    <div v-if="!hideProgressBar && showQuestionsCounter" class="text-body-2 text-sm-body-1 header-questions-counter">
                        <span v-if="survey.has_logic || survey.settings.percentage_progress">{{ answersCompletion }}% completed</span>
                        <span v-else>{{ questionNumber }}/{{ survey.questions_count }} questions</span>
                    </div>
                </v-fade-transition>
            </v-row>
        </v-container>
    </v-app-bar>
</template>

<script>
export default {
    props: {
        styles: {
            required: true
        },
        survey: {
            required: true
        },
        stepProperties: {
            required: true
        }
    },
    
    computed: {
        hideFullBar(){
            return !this.survey.top_left_file_url && this.hideProgressBar
        },
        hideProgressBar() {
            return this.survey && this.survey.settings && this.survey.settings.is_progressbar_hidden;
        },
        questionNumber(){
            return this.stepProperties.order + 1
        },
        headerStyles(){
            return `color: ${this.styles.backgroundTextColor} ; background-color: ${this.styles.background}; border-bottom: ${this.styles.backgroundTextColor}33 solid 1px;`
        },
        showQuestionsCounter(){
            return this.stepProperties.component === 'QuestionStep' && !this.submissionStatus
        },
        answersCompletion(){
            if(this.survey.questions_count){
                return Math.round(this.questionNumber /this.survey.questions_count * 100);
            }
            return 0
        }
    }
}
</script>

<style lang="scss" scoped>
.header{
    &-questions-counter{
        text-align: right;
    }
}
 .logo-image{
     width: unset;
     height: 40px
 }

</style>