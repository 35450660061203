<template>
    <div class="wrapper-container" :style="wrapperStyles" v-bind="$attrs"
        v-on="$listeners">
            <slot></slot>
        </div>
</template>
  
<script>
export default {
    data() {
        return {
            wrapperStyles: {
                position: 'relative',
            },
        };
    },
    mounted() {
        this.$el.childNodes.forEach((child) => {
            if (child.nodeType === Node.ELEMENT_NODE) {
                child.style.pointerEvents = 'none';
            }
        });
    },
};
</script>

<style lang="scss" scoped>
.wrapper-container{
    cursor: not-allowed;
    width: fit-content;
}
</style>