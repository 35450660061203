<template>
    <v-expansion-panels style="margin-left: 1px; margin-right: 1px;" flat>
        <v-expansion-panel>
            <v-expansion-panel-header color="grey lighten-5">
                
                <div class="d-flex align-center">
                    Logic Actions
                    <v-badge class="ml-1" inline color="primary" :value="actionsCount" :content="actionsCount"></v-badge>
                </div>
            
            </v-expansion-panel-header>
            <v-expansion-panel-content class="actions-panel">
                <div class="actions-container">
                    <div class="pa-4" v-if="loading">
                        <div v-for="key in 5" :key="`placeholder-${key}`" class="my-2">
                            <skeleton-loader-vue type="rect" :width="310" :height="30" animation="fade" rounded
                                radius="4" />
                        </div>
                    </div>
                    <QuestionNodeProperties hideHeader v-else-if="questionNode" :questionNode="questionNode" />
                </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import QuestionNodeProperties from '../../logic/QuestionNodeProperties.vue';
import { mapActions, mapGetters } from 'vuex';
export default {
    name: "Actions",
    inject: ['question'],
    components: {
        QuestionNodeProperties
    },
    data() {
        return {
            loading: true
        }
    },
    methods: {
        ...mapActions({
            initActionsFromSurvey: 'logic/initActionsFromSurvey'
        }),
    },
    computed: {
        ...mapGetters({
            questions: 'logic/questions',
        }),
        actionsCount() {
            return this.actions ? this.actions.length : 0
        },
        actions(){
            if(this.questions && this.question){
                const questionWithLogic = Array.isArray(this.questions) ? this.questions.find(question => question.id === this.question.id) : []
                return questionWithLogic ? questionWithLogic.actions : []
            }
            return []
        },
        questionNode() {
            if (this.questions && this.question) {
                return {
                    name: `question-${this.question.id}`,
                    data: {
                        question: {
                            ...this.question,
                            actions: this.actions || []
                        }
                    }
                }
            }
            return null;
        }
    },
    async mounted() {
        try {
            this.loading = true
            await this.initActionsFromSurvey(this.$route.params.surveyId)
            this.loading = false
        } catch (error) {
            console.error(error)
        } finally {
            this.loading = false
        }
    },
}
</script>

<style lang="scss" scoped>

.actions-count{
    color: var(--v-primary-base);
    background-color: var(--v-primary-lighten5);
    border-radius: 4px;
    padding: 2px 4px;
    min-width: 30px;
}
.actions-container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
}
.actions-panel::v-deep .v-expansion-panel-content__wrap{
    padding: 0px;
}
</style>