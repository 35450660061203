<template>
    <div>
        <div class="mt-4" v-if="question && question.multiple_choice_items.length > 0">
            <div class="text-subtitle-2 mb-3">Options</div>
            <draggable :list="question.multiple_choice_items" ghost-class="ghost" @change="updateQuestion" :options="{ handle: '.drag-handle' }">
                <div
                    class="d-flex mb-3"
                    v-for="(choice, i) in question.multiple_choice_items"
                    :key="`${question.id}-option-${i}`"
                >
                    <v-icon class="ml-n4 grab drag-handle" small>
                        mdi-drag-vertical
                    </v-icon>
                    <v-text-field
                        hide-details="auto"
                        v-model="choice.value"
                        placeholder="Choice..."
                        @input="handleInput"
                        @change="!error && updateQuestion()"
                        :error-messages="indexInArray(i) ? error : ''"
                        outlined
                        dense
                        background-color="white"
                        class="text-body-2"
                    ></v-text-field>
                    <v-btn
                        @click.prevent="handleDelete(i)"
                        icon
                    >
                        <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                </div>
            </draggable>
        </div>
        <div class="d-flex mt-3">
            <v-btn color="primary" elevation=0 @click="handleAddClick">
                <v-icon left>mdi-plus-circle</v-icon>
                Add Option
            </v-btn>
            <v-btn @click="bulkAddDialog=true" class="ml-2" color="secondary" outlined>
                <v-icon left>mdi-playlist-plus</v-icon>
                Bulk Add
            </v-btn>
        </div>
        <v-dialog v-model="bulkAddDialog" width="500px">
            <bulk-options-add @addOptions="handleBulkAddOptions"></bulk-options-add>
        </v-dialog>
    </div>
</template>

<script>
import {mapMutations } from "vuex";
import draggable from 'vuedraggable'
import BulkOptionsAdd from './BulkOptionsAdd.vue';

export default {
    inject: [
        'deleteChoiceFromQuestion',
        'addChoiceToQuestion',
        'updateQuestion',
        'question'
    ],
    components: {draggable,BulkOptionsAdd },
    data() {
        return {
            error: "",
            duplicateIndices: [],
            bulkAddDialog: false,
        }
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        handleAddClick() {
            if(this.question.multiple_choice_items.some(item => item.value === "" || item.value === null)) {
                this.showSnackBar({
                    text: 'Cannot add an empty field', 
                    color: 'error', 
                    timeout: 2000,
                    right: true,
                    top: true,
                })
                return;
            }
            this.addChoiceToQuestion();
        },
        handleBulkAddOptions(options){
            this.bulkAddDialog = false;
            options.forEach(option => {
                // check if option does not exist
                if(this.question.multiple_choice_items.some(item => item.value === option)) {
                    return;
                }
                this.question.multiple_choice_items.push({value: option});
            });
            this.updateQuestion();
        },
        handleDelete(i) {
            this.duplicateIndices.pop(i);
            this.deleteChoiceFromQuestion(i);
            this.updateQuestion();
        },
        handleInput() {
            this.duplicateIndices = this.getDuplicates(this.question.multiple_choice_items);
            if(this.duplicateIndices.length !== 0) {
                this.error = "duplicate options not allowed"
                return;
            }
            this.error = "";
        },
        getDuplicates(array) {
            const choiceValueList = Array.from(array, choice => choice.value);
            let duplicates = []
            choiceValueList.forEach((word, index) => {
                let flag = false;
                const slice = choiceValueList.slice(index, choiceValueList.length);
                for(let i = 0; i < slice.length; ++i) {
                    if(word === slice[i]) {
                        if(flag && !duplicates.includes(i + index)) {
                            duplicates.push(i + index);
                        }
                        flag = true;
                    }
                }
                flag = false;
            });
            return duplicates;
        },
        indexInArray(i) {
            return this.duplicateIndices.includes(i);
        },
    }
}
</script>

<style lang="scss" scoped>
.grab{
    cursor: grab;
    position: relative;;
    &:hover {
        color: var(v-primary-dark);
    }
}
.ghost{
    opacity: 0;
}
</style>
