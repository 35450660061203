<template>
    <div v-if="stepProperties" class="d-flex flex-grow-1" :style="mainStyles">
        <header-edit v-if="stepProperties.id" :stepProperties="stepProperties" :survey="survey" :styles="styles"></header-edit>
        <v-sheet class="slide" height="100%" color="transparent" :style="`color: ${styles.backgroundTextColor};`" >
            <v-container class="main-container">
                <div class="question-layout">
                    <welcome-step-edit v-if="$route.params.stepType === 'welcome_screen'"  :activeStep="stepProperties" :styles="styles" :contrastTextColor="styles.backgroundTextColor"></welcome-step-edit>
                    <finish-step-edit v-else-if="$route.params.stepType === 'goodbye_page'" :styles="styles"></finish-step-edit>
                    <question-step-edit :key="stepProperties.id" v-else :step="stepProperties" :survey="survey"
                        :contrastTextColor="styles.backgroundTextColor" :styles="styles">
                        <component v-if="question.type !== 'description-slide'"
                            :styles="styles" 
                            :is="generateStepType(question.type)"
                            v-model="answerValue" 
                            :survey="survey" 
                            :question="stepProperties"
                            :key="`question-${question.id}`"
                        />
                    </question-step-edit>
                </div>
            </v-container>
        </v-sheet>
    </div>
</template>

<script>

import FinishStep from "../../views/survey/components/steps/FinishStep";
import MultipleChoice from "../question-editor/types/MultipleChoiceEdit.vue";
import DatePicker from "../question-editor/types/DatePickerEdit.vue";
import Checkbox from "../question-editor/types/CheckboxEdit.vue";
import Email from "../question-editor/types/EmailEdit.vue";
import Rating from "../question-editor/types/RatingEdit.vue";
import NetPromoterScore from "../question-editor/types/NetPromoterScoreEdit.vue";
import Name from "../question-editor/types/NameEdit.vue";
import ZipCode from '../question-editor/types/ZipCodeEdit.vue';
import Yesno from "../question-editor/types/YesnoEdit.vue";
import Dropdown from "../question-editor/types/DropdownEdit.vue";
import CustomField from "../question-editor/types/CustomFieldEdit.vue";
import VoiceResponse from "../question-editor/types/VoiceResponseEdit.vue";
import PhoneNumber from '../question-editor/types/PhoneNumberEdit.vue'
import FileUpload from "../question-editor/types/FileUploadEdit.vue";
import questionTypes from '../../configs/questionTypes';
import QuestionStepEdit from '../question-editor/QuestionStepEdit.vue';
import { invertColor } from '../../utils/invert-color';
import HeaderEdit from '../question-editor/HeaderEdit.vue';
import WelcomeStepEdit from '../question-editor/WelcomeStepEdit.vue';
import FinishStepEdit from "../question-editor/FinishStepEdit.vue";
import NumericInput from '../question-editor/types/NumericInputEdit.vue';
import Matrix from '../question-editor/types/matrix/MatrixEdit.vue';

export default {
    name: "SurveyPreview",
    components: {
        FinishStep,
        MultipleChoice,
        DatePicker,
        Checkbox,
        Email,
        Rating,
        NetPromoterScore,
        Name,
        ZipCode,
        Yesno,
        Dropdown,
        CustomField,
        FileUpload,
        VoiceResponse,
        PhoneNumber,
        Matrix,
        QuestionStepEdit,
        HeaderEdit,
        WelcomeStepEdit,
        FinishStepEdit,
        NumericInput
     },
    inject: ["question", "survey"],
    data() {
        return {
            answer: null
        };
    },
    watch: {
      '$route.params.stepType': function () {
        this.answerValue = null
      }
    },
    computed: {
        answerValue: {
            get(){
                return this.answer && this.answer.answer
            },
            set(answerValue){
                this.answer = answerValue? {
                    // some answers consist of "answer", "valid" fields
                    answer: answerValue.answer || answerValue,
                    valid: answerValue.valid || true,
                } : null;
            }
        },
        stepProperties() {
            if( this.$route.params.stepType === 'welcome_screen'){
                return {
                    component: 'WelcomeStep', step: 'welcome_screen'
                }
            }

            if( this.$route.params.stepType === 'goodbye_page'){
                return {
                    component: 'FinishPage', step: 'goodbye_page'
                }
            }
            if(this.question){
                return {
                    order: this.question.order,
                    id: this.question.id,
                    text: this.question.text,
                    audio_url: this.question.audio_url,
                    style: this.question.style,
                    image: this.question.image_url,
                    is_optional: this.question.is_optional,
                    type: this.question.type == 'plain' ? 'voice-response' : this.question.type,
                    multipleChoiceItems: this.question.multiple_choice_items,
                    media_attachments: this.question.media_attachments || [],
                    enable_text_input: !!this.question.enable_text_input,
                    description: this.question.description || '',
                    video_source: this.question.video_source || '',
                    actions: this.question.actions && this.question.actions.length ? this.question.actions : null,
                    component: 'QuestionStep',
                    properties: this.question.properties,
                    skippedByLogic: false,
                }
            }
            return null
        },
        styles(){
            return this.generateSurveyStyles(this.survey)
        },
        mainStyles(){
            return `color: ${this.styles.backgroundTextColor} ; background-color: ${this.styles.background}; padding-bottom: ${this.hideFooter? '0px': '62px'}`
        }
    },
    methods: {
        generateStepType(type){
            if(type === questionTypes.VOICE_QUESTION){
                return questionTypes.VOICE_RESPONSE
            }
            return type
        },
        generateSurveyStyles(survey) {

            let styles = {
                background: '#FFF',
                contrastBackground: "#000",
                backgroundTextColor: "#000",
                color: '#000',
                button: {
                    background: '#000',
                    color: '#fff',
                },
                dark: false,
            }

            try {
                if (survey && survey.style) {
                    styles = {
                        ...survey.style,
                        contrastBackground: invertColor(survey.style.background),
                        backgroundTextColor: invertColor(survey.style.background, true),
                        dark: invertColor(survey.style.background, true) === "#FFFFFF"
                    }
                }
            } catch (error) {
                console.log('cannot set styles', error);
            }
            return styles
        }
    }
};
</script>

<style lang="scss">
    :root {
        --overflow: unset;
    }
</style>

<style lang="scss" scoped>
 .page-container{
    width: 100%;
    height: 100%;
    overflow: auto;
 }

 .slide{
    display: flex;
    flex: 1;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: auto;
}

.main-container {
    padding-top: 62px;
    max-width: 960px;
    display: flex;
}
 .question-layout {
    display: flex;
    justify-content: center;
    flex: 1;
    min-height: 40vh;
    margin-top: auto;
    margin-bottom: auto;
}
</style>

