<template>

    <div class="d-flex flex-grow-1 flex-column pa-6">
        <v-btn-toggle
            v-model="currentTab"
            borderless
            outlined
            dense
            class="mb-4"
        >
            <v-btn v-for="tab in tabs" :key="tab.value" :value="tab.value">
                {{tab.title}}
            </v-btn>
        </v-btn-toggle>
        <component :is="currentTab" />
    </div>


</template>

<script>
import Customize from "./Customize.vue";
import Themes from "@/views/dashboard/voiceform/create/design/Themes.vue";

export default {
    name: "Design",
    components: {
        Customize,
        Themes,
    },
    data() {
        return {
            currentTab: "customize",
            tabs: [
                {
                    title: "Customize",
                    value: "customize",
                },
                {
                    title: "Themes",
                    value: "themes",
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.preview-container{
    display: flex;
    height: 700px;
}
</style>
