<template>
    <div>
        <v-select ref="typeSelect" outlined dense hide-details :items="types" v-model="currentType"
            @change="typeSwitchDialog = true" class="mb-4" :menu-props="{ offsetY: true }">
            <template v-slot:item="{ item }">
                <div class="d-flex align-center question-type-badge">
                    <v-icon small color="primary darken-1">{{ questionIcons[item] }}</v-icon>
                </div>
                <div class="ml-2">{{ titles[item] }}</div>
            </template>

            <template v-slot:selection="{ item }">
                <div class="d-flex align-center question-type-badge ml-1"
                    style="width: 26px; height: 26px; min-width: 26px">
                    <v-icon small color="primary darken-1">{{ questionIcons[item] }}</v-icon>
                </div>
                <div class="ml-2">{{ titles[item] }}</div>
            </template>
        </v-select>

        <v-dialog v-model="typeSwitchDialog" width="460" persistent>
            <v-card class="pa-5">
                <div class="text-h5 py-2">Are you sure you want to switch question type?</div>
                <div class="text-subtitle-1 py-4">By clicking "Confirm" button you accept that"</div>
                <div class="pa-4">All answers related to that question will be removed</div>
                <div class="pa-4">All logic actions will be removed from this question</div>
                <div class="pa-4">Answers logic related to this question may be broken</div>
                <div class="d-flex flex-row-reverse">
                    <v-btn color="primary" elevation="0" @click="changeQuestionType">Confirm</v-btn>
                    <v-btn class="mr-2" text @click="cancelTypeSwitch">Cancel</v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { TYPES_ARRAY, questionIcons, titles, ZIP_CODE } from '../../../../../../configs/questionTypes';

export default {
    data() {
        return {
            TYPES_ARRAY,
            questionIcons,
            titles,
            currentType: this.questionType || 'voice-response',
            typeSwitchDialog: false,
        };
    },
    props: {
        questionType: {
            type: String,
            required: true
        }
    },
    watch: {
        questionType(val) {
            this.currentType = val;
        }
    },
    methods: {
        cancelTypeSwitch() {
            this.typeSwitchDialog = false;
            this.currentType = this.questionType;
        },
        changeQuestionType() {
            this.typeSwitchDialog = false;
            this.$emit('changeType', this.currentType);
        }
    },
    inject: ['survey'],
    computed:{
        types(){
            if(this.survey.inbounds){
                return TYPES_ARRAY
            }
            return TYPES_ARRAY.filter(type => type !== ZIP_CODE );
        }
    }
};
</script>

<style scoped lang="scss">
.question-type-badge {
    width: 32px;
    height: 32px;
    min-width: 32px;
    justify-content: center;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 4px;

    background-color: var(--v-primary-lighten5);
    color: var(--v-primary-darken1);
}
</style>