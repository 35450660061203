import HorizontalRule from "./extensions/HorizontalRule"
import ImageBlock from "./extensions/ImageBlock"
import { isTextSelection } from '@tiptap/vue-2'
import Link from "@tiptap/extension-link"

export const isCustomNodeSelected = (editor) => {
    const customNodes = [
      HorizontalRule.name,
      ImageBlock.name,
      Link.name,
    ]
  
    return customNodes.some(type => editor.isActive(type))
}

// from https://github.com/ueberdosis/tiptap-templates/blob/9463b7d46f2c02a3a0907af8f52667b2b1e7d953/templates/next-block-editor-app/src/lib/utils/isTextSelected.ts#L3
export const isTextSelected = editor => {
    const {
        state: {
          doc,
          selection,
          selection: { empty, from, to },
        },
      } = editor
    
      // Sometime check for `empty` is not enough.
      // Doubleclick an empty paragraph returns a node size of 2.
      // So we check also for an empty text size.
      const isEmptyTextBlock = !doc.textBetween(from, to).length && isTextSelection(selection)
    
      if (empty || isEmptyTextBlock || !editor.isEditable) {
        return false
      }
    
      return true
}