<template>
    <div>
    <VuePhoneNumberInput 
        :dark="styles.dark" 
        :preferred-countries="['US', 'CA']" 
        show-code-on-list
        @update="onPhoneInputUpdate"
        :color="styles.color"
        :dark-color="styles.background"
        valid-color="#4CAF50"
        error-color="#F44336"
        clearable
        v-model="phoneValue"
        class="phone-number"
        :no-country-selector="survey && survey.inbounds"
    />
    </div>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import { parsePhoneNumberWithError, ParseError } from 'libphonenumber-js';
export default {
    name: "PhoneNumber",
    props: {
        value: {
            required: true,
        },
        survey: {
            required: true,
        },
        styles: {
            required: true
        }
    },
    components: {VuePhoneNumberInput},
    data() {
        return {
            fullPhoneNumber: null,
            phoneValue: this.value ? this.value.value : "",
            phoneNumberIsValid: true,
        };
    },
    methods: {
        onPhoneInputUpdate(event){
            this.phoneNumberIsValid = event.isValid;
             this.$emit("input", event.e164?{answer: { type: "phone-number", value: event.e164 }, valid: event.isValid || 'The phone number is invalid' }: null);
            //  TODO: maybe calling it from here or from the ^ input method 🤔
            // valid method can be boolean or string. if string that means it has custom message.
            // if valid method is not fired we consider the answer valid by default
             this.$emit("valid",  event.isValid || 'The phone number is invalid')
        },
    },
    created() {
        try {
            let phoneNumber = this.$route.query && this.$route.query.v_phone_number || localStorage.getItem('phone-number');
            if(phoneNumber) {
                phoneNumber = parsePhoneNumberWithError(phoneNumber, 'US');
                phoneNumber && this.$emit("input", { type: "phone-number", value: phoneNumber.nationalNumber, valid: true})
                this.phoneValue = phoneNumber.nationalNumber
                this.$emit("valid",  true);
            }
        } catch (error) {
            if (error instanceof ParseError) {
                // Not a phone number, non-existent country, etc.
                console.error(error.message)
            } else {
                throw error
            }
        }
    }
};
</script>

<style lang="scss">
    .phone-number{
        * input {
            font-size: 16px !important;
        }
    }
</style>
