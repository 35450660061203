<template>
    <BubbleMenu :editor="editor" pluginKey="textMenu" :shouldShow="handleShouldShow" :updateDelay="100" :tippyOptions="{
        // set tippy width
        maxWidth: 'none',
        popperOptions: { placement: 'top-start' }
    }">
        <v-toolbar rounded height="40" ref="menuRef">
            <v-btn-toggle class="mr-2" multiple dense :value="activeFormats">
                <v-btn value="bold" text @click.prevent="handleActionPress('bold')" small>
                    <v-icon small>mdi-format-bold</v-icon>
                </v-btn>
                <v-btn value="italic" text @click.prevent="handleActionPress('italic')" small>
                    <v-icon small>mdi-format-italic</v-icon>
                </v-btn>

                <v-btn value="underline" text @click.prevent="handleActionPress('underline')" small>
                    <v-icon small>mdi-format-underline</v-icon>
                </v-btn>
                <v-btn value="strike" text @click.prevent="handleActionPress('strike')" small>
                    <v-icon small>mdi-format-strikethrough-variant </v-icon>
                </v-btn>
                <v-btn value="highlight" text @click.prevent="handleActionPress('highlight')" small>
                    <v-icon small>mdi-marker</v-icon>
                </v-btn>
            </v-btn-toggle>

            <v-btn-toggle dense group mandatory :value="activeAlignment">
                <v-btn text value="left" @click.prevent="handleActionPress('left')" small>
                    <v-icon small>mdi-format-align-left</v-icon>
                </v-btn>

                <v-btn text value="center" @click.prevent="handleActionPress('center')" small>
                    <v-icon small>mdi-format-align-center</v-icon>
                </v-btn>

                <v-btn text value="right" @click.prevent="handleActionPress('right')" small>
                    <v-icon small>mdi-format-align-right</v-icon>
                </v-btn>
                <v-btn text value="justify" @click.prevent="handleActionPress('justify')" small>
                    <v-icon small>mdi-format-align-justify</v-icon>
                </v-btn>
            </v-btn-toggle>

            <v-menu offset-y :close-on-content-click="false" v-model="newLinkMenu">
                <template v-slot:activator="{ attrs }">
                    <v-btn v-bind="attrs" @click="handleOpenLinkMenu" small text
                        :color="editor && editor.isActive('link') ? 'primary' : 'default'">
                        <v-icon small>mdi-link-variant</v-icon>
                    </v-btn>
                </template>
                <v-card class="d-flex align-center pa-2">
                    <v-text-field :style="{width: '200px'}" width="400px" class="text-caption mr-1" hide-details dense placeholder="Enter URL"
                        v-model="url" outlined>
                        <template slot="prepend-inner">
                            <v-icon class="mt-1 mr-1" small>mdi-link-variant</v-icon>
                        </template>
                    
                    </v-text-field>
                    <v-btn @click="setLink" class="mr-2" icon>
                        <v-icon>mdi-check</v-icon>
                    </v-btn>
                    <v-btn @click="newLinkMenu=false" icon>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card>
            </v-menu>
        </v-toolbar>
    </BubbleMenu>
</template>

<script>
import { BubbleMenu } from '@tiptap/vue-2'
import { isCustomNodeSelected, isTextSelected } from '../../utils'

const LinkReg = new RegExp("^(http|https)://", "i");

export default {
    name: 'TextMenu',
    components: {
        BubbleMenu,
    },
    props: {
        editor: {
            type: Object,
            required: true,
        },
        appendTo: {
            default: () => document.body,
        },
    },
    data() {
        return {
            newLinkMenu: false,
            url: '',
        }
    },

    methods: {
        handleShouldShow() {
            const { view } = this.editor;
            let shouldShow = false;
            if (view && !isCustomNodeSelected(this.editor) && isTextSelected(this.editor)) {
                shouldShow = true;
            }
            return shouldShow;
        },
        handleActionPress(action) {

            switch (action) {
                case 'bold':
                    this.editor.chain().focus().toggleBold().run()
                    break;
                case 'italic':
                    this.editor.chain().focus().toggleItalic().run()
                    break;
                case 'underline':
                    this.editor.chain().focus().toggleUnderline().run()
                    break;
                case 'highlight':
                    this.editor.chain().focus().toggleHighlight().run()
                    break;
                case 'left':
                    this.editor.chain().focus().setTextAlign('left').run()
                    break;
                case 'center':
                    this.editor.chain().focus().setTextAlign('center').run()
                    break;
                case 'right':
                    this.editor.chain().focus().setTextAlign('right').run()
                    break;
                case 'justify':
                    this.editor.chain().focus().setTextAlign('justify').run()
                    break;
                case 'strike':
                    this.editor.chain().focus().toggleStrike().run()
                    break;
                default:
                    break;
            }
        },

        handleOpenLinkMenu() {
            this.url = '';
            if(!this.editor.isActive('link')){
                this.newLinkMenu = true
            }

        },

        setLink() {
            this.newLinkMenu = false
            // empty
            if (this.url === '') {
                this.editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .unsetLink()
                    .run()

                return
            }

            if (this.url && this.url.trim() && !LinkReg.test(this.url)) {
                this.url = `http://${this.url}`
            }

            // update link
            this.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .setLink({ href: this.url })
                .run()
        }
    },

    computed: {
        activeFormats() {
            const textFormat = []
            if (this.editor) {
                if (this.editor.isActive('bold')) {
                    textFormat.push('bold')
                }
                if (this.editor.isActive('italic')) {
                    textFormat.push('italic')
                }
                if (this.editor.isActive('underline')) {
                    textFormat.push('underline')
                }
                if (this.editor.isActive('highlight')) {
                    textFormat.push('highlight')
                }

                if (this.editor.isActive('strike')) {
                    textFormat.push('strike')
                }
            }

            return textFormat
        },
        activeAlignment() {
            if (this.editor) {
                if (this.editor.isActive({ textAlign: 'left' })) {
                    return 'left'
                }
                if (this.editor.isActive({ textAlign: 'center' })) {
                    return 'center'
                }
                if (this.editor.isActive({ textAlign: 'right' })) {
                    return 'right'
                }
                if (this.editor.isActive({ textAlign: 'justify' })) {
                    return 'justify'
                }
            }
            return ''
        },
    },
}
</script>