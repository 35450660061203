<template>
    <v-radio-group :dark="styles.dark" v-model="answer" @change="$emit('change')">
        <template v-for="(choice, i) in mcqItems">
            <v-radio
                v-if="choice"
                @click="handleClickInput"
                :key="i"
                :id="(choice + i).toString()"
                :label="choice"
                :color="survey.style.color || survey.font_color || primary"
                :value="choice"
            />
        </template>
    </v-radio-group>
</template>

<script>
export default {
    name: "MultipleChoice",
    props: {
        value: {
            required: true,
        },
        question: {
            required: true,
        },
        styles: {
            required: true,
        },
        survey: {
            required: true,
        },
    },
    computed: {
        answer: {
            get: function () {
                return this.value ? this.value.value : "";
            },
            set: function (val) {
                if(val) {
                    this.$emit("input", { type: "choice", value: val });
                } else {
                    this.$emit("input", null);
                }
            },
        },
        mcqItems() {
            const items = this.question.multipleChoiceItems;
            return new Set(Array.from(items, choice => choice.value));
        },
    },
    methods: {
        handleClickInput(e) {
            let val = e.target && e.target.outerText ? e.target.outerText : e.target.previousSibling._value
            if(val === this.answer) {
                this.answer = null;
            }
        }
    },
};
</script>

<style lang="scss" scoped>
</style>
